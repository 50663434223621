<template>
  <div id="pdfview" :style="'height:' + height">
    <v-fab-transition>
      <v-btn
        v-if="!hideDownload"
        v-tooltip="{ content: 'ダウンロード', placement: 'left' }"
        small
        color="grey lighten-3"
        absolute
        fab
        style="top: 70px; right: 20px"
        @click="$emit('download-file')"
      >
        <v-icon x-small> feather-download </v-icon>
      </v-btn>
    </v-fab-transition>
    <iframe ref="iframe" type="application/pdf" height="100%" width="100%" />
  </div>
</template>

<script>
export default {
  props: {
    uri: '',
    height: '',
    width: '',
    hideDownload: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    uri: function () {
      this.$nextTick(() => {
        this.$refs.iframe.contentWindow.location.replace(this.uri)
      })
    },
  },
  mounted() {
    if (this.uri) {
      this.$nextTick(() => {
        this.$refs.iframe.contentWindow.location.replace(this.uri)
      })
    }
  },
}
</script>
