<template>
  <v-dialog v-model="dialog" max-width="650">
    <v-card class="sweeep-dialog">
      <v-card-title> インポートエラー </v-card-title>

      <v-card-text v-if="customerNameError.length">
        会社名を入力ください<br />
        CSV行数 {{ customerNameError.join(', ') }}
      </v-card-text>

      <v-card-text v-if="userOrganizationError.length">
        自社担当者に登録されていない名前が入力されています<br />
        CSV行数 {{ userOrganizationError.join(', ') }}
      </v-card-text>

      <v-card-text v-if="invalidEmailError.length">
        メールアドレスを正しい形式で入力ください<br />
        CSV行数 {{ invalidEmailError.join(', ') }}
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn @click.native="close">
          {{ $t('message.modal_cancel_btn') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      customerNameError: [],
      userOrganizationError: [],
      invalidEmailError: [],
    }
  },
  methods: {
    open({ customerNameError, userOrganizationError, invalidEmailError }) {
      if (customerNameError) {
        this.customerNameError = customerNameError
      }
      if (userOrganizationError) {
        this.userOrganizationError = userOrganizationError
      }
      if (invalidEmailError) {
        this.invalidEmailError = invalidEmailError
      }
      this.dialog = true
    },
    close() {
      this.dialog = false
      this.customerNameError = []
      this.userOrganizationError = []
      this.invalidEmailError = []
    },
  },
}
</script>
