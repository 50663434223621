/*
  テーブルをfront用に編集します。

  [元のテーブルの構成]
    customer_table: [
      {
        'customer': customer_obj,
        'own_represent_user_name': user.full_name,
        'items': {
            datetime1: [inbox1_1],
            datetime2: [inbox2_1, inbox2_2],
            .....
        }
      },
    ]

  [編集後のテーブル]
    customer_table: [
      {
        'customer': customer_obj,
        'own_represent_user_name': user.full_name,
        'items': [[inbox1_1, inbox1_2], [inbox2_1, inbox2_2], ....., [inbox12_1, ..., inbox12_N]]
      },
    ]
*/

function checkText(item, text) {
  if (!text) {
    return true
  }
  return (
    item.customer.name.includes(text) ||
    item.customer.department_name.includes(text) ||
    item.own_department_name.includes(text) ||
    item.own_represent_user_name.includes(text)
  )
}

function checkStatus(item, status) {
  if (status === 1 || status === 2) {
    return item.customer.status === status
  } else if (status === 3) {
    return item.customer.status !== 0
  }
}

function checkOwnDepartment(item, ownDepartmentId) {
  if (!ownDepartmentId) {
    return true
  }
  return item.customer.own_department === ownDepartmentId
}

function checkUncategorized(item, showUncategorized) {
  if (showUncategorized) {
    return true
  }
  return !item.customer.is_uncategorized
}

export function filterCustomerTable(
  table,
  { year, month, status, text, ownDepartmentId, showUncategorized }
) {
  if (!year) {
    let today = new Date()
    year = today.getFullYear()
  }
  if (!month) {
    month = 1
  }

  // Prepare result table
  let resultTable = JSON.parse(JSON.stringify(table))
  let tempResultItems = {}
  let yearIndex = year
  let monthIndex = month
  for (let i = 1; i <= 4; i++) {
    if (monthIndex == 13) {
      yearIndex++
      monthIndex = 1
    }
    let dateStr = String(yearIndex) + '/' + String(monthIndex)
    tempResultItems[dateStr] = null
    monthIndex++
  }
  for (let resultData of resultTable) {
    resultData.items = JSON.parse(JSON.stringify(tempResultItems))
  }

  // create result
  for (let data of table) {
    let tableIndex = table.indexOf(data)
    for (let itemDateStr of Object.keys(data.items)) {
      let itemDate = new Date(itemDateStr)
      if (year > itemDate.getFullYear()) {
        continue
      }
      let dateKey =
        String(itemDate.getFullYear()) + '/' + String(itemDate.getMonth() + 1)
      if (Object.keys(resultTable[tableIndex].items).indexOf(dateKey) >= 0) {
        resultTable[tableIndex].items[dateKey] = data.items[itemDateStr]
      }
    }
    resultTable[tableIndex].items = Object.values(resultTable[tableIndex].items)
  }

  resultTable = resultTable.filter((item) => {
    return (
      checkStatus(item, status) &&
      checkText(item, text) &&
      checkOwnDepartment(item, ownDepartmentId) &&
      checkUncategorized(item, showUncategorized)
    )
  })

  return resultTable
}

export function filterCustomerTableWithoutDate(
  table,
  { status, text, ownDepartmentId, showUncategorized }
) {
  let resultTable = JSON.parse(JSON.stringify(table))
  resultTable = resultTable.filter((item) => {
    return (
      checkStatus(item, status) &&
      checkText(item, text) &&
      checkOwnDepartment(item, ownDepartmentId) &&
      checkUncategorized(item, showUncategorized)
    )
  })

  return resultTable
}
