<template>
  <v-card
    tile
    flat
    class="ma-0 pa-0"
    :style="`min-width: ${width}; max-width: ${width}`"
  >
    <v-toolbar
      flat
      style="
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        text-align: center;
      "
      height="57"
    >
      <span v-show="opened" class="fs-12"> 部門選択 </span>
      <v-spacer />
      <div :style="btnStyle">
        <v-btn x-small fab icon @click="opened = !opened">
          <s-icon
            :icon="opened ? 'feather-chevron-left' : 'feather-chevron-right'"
            size="lg"
          />
        </v-btn>
      </div>
    </v-toolbar>
    <v-list style="height: calc(100vh - 107px); overflow: auto" class="pa-0">
      <v-list-item-group
        v-model="selectedIndex"
        mandatory
        @change="$emit('change-department', departments[selectedIndex])"
      >
        <v-list-item
          v-for="item in departments"
          :key="item.name_code"
          :class="!opened ? 'pl-5 pr-0' : ''"
        >
          <template v-slot:default="{ active }">
            <div v-show="opened" style="display: flex">
              <v-list-item-action>
                <v-checkbox :input-value="active" color="primary" />
              </v-list-item-action>

              <v-list-item-content v-text="item.is_all ? '全社' : item.name" />
            </div>
            <span v-show="!opened" class="fs-10">
              <template v-if="item.is_all"> 全社 </template>
              <template v-else>
                {{ item.name.slice(0, 2) }}
              </template>
            </span>
          </template>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    height: {
      type: String,
      default: '100vh - 220px',
    },
    maxWidth: {
      type: String,
      required: true,
    },
    minWidth: {
      type: String,
      required: true,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedIndex: 0,
    }
  },
  computed: {
    ...mapGetters({
      departments: 'inboxDepartments',
      tableParams: 'inboxCustomersTableParams',
      listParams: 'inboxItemsListParams',
    }),
    opened: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('change', v)
      },
    },
    width() {
      return this.opened ? this.maxWidth : this.minWidth
    },
    btnStyle() {
      if (this.opened) {
        return 'position: absolute; top: 13px; right: 5px;'
      } else {
        return 'position: absolute; top: 13px; right: 12px;'
      }
    },
  },
  watch: {
    'tableParams.ownDepartmentId': {
      handler(v) {
        this.selectedIndex = this.departments.findIndex((item) => item.id === v)
      },
      immediate: true,
    },
    'listParams.ownDepartmentId': {
      handler(v) {
        this.selectedIndex = this.departments.findIndex((item) => item.id === v)
      },
      immediate: true,
    },
  },
}
</script>
