<template>
  <v-card-text
    class="pt-0 mt-0"
    style="height: calc(100vh - 510px); overflow: scroll"
  >
    <v-row no-gutters class="px-2">
      <v-card tile outlined class="w-100">
        <v-card-title>
          <!-- ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
              ＝＝＝＝＝開発時のお願い＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
              これらのボタンは動的に埋め込む項目用のものです。
              ボタン押下時に「%xxx%」がメール内容に記入されることが理想ですが、すぐにコーディングの方法が思いつかない場合は
              ボタン押下時に「専用コードがコピーされました。メールに埋め込んでください」というスナックバーを表示して「%xxx%」をクリップボードに格納してもOKです。
              ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝ -->

          <v-btn
            x-small
            class="mx-1"
            @click="insert('csCompanyName')"
            v-text="$t('message.inbox_customer_company_name')"
          />
          <v-btn
            x-small
            class="mx-1"
            @click="insert('csDepartmentName')"
            v-text="$t('message.inbox_customer_department_name')"
          />
          <v-btn
            x-small
            class="mx-1"
            @click="insert('csRepresentName')"
            v-text="$t('message.inbox_customer_represent_name')"
          />
          <v-btn
            x-small
            class="mx-1"
            @click="insert('ownCompanyName')"
            v-text="$t('message.inbox_owner_company_name')"
          />
          <v-btn
            x-small
            class="mx-1"
            @click="insert('ownDepartmentName')"
            v-text="$t('message.inbox_owner_department_name')"
          />
          <v-btn
            x-small
            class="mx-1"
            @click="insert('ownRepresentName')"
            v-text="$t('message.inbox_owner_represent_name')"
          />
          <v-btn
            x-small
            class="mx-1"
            @click="insert('uploadUrl')"
            v-text="$t('message.inbox_upload_url')"
          />
          <v-btn
            x-small
            class="mx-1"
            @click="insert('uploadEmail')"
            v-text="$t('message.inbox_upload_email')"
          />
        </v-card-title>
        <v-card-text>
          <quill-editor
            ref="myQuillEditor"
            :content="value"
            :options="editorOption"
            @focus="onFocus"
            @blur="onBlur"
            @change="onChange"
          />
        </v-card-text>
      </v-card>
    </v-row>
  </v-card-text>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      editorOption: {
        modules: {
          toolbar: [],
        },
        placeholder: 'メール本文を入力ください',
        theme: 'bubble',
      },
    }
  },
  computed: {
    quill() {
      return this.$refs.myQuillEditor.quill
    },
  },
  methods: {
    onFocus() {
      this.$emit('focus')
    },
    onBlur() {
      this.$emit('blur')
    },
    onChange({ html }) {
      this.$emit('change', html)
    },
    insert(name) {
      const quill = this.quill
      const selection = quill.getSelection()
      switch (name) {
        case 'csCompanyName':
          quill.insertText(
            selection.index,
            '%' + this.$t('message.inbox_customer_company_name') + '%'
          )
          break
        case 'csDepartmentName':
          quill.insertText(
            selection.index,
            '%' + this.$t('message.inbox_customer_department_name') + '%'
          )
          break
        case 'csRepresentName':
          quill.insertText(
            selection.index,
            '%' + this.$t('message.inbox_customer_represent_name') + '%'
          )
          break
        case 'ownCompanyName':
          quill.insertText(
            selection.index,
            '%' + this.$t('message.inbox_owner_company_name') + '%'
          )
          break
        case 'ownDepartmentName':
          quill.insertText(
            selection.index,
            '%' + this.$t('message.inbox_owner_department_name') + '%'
          )
          break
        case 'ownRepresentName':
          quill.insertText(
            selection.index,
            '%' + this.$t('message.inbox_owner_represent_name') + '%'
          )
          break
        case 'uploadUrl':
          quill.insertText(
            selection.index,
            '%' + this.$t('message.inbox_upload_url') + '%'
          )
          break
        case 'uploadEmail':
          quill.insertText(
            selection.index,
            '%' + this.$t('message.inbox_upload_email') + '%'
          )
          break
      }
    },
  },
}
</script>
