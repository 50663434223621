function inboxStatus() {
  return {
    UNCOLLECTED: 1,
    REQUESTED: 2,
    RECEIVED: 3,
    APPROVING: 4,
    IMPORTED: 5,
    DISAPPROVED: 6,
    DELETED: 7,
    NOT_NEED_TO_COLLECT: 8,
  }
}

export default new Proxy(inboxStatus(), {
  get(target, name) {
    if (target[name] === undefined) {
      throw new Error('UndefinedProperty: ' + name)
    }
    return target[name]
  },
})
