<template>
  <v-dialog v-model="dialog" max-width="500">
    <v-card class="sweeep-dialog">
      <v-card-title>承認失敗</v-card-title>
      <v-card-text>
        <slot name="card-text"> 前段階の承認が必要です。 </slot>
      </v-card-text>
      <v-card-actions>
        <slot name="card-actions">
          <v-spacer />
          <v-btn @click="dialog = false" v-text="$t('message.close')" />
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
    }
  },
  methods: {
    open() {
      this.dialog = true
    },
  },
}
</script>
