<template>
  <v-dialog v-model="dialog" :max-width="maxWidth">
    <no-privilege-dialog ref="noPrivilegeDialog" />
    <input-delete-dialog
      ref="dialogDelete"
      title="取引先の削除"
      message="選択した取引先を削除してよろしいですか?"
      :cancel-text="$t('message.modal_cancel_btn')"
      :delete-text="$t('message.delete')"
      @submit="
        $emit('delete-customer', form.hiddenCustomerId)
        $refs.dialogDelete.close()
      "
    />
    <v-card class="sweeep-dialog">
      <v-card-title v-text="editMode ? '取引先の編集' : '取引先の追加'" />
      <v-card-text class="py-0 grey lighten-3">
        <v-form ref="form">
          <v-row>
            <v-col style="height: calc(90vh - 115px); overflow: scroll">
              <v-card class="pa-5">
                <div class="fs-12 fw-bold">取引先情報</div>
                <v-radio-group v-model="form.status" row>
                  <v-radio color="blue" label="表示" :value="1" />
                  <v-radio color="blue" label="非表示" :value="2" />
                </v-radio-group>
                <v-text-field
                  v-model="form.name"
                  :rules="textRequiredRules"
                  required
                  validate-on-blur
                  label="会社名"
                  filled
                  dense
                  outlined
                  :readonly="readonly"
                />
                <v-text-field
                  v-model="form.departmentName"
                  validate-on-blur
                  label="部門名"
                  filled
                  dense
                  outlined
                  :readonly="readonly"
                />
                <v-text-field
                  v-model="form.representName"
                  validate-on-blur
                  label="担当者名"
                  filled
                  dense
                  outlined
                  :readonly="readonly"
                />
                <v-text-field
                  v-model="form.email"
                  :rules="emailRules"
                  validate-on-blur
                  label="メールアドレス"
                  filled
                  dense
                  outlined
                  :readonly="readonly"
                />
                <v-text-field
                  v-model="form.uploadUrl"
                  :label="$t('message.inbox_upload_url')"
                  placeholder="取引先を追加すると発行されます"
                  :append-icon="editMode ? 'feather-copy' : ''"
                  dense
                  readonly
                  hide-details
                  class="mt-8"
                  @click.stop.prevent="
                    editMode
                      ? $emit(
                          'write-to-clipboard',
                          customerUploadUrlQuery(form.hiddenCustomerId)
                        )
                      : ''
                  "
                />
                <input
                  :id="customerUploadUrlId(form.hiddenCustomerId)"
                  type="hidden"
                  :value="form.uploadUrl"
                />
                <v-text-field
                  v-model="form.uploadEmail"
                  :label="$t('message.inbox_upload_email')"
                  placeholder="取引先を追加すると発行されます"
                  :append-icon="editMode ? 'feather-copy' : ''"
                  dense
                  readonly
                  hide-details
                  class="mt-8"
                  @click.stop.prevent="
                    editMode
                      ? $emit(
                          'write-to-clipboard',
                          customerUploadEmailQuery(form.hiddenCustomerId)
                        )
                      : ''
                  "
                />
                <input
                  :id="customerUploadEmailId(form.hiddenCustomerId)"
                  type="hidden"
                  :value="form.uploadEmail"
                />
              </v-card>
              <v-card v-if="!form.isUncategorized" class="pa-5 mt-3">
                <div class="fs-12 fw-bold">請求書受領予定</div>
                <!-- <v-row no-gutters class="pt-1">
                  <v-radio-group
                    v-model="form.useIssueDate"
                    row
                  >
                    <v-radio
                      color="blue"
                      label="OCR結果を反映"
                      :value="false"
                    />
                    <v-radio
                      color="blue"
                      label="受領日付から計算"
                      :value="true"
                    />
                  </v-radio-group>
                </v-row> -->
                <v-row no-gutters class="py-2">
                  <date-pick-input v-model="form.startAt" clearable />
                  <span class="mt-2 ml-8 mr-5">〜</span>
                  <date-pick-input v-model="form.endAt" clearable />
                </v-row>
                <v-row no-gutters class="pa-4">
                  <v-col
                    v-for="monthcol in [1, 4, 7, 10]"
                    :key="`monthcol${monthcol}`"
                  >
                    <v-checkbox
                      v-for="month in [monthcol, monthcol + 1, monthcol + 2]"
                      :key="`month${month}`"
                      v-model="form.tranMonths[month - 1]"
                      :label="`${month}月`"
                      class="mt-0"
                      hide-details
                    />
                  </v-col>
                </v-row>
              </v-card>
              <v-card class="pa-5 mt-3">
                <div class="fs-12 fw-bold">自動メール</div>
                <v-row no-gutters class="mt-3">
                  <v-col cols="4">
                    <v-switch
                      v-model="form.isSendScheduleEmail"
                      color="primary"
                      hide-details
                      label="自動でメール送信"
                    />
                  </v-col>
                  <v-col cols="2">
                    <v-menu
                      bottom
                      nudge-bottom="20"
                      transition="slide-y-transition"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon x-small v-bind="attrs" v-on="on">
                          feather-info
                        </v-icon>
                      </template>

                      <v-card class="sweeep-popover" width="400">
                        <v-card-title>自動メール送信設定方法</v-card-title>
                        <v-card-text>
                          <div>
                            取引先毎に起算日と起算日からの日数を設定することができます。
                          </div>
                          <div>
                            起算日がその月の月末を超えている場合は月末を起算日として扱います。
                          </div>
                          <br />
                          <div>(例)</div>
                          <div>請求書受領月 2021/1/1 ~ 2021/3/31</div>
                          <div>起算日：30</div>
                          <div>起算日からの日数: -2</div>
                          <div>
                            上記の設定の場合下記の日程で自動メール送信が行われます。
                          </div>
                          <div>2021/1/28, 2021/2/26, 2021/3/28</div>
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </v-col>
                  <v-col cols="3">
                    <v-autocomplete
                      v-model="form.beginningDay"
                      :items="beginningDayItems"
                      :disabled="!form.isSendScheduleEmail"
                      :rules="numberScheduleRequiredRulesNoZero"
                      :required="scheduleRequired"
                      validate-on-blur
                      label="起算日"
                      item-text="name"
                      item-value="value"
                      class="pr-2"
                    />
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model="form.incrementDay"
                      label="起算日からの日数"
                      :disabled="!form.isSendScheduleEmail"
                      :rules="numberScheduleRequiredRules"
                      :required="scheduleRequired"
                      validate-on-blur
                      type="number"
                      min="-31"
                      max="31"
                      class="fs-10"
                      style="width: 100px"
                    />
                  </v-col>
                </v-row>
                <v-autocomplete
                  v-model="form.emailTemplateId"
                  :items="emailTemplates"
                  :disabled="!form.isSendScheduleEmail"
                  :rules="numberScheduleRequiredRules"
                  :required="scheduleRequired"
                  validate-on-blur
                  label="メールテンプレート"
                  item-text="name"
                  item-value="id"
                />
              </v-card>
            </v-col>
            <v-col
              v-if="!form.isUncategorized"
              style="height: calc(90vh - 115px); overflow: scroll"
            >
              <v-card class="pa-5">
                <div class="fs-12 fw-bold">自社情報</div>
                <v-autocomplete
                  v-model="form.ownRepresentUserOrg"
                  :rules="textRequiredRules"
                  label="自社担当者"
                  filled
                  dense
                  outlined
                  hide-details
                  class="mt-3"
                  :readonly="readonly"
                  :items="validUserOrganizations"
                  item-text="name"
                  item-value="id"
                />
                <v-autocomplete
                  v-model="form.ownDepartmentId"
                  required
                  label="自社担当部門"
                  filled
                  dense
                  outlined
                  hide-details
                  class="mt-3 mb-3"
                  :readonly="readonly"
                  :items="departmentList"
                  item-text="name_code"
                  item-value="id"
                />
              </v-card>
              <v-card class="pa-5 mt-3">
                <div class="fs-12 fw-bold">デフォルト承認者</div>
                <div class="mt-3 mb-3">
                  <v-radio-group v-model="form.approvalType" row>
                    <v-radio
                      color="blue"
                      label="承認順序は問わない"
                      :value="1"
                    />
                    <v-radio
                      color="blue"
                      label="前段階の承認が必要"
                      :value="2"
                    />
                  </v-radio-group>
                  <v-autocomplete
                    v-model="form.approvalStage"
                    label="承認回数"
                    class="fs-10 py-0"
                    style="width: 150px"
                    filled
                    outlined
                    dense
                    hide-details
                    :items="approvalStageItems"
                  />
                </div>

                <div v-for="stage in computedApprovalStageItems" :key="stage">
                  <v-autocomplete
                    v-model="form.selectedUsers['approve' + String(stage)]"
                    chips
                    small-chips
                    multiple
                    clearable
                    :label="stageTitle(stage)"
                    filled
                    dense
                    outlined
                    hide-details
                    class="mt-3"
                    :readonly="readonly"
                    :items="approveUserOrganizations(stage)"
                    item-text="name"
                    item-value="id"
                  />
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="border-top-1">
        <v-btn v-if="editMode && !form.isUncategorized" @click="onClickDelete">
          <v-icon x-small class="mr-2" v-text="'feather-trash-2'" />
          削除
        </v-btn>
        <v-spacer />
        <v-btn @click.native="close">
          {{ $t('message.modal_cancel_btn') }}
        </v-btn>
        <v-btn v-if="!loading && !editMode" color="primary" @click="onClickAdd">
          {{ $t('message.add') }}
        </v-btn>
        <v-btn
          v-else-if="!loading && editMode"
          color="primary"
          @click="onClickUpdate"
        >
          {{ $t('message.submit') }}
        </v-btn>
        <v-btn v-else loading color="primary" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import InputDeleteDialog from '@/components/Dialog/InputDeleteDialog.vue'
import DatePickInput from '@/components/Input/DatePickInput.vue'

export default {
  components: {
    DatePickInput,
    InputDeleteDialog,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      editMode: false,
      approvalStageItems: [1, 2, 3, 4, 5, 6, 7, 8],

      headers: [
        { text: '', diplay: true, value: 'selectedUsers', sortable: false },
        { text: '名前', diplay: true, value: 'name' },
      ],
      form: {
        name: '',
        departmentName: '',
        representName: '',
        email: '',
        ownRepresentUserOrg: '',
        ownDepartmentId: '',
        uploadUrl: '',
        uploadEmail: '',
        status: 1,
        hiddenCustomerId: '',
        isUncategorized: false,
        approvalStage: 8,
        approvalType: 1,
        selectedUsers: {
          approve1: [],
          approve2: [],
          approve3: [],
          approve4: [],
          approve5: [],
          approve6: [],
          approve7: [],
          approve8: [],
        },
        startAt: '',
        endAt: '',
        tranMonths: [
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
        ],
        isSendScheduleEmail: false,
        beginningDay: null,
        incrementDay: null,
        emailTemplateId: null,
        useIssueDate: true,
      },
    }
  },
  computed: {
    ...mapGetters({
      userOrganizations: 'inboxUserOrganizations',
      emailTemplates: 'inboxEmailTemplates',
    }),
    maxWidth: function () {
      if (this.form.isUncategorized) {
        return '500px'
      }
      return '1000px'
    },
    computedApprovalStageItems: function () {
      if (!this.form.approvalStage) {
        return this.approvalStageItems
      }
      const approvalStageItems = [...new Array(this.form.approvalStage)].map(
        (_, i) => i + 1
      )
      approvalStageItems.splice(-1, 1, 8)
      return approvalStageItems
    },
    departmentList: function () {
      return this.$store.getters.inboxDepartments.filter(
        (item) => item.id !== null
      )
    },
    validUserOrganizations: function () {
      return this.$store.getters.inboxUserOrganizations.filter(
        (userOrg) => userOrg.status == 1
      )
    },
    customerUploadUrlId() {
      return (customerId) => 'c-upload-url-' + customerId
    },
    customerUploadUrlQuery() {
      return (customerId) => '#' + this.customerUploadUrlId(customerId)
    },
    customerUploadEmailId() {
      return (customerId) => 'c-upload-email-' + customerId
    },
    customerUploadEmailQuery() {
      return (customerId) => '#' + this.customerUploadEmailId(customerId)
    },
    readonly() {
      return this.form.isUncategorized
    },
    textRequiredRules() {
      if (this.readonly) {
        return []
      }
      return [
        (v) => !!v || this.$t('message.form_input_required'),
        (v) =>
          (v && v.replace(/\s/g, '').length >= 1) ||
          this.$t('message.form_input_required'),
      ]
    },
    numberScheduleRequiredRules() {
      if (!this.form.isSendScheduleEmail) {
        return []
      }
      return [(v) => v != null || this.$t('message.form_input_required')]
    },
    numberScheduleRequiredRulesNoZero() {
      if (!this.form.isSendScheduleEmail) {
        return []
      }
      const noZeroRule = [
        (v) => v != 0 || this.$t('message.form_input_required'),
      ]
      const rules = noZeroRule.concat(this.numberScheduleRequiredRules)
      return rules
    },
    emailRules() {
      if (this.readonly) {
        return []
      }
      return [
        (v) =>
          !v ||
          /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
            v
          ) ||
          '※正しい形式で入力ください',
        (v) =>
          v.length <= 100 || '100' + this.$t('message.character_max_length'),
      ]
    },
    approveUserOrganizations() {
      return (stage) =>
        this.userOrganizations.filter(
          (user) => user.status == 1 && this.hasPrivilege(stage, user)
        )
    },
    footerProps() {
      return {
        'items-per-page-text': '表示件数',
        'items-per-page-options': [20, 50, 100],
      }
    },
    beginningDayItems() {
      const dayItems = []
      for (let i = 31; i >= 1; i--) {
        if (i == 31) {
          dayItems.push({ name: '月末', value: i })
        } else {
          dayItems.push({ name: i + '日', value: i })
        }
      }
      return dayItems
    },
    scheduleRequired() {
      if (this.form.isSendScheduleEmail) {
        return true
      }
      return false
    },
  },
  methods: {
    open(form, editMode = false) {
      this.editMode = editMode
      this.clearform()
      for (let [field, value] of Object.entries(
        JSON.parse(JSON.stringify(form))
      )) {
        this.form[field] = value
      }
      this.dialog = true
      this.setSelectedUsers(JSON.parse(JSON.stringify(form.approvalUsers)))

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    close() {
      this.dialog = false
      this.loading = false
      this.clearform()
    },
    startLoading() {
      this.loading = true
    },
    stopLoading() {
      this.loading = false
    },
    onClickAdd() {
      if (this.$refs.form.validate()) {
        this.$emit('create-customer', this.form)
        this.close()
      }
    },
    onClickUpdate() {
      if (this.$refs.form.validate() || this.readOnly) {
        this.$emit('update-customer', this.form)
        this.close()
      }
    },
    clearform() {
      this.form = {
        name: '',
        departmentName: '',
        representName: '',
        email: '',
        ownRepresentUserOrg: '',
        ownDepartmentId: '',
        uploadUrl: '',
        uploadEmail: '',
        status: 1,
        hiddenCustomerId: '',
        isUncategorized: false,
        approvalStage: 8,
        approvalType: 1,
        selectedUsers: {
          approve1: [],
          approve2: [],
          approve3: [],
          approve4: [],
          approve5: [],
          approve6: [],
          approve7: [],
          approve8: [],
        },
        startAt: '',
        endAt: '',
        tranMonths: [
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
        ],
        isSendScheduleEmail: false,
        beginningDay: null,
        incrementDay: null,
        emailTemplateId: null,
        useIssueDate: true,
      }
    },
    setSelectedUsers(selectedUsers) {
      for (let [approvalKey, users] of Object.entries(selectedUsers)) {
        this.form.selectedUsers[approvalKey] = []
        for (let user of users) {
          this.form.selectedUsers[approvalKey].push(String(user.id))
        }
      }
    },
    onClickDelete() {
      this.$refs.dialogDelete.open()
    },
    stageTitle(stage) {
      if (stage == 8) {
        return '最終承認'
      }
      return '第' + String(stage) + '承認'
    },
    toggleOne(stage, item) {
      try {
        const stageKey = 'approve' + String(stage)
        if (
          this.form.selectedUsers[stageKey].find((user) => user.id == item.id)
        ) {
          this.form.selectedUsers[stageKey] = this.form.selectedUsers[
            stageKey
          ].filter((v) => v.id != item.id)
        } else {
          this.form.selectedUsers[stageKey].push(item)
        }
      } catch (e) {
        console.log(e)
      }
    },
    hasPrivilege(stage, user) {
      const approvalKey = 'approve' + String(stage)
      return user.privileges.inbox[approvalKey]
    },
    getScrollHeight() {
      return 'max-height: calc(100vh - 230px)'
    },
    onClickDelete() {
      if (this.checkPrivilege('inbox:delete_customer')) {
        this.$refs.dialogDelete.open()
      } else {
        this.$refs.noPrivilegeDialog.open()
      }
    },
    cardTextClass(stage) {
      if (stage == 8) {
        return 'pa-8'
      }
      return 'pa-8 mb-5'
    },
  },
}
</script>
