<template>
  <v-toolbar flat>
    <v-menu nudge-bottom="45" transition="slide-y-transition">
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <v-btn
            v-tooltip="'選択中のデータを一括で操作'"
            class="mr-4"
            v-text="'一括操作'"
          />
        </div>
      </template>
      <v-list class="fs-12">
        <v-list-item class="border-bottom-1" @click="$emit('bulk-edit')">
          <s-icon class="tw-mr-3" icon="feather-edit-2" />
          編集
        </v-list-item>
        <v-list-item class="border-bottom-1" @click="$emit('bulk-delete')">
          <s-icon class="tw-mr-3" icon="feather-trash-2" />
          削除
        </v-list-item>
      </v-list>
    </v-menu>
    <v-btn class="mr-2" @click="$emit('send-emails')">
      <s-icon class="tw-mr-2" icon="feather-mail" />
      メール
    </v-btn>
    <v-btn @click="$emit('add-customer')">
      <s-icon class="tw-mr-2" icon="feather-plus" />
      取引先
    </v-btn>
    <v-btn class="ml-2" @click.native.stop="$emit('import-customers')">
      インポート
    </v-btn>
    <v-btn
      v-tooltip="'インポート用のデータサンプルを取得'"
      class="ml-2"
      text
      @click="$emit('get-import-sample')"
    >
      {{ $t('message.get_customers_csv') }}
    </v-btn>

    <v-spacer />
    <v-checkbox
      v-model="filterForm.showUncategorized"
      class="mt-5 mr-4"
      label="未分類を表示"
      color="primary"
      @change="$emit('change-filter', filterForm)"
    />

    <v-autocomplete
      v-model="filterForm.status"
      :items="statusFilterItems"
      filled
      outlined
      dense
      hide-details
      item-text="name"
      item-value="id"
      class="more-dense"
      style="max-width: 150px"
      @change="$emit('change-filter', filterForm)"
    />
    <v-menu offset-y bottom nudge-bottom="20" transition="slide-y-transition">
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">
          <s-icon class="ml-2" icon="feather-info" />
        </span>
      </template>

      <v-card class="sweeep-popover" width="400">
        <v-card-title>取引先の表示／非表示</v-card-title>
        <v-card-text>
          <div>
            利用しなくなった取引先は「非表示」に指定することができます。
          </div>
          <div>
            表示／非表示の設定は取引先名をクリックして表示される画面で変更でき、いつでも戻すことができます。
          </div>
        </v-card-text>
      </v-card>
    </v-menu>
    <v-text-field
      v-model="filterForm.text"
      v-tooltip="$t('message.filter_bar_tooltip_on_inbox_customers')"
      append-icon="search"
      placeholder="簡単フィルタ"
      hide-details
      clearable
      class="more-dense ml-3"
      dense
      filled
      @input="$emit('change-filter', filterForm)"
    />
    <v-menu
      offset-y
      nudge-bottom="20"
      z-index="99"
      transition="slide-y-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <v-btn small depressed class="ml-2">
            説明
            <s-icon class="pl-2" icon="feather-info" />
          </v-btn>
        </div>
      </template>

      <v-card class="sweeep-popover" width="650">
        <v-card-title>ステータスについて</v-card-title>
        <v-card-text>
          <div class="mb-2">
            一覧に表示されている請求書処理ステータスは以下の内容です
          </div>
          <div class="mt-1">
            <s-chip class="fw-bold px-3 mr-2"> 未請求 </s-chip>
            <span style="position: absolute; left: 85px"
              >まだ何も処理していない状態です。</span
            >
          </div>
          <div class="mt-1">
            <s-chip-dark class="fw-bold px-3 mr-2" color="blue-500">
              受領済
            </s-chip-dark>
            <span style="position: absolute; left: 85px"
              >請求書がこの画面に格納された状態です。</span
            >
          </div>
          <div class="mt-1">
            <s-chip-dark class="fw-bold px-3 mr-2" color="green-500">
              承認中
            </s-chip-dark>
            <span style="position: absolute; left: 85px"
              >請求書に対する承認操作を開始した段階です。</span
            >
          </div>
          <div class="mt-1">
            <s-chip-dark class="fw-bold px-3 mr-2" color="indigo-800">
              取込済
            </s-chip-dark>
            <span style="position: absolute; left: 85px"
              >最終承認が完了し請求書メニューにデータ転送された状態です。ステータスの変更はできません。</span
            >
          </div>
          <div class="mt-1">
            <s-chip-dark class="fw-bold px-2 mr-2" color="gray-400">
              回収不要
            </s-chip-dark>
            <span style="position: absolute; left: 85px"
              >請求予定がない状態です。不要にするための操作が必要です。</span
            >
          </div>
          <div class="mt-1">
            <s-chip-dark class="fw-bold px-4 mr-2" color="pink-600">
              否認
            </s-chip-dark>
            <span style="position: absolute; left: 85px"
              >請求書が承認フローの中で否認された状態です。ステータスの変更はできません。</span
            >
          </div>
          <div class="mt-1">
            <s-chip-dark class="fw-bold px-3 mr-2" color="red-500">
              削除済
            </s-chip-dark>
            <span style="position: absolute; left: 85px"
              >請求書に対して削除操作された状態です。ステータスの変更はできません。</span
            >
          </div>
          <div class="mt-3">
            <s-chip-dark class="fw-bold px-3 mr-2" color="yellow-500">
              依頼済
            </s-chip-dark>
            <span style="position: absolute; left: 85px"
              >取引先に依頼メールを送った状態です。</span
            >
          </div>
        </v-card-text>
      </v-card>
    </v-menu>
  </v-toolbar>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      filterForm: {
        status: 1,
        text: '',
        showUncategorized: false,
      },
      statusFilterItems: [
        { id: 1, name: '「非表示」を除外' },
        { id: 2, name: '「非表示」のみ' },
        { id: 3, name: '全ての取引先' },
      ],
    }
  },
  computed: {
    ...mapGetters({
      tableParams: 'inboxCustomersTableParams',
    }),
  },
  watch: {
    'tableParams.text': {
      handler(text) {
        this.filterForm.text = text
      },
      immediate: true,
    },
    'tableParams.status': {
      handler(status) {
        this.filterForm.status = status
      },
      immediate: true,
    },
    'tableParams.showUncategorized': {
      handler(showUncategorized) {
        this.filterForm.showUncategorized = showUncategorized
      },
      immediate: true,
    },
  },
}
</script>
