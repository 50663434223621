function convertBase64toBlob(base64, contentType) {
  var bin = atob(base64)
  var buffer = new Uint8Array(bin.length)
  for (var i = 0; i < bin.length; i++) {
    buffer[i] = bin.charCodeAt(i)
  }
  // Blobを作成
  try {
    var blob = new Blob([buffer.buffer], {
      type: contentType,
    })
  } catch (e) {
    return false
  }
  return blob
}

export { convertBase64toBlob }
