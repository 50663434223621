<template>
  <v-container v-if="checkPrivilege('inbox:read')" fluid pa-0>
    <base-dialog
      ref="dialogDisapprove"
      :title="$t('message.confirm')"
      :message="$t('message.disapprove_message')"
      :cancel-text="$t('message.modal_cancel_btn')"
      :submit-text="$t('message.ok')"
      :close-on-submit="true"
      @submit="disapprove"
    />
    <base-dialog
      ref="dialogDelete"
      :title="$t('message.confirm')"
      :message="$t('message.delete_item_message')"
      :cancel-text="$t('message.modal_cancel_btn')"
      :submit-text="$t('message.ok')"
      :close-on-submit="true"
      @submit="deleteInbox"
    />

    <v-row no-gutters style="flex-wrap: nowrap">
      <v-col
        class="flex-grow-0 flex-shrink-0 pa-0 ma-0"
        :style="inboxSideMenuStyle"
      >
        <inbox-side-menu
          ref="inboxSideMenu"
          v-model="sideMenu.open"
          :max-width="sideMenu.maxWidth"
          :min-width="sideMenu.minWidth"
          @change-department="onChangeOwnDepartment"
        />
      </v-col>
      <v-col class="flex-grow-1 flex-shrink-1" style="overflow: hidden">
        <v-card tile outlined>
          <inbox-menu
            @select-menu="setInboxMenu"
            @to-next-month="onChangeDisplayMonth('next')"
            @to-prev-month="onChangeDisplayMonth('prev')"
            @change-date-type="updateInboxItemsParams"
            @change-date-from="updateInboxItemsParams"
            @change-date-to="updateInboxItemsParams"
          />
          <div style="height: calc(100vh - 108px)">
            <inbox-customers
              v-if="inboxMenu === 'collect'"
              ref="inboxCustomers"
              @get-inbox-items="getInboxItems"
              @get-table-data="getCustomerTableData"
              @get-customers="getInboxCustomers"
              @update-sort-num="updateSortNum"
              @update-submit-status="updateSubmitStatus($event, true)"
              @update-inbox="updateInbox"
              @approve="approve"
              @cancel-approve="cancelApprove"
              @disapprove="openDialogDisapprove"
              @delete="openDialogDelete"
              @send-request="requestApproval($event)"
            />
            <inbox-detail
              v-else
              ref="inboxDetail"
              @get-inbox-items="getInboxItems"
              @approve="approve"
              @cancel-approve="cancelApprove"
              @change-menu-status="updateInboxItemsParams"
              @update-inbox="updateInbox"
              @disapprove="openDialogDisapprove"
              @delete="openDialogDelete"
              @send-request="requestApproval($event)"
            />
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Axios from 'axios'
import { mapGetters } from 'vuex'

// helpers
import { getCurrentAppLayout } from '@/helpers/helpers'
import Snackbar from '@/helpers/snackbar/index'
import { formatDate } from '@/pages/inbox/@modules/helpers/date'

// const
import submitStatuses from '@/pages/inbox/@modules/data/submitStatuses.js'

// components
import InboxSideMenu from '@/pages/inbox/@components/InboxSideMenu/InboxSideMenu'
import InboxMenu from '@/pages/inbox/@components//InboxMenu/InboxMenu'
import InboxDetail from '@/pages/inbox/@components//InboxDetail/InboxDetail'
import InboxCustomers from '@/pages/inbox/@components//InboxCustomers/InboxCustomers'

export default {
  components: {
    InboxSideMenu,
    InboxMenu,
    InboxDetail,
    InboxCustomers,
  },
  data() {
    return {
      sideMenu: {
        open: false,
        maxWidth: '240px',
        minWidth: '60px',
      },
      uploadUrls: [],
    }
  },
  computed: {
    ...mapGetters([
      'imageData',
      'inboxMenu',
      'inboxCustomers',
      'inboxItems',
      'inboxCustomersTableData',
    ]),
    inboxSideMenuStyle() {
      let width = this.sideMenu.minWidth
      if (this.sideMenu.open) {
        width = this.sideMenu.maxWidth
      }
      return `
        min-width: ${width};
        max-width: ${width};
      `
    },
    submitStatuses() {
      return submitStatuses
    },
  },
  mounted() {
    this.signin()

    this.$store.dispatch('getInboxDepartments')
    this.$store.dispatch('getInboxEmailTemplates')

    this.getInboxCustomers()
    this.$store.dispatch('initInboxItemsStates')
  },
  methods: {
    onChangeDisplayMonth(mode) {
      if (mode === 'next') {
        this.$refs.inboxCustomers.toNextMonth()
      } else {
        this.$refs.inboxCustomers.toPrevMonth()
      }
    },
    onChangeOwnDepartment(obj) {
      this.$store.dispatch('updateInboxCustomersTableParams', {
        ownDepartmentId: obj.id,
        isAll: obj.is_all,
      })
      this.updateInboxItemsParams({
        ownDepartmentId: obj.id,
      })
    },
    signin() {
      const user_organization_id = this.$route.query.userorganizationid
      if (!user_organization_id) {
        return
      }
      if (user_organization_id == this.$store.getters.getUserOrganizationID) {
        return
      }

      try {
        let confirmUrl = this.$store.getters.apiConfirmSignInAnother

        Axios.get(confirmUrl, {
          headers: { Authorization: this.$store.getters.getAuthToken },
          params: {
            user_id: this.$store.getters.getUserID,
            user_organization_id: user_organization_id,
          },
        }).then((response) => {
          if (response.data.status == 'success' && response.data.can_signin) {
            var apiUrl = this.$store.getters.apiSignInAnother
            var token = this.$store.getters.getAuthToken
            var envtype = this.$store.getters.getEnvType

            this.$store
              .dispatch('apiSignInAnother', {
                user_organization_id,
                envtype,
                apiUrl,
                token,
              })
              .then((response) => {
                if (response.data.error) {
                  Snackbar.error(response.data.error)
                }
              })
          } else {
            Snackbar.error(response.data.error)
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    getInboxItemOnQueryParams() {
      let inboxId = this.$route.params.id
      if (!inboxId) {
        return
      }
      if (this.inboxMenu == 'collect' && !this.inboxCustomersTableData) {
        return
      } else if (this.inboxMenu == 'inbox' && !this.inboxItems) {
        return
      }

      let inboxItem = null
      inboxItem = this.findInboxItemFromTable(inboxId)

      this.$refs.inboxCustomers.drawCollectedInvoice(inboxId, inboxItem)
      this.$router.replace('/inbox')
    },
    findInboxItemFromTable(inboxId) {
      for (let row of this.inboxCustomersTableData) {
        if (row) {
          for (let items of row.items) {
            if (items) {
              for (let item of items) {
                if (item.id == inboxId) {
                  return item
                }
              }
            }
          }
        }
      }
      return null
    },
    openDialogDisapprove(inbox) {
      this.$refs.dialogDisapprove.open(inbox)
    },
    openDialogDelete(inbox) {
      this.$refs.dialogDelete.open(inbox)
    },
    disapprove(params) {
      params.status = submitStatuses.DISAPPROVED.status
      this.updateInbox(params)
    },
    deleteInbox(params) {
      params.status = submitStatuses.DELETED.status
      this.updateInbox(params)
    },
    updateSubmitStatus(params) {
      try {
        const payload = {
          inboxId: params.inboxId,
          status: params.status,
        }
        this.$store.dispatch('patchInboxItem', payload).then(() => {
          Snackbar.success('ステータスが変更されました')
          this.updateItemsOrTable(params)
        })
      } catch (err) {
        console.log(err)
      }
    },
    updateInbox(params) {
      try {
        const payload = {
          inboxId: params.inboxId,
          status: params.status ? params.status : null,
          purpose: params.purpose,
          comment: params.comment,
          customerId: params.customerId,
          transactionYear: params.transactionYear,
          transactionMonth: params.transactionMonth,
        }

        this.$store.dispatch('patchInboxItem', payload).then(() => {
          Snackbar.success('更新しました')
          this.getTableAndUpdateItemsOrTable(params)
        })
      } catch (err) {
        console.log(err)
      }
    },
    getTableAndUpdateItemsOrTable(params) {
      if (this.inboxMenu === 'collect') {
        this.getCustomerTableData()
      }
      this.updateItemsOrTable(params)
    },
    updateItemsOrTable(params) {
      const payload = {
        inboxId: params.inboxId,
        status: params.status,
      }
      if (this.inboxMenu === 'collect') {
        this.$refs.inboxCustomers.updateSelectedInboxItem(payload)
      } else {
        this.getInboxItems()
        this.$refs.inboxDetail.updateSelectedInboxItem(payload)
      }
    },
    updateInboxItemState(params) {
      try {
        const customer = this.inboxCustomers.find(
          (obj) => obj.id === params.customerId
        )
        const payload = {
          id: params.inboxId,
          customer: customer ? customer : undefined,
          submit_status: params.status,
          submit_comment: params.comment,
          transaction_year: params.transactionYear,
          transaction_month: params.transactionMonth,
        }
        this.$store.dispatch('updateInboxItemState', payload)
      } catch (err) {
        console.log(err)
      }
    },
    approve(params) {
      if (!this.checkPrivilege('inbox:approve' + params.stage)) {
        Snackbar.error('権限がありません')
        return
      }

      if (params.stage == 8) {
        this.finalApproveItem(params)
      } else {
        this.approveItem(params)
      }
    },
    approveItem(params) {
      this.$store
        .dispatch('approveInboxItem', params)
        .then((response) => {
          if (this.inboxMenu == 'collect') {
            this.$refs.inboxCustomers.updateInboxApproval(response.data)
          } else {
            this.getCustomerTableData()
            this.$refs.inboxDetail.updateInboxApproval(response.data)
          }
          Snackbar.success('承認されました')
        })
        .catch((err) => {
          Snackbar.error(err.message)
        })
    },
    cancelApprove(params) {
      if (!this.checkPrivilege('inbox:approve' + params.stage)) {
        Snackbar.error('権限がありません')
        return
      }

      this.$store
        .dispatch('approveCancelInboxItem', params)
        .then(() => {
          if (this.inboxMenu == 'collect') {
            this.$refs.inboxCustomers.canselInboxApproval(params.stage)
          } else {
            this.getCustomerTableData()
            this.$refs.inboxDetail.canselInboxApproval(params.stage)
          }
          Snackbar.success('承認がキャンセルされました')
        })
        .catch((err) => {
          Snackbar.error(err.message)
        })
    },
    finalApproveItem(params) {
      try {
        let refsInboxMenu = null
        if (this.inboxMenu == 'collect') {
          refsInboxMenu = this.$refs.inboxCustomers
        } else {
          refsInboxMenu = this.$refs.inboxDetail
        }
        refsInboxMenu.startApproveLoading()

        this.$store
          .dispatch('finalApproveInboxItem', params)
          .then((response) => {
            this.updateItemsOrTable({
              status: this.submitStatuses.IMPORTED.status,
              inboxId: params.inboxId,
            })
            if (this.inboxMenu == 'inbox') {
              this.getCustomerTableData()
            }
            refsInboxMenu.updateInboxApproval(response.data)

            refsInboxMenu.stopApproveLoading()
            Snackbar.success('承認されました')
          })
          .catch((err) => {
            refsInboxMenu.stopApproveLoading()
            Snackbar.error(err.message)
          })
      } catch (error) {
        console.log(error)
      }
    },
    updateSortNum(params) {
      try {
        this.$store.dispatch('updateSortNum', params).then((response) => {
          if (response.status == 'success') {
            this.$refs.inboxCustomers.closeDialogEditSortNum()
            Snackbar.success('更新しました')
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    getInboxCustomers() {
      try {
        this.$store.dispatch('getInboxCustomers')
      } catch (e) {
        console.log(e)
      }
    },
    getInboxItems() {
      try {
        this.$store
          .dispatch('getInboxItems')
          .then(() => {
            this.getInboxItemOnQueryParams()
          })
          .catch((err) => {
            Snackbar.error(err.message)
          })
      } catch (e) {
        console.log(e)
      }
    },
    getCustomerTableData() {
      try {
        this.$store.dispatch('getInboxCustomersTableData').then(() => {
          this.getInboxItemOnQueryParams()
        })
      } catch (e) {
        console.log(e)
      }
    },
    updateInboxItemsParams(params) {
      this.$store.dispatch('updateInboxItemsParams', params)
    },
    setInboxMenu(menu) {
      this.$store.dispatch('setInboxMenu', menu)
    },
    requestApproval(params) {
      try {
        let refsInboxMenu = null
        if (this.inboxMenu == 'collect') {
          refsInboxMenu = this.$refs.inboxCustomers
        } else {
          refsInboxMenu = this.$refs.inboxDetail
        }

        const parmas = {
          inboxItemId: params.inboxItemId,
          approvalType: params.approvalType,
          maxApprovalStage: params.maxApprovalStage,
          nextApprovalUsers: params.nextApproveRequestUsers,
        }
        this.$store
          .dispatch('putNextApprovalRequests', parmas)
          .then((response) => {
            if (response.status == 'success') {
              Snackbar.success('承認依頼が送信されました。')
            }
            refsInboxMenu.stopSendRequestLoading()
          })
          .catch((err) => {
            console.log(err)
            if (err.message == 'invalid_status') {
              Snackbar.error('別のユーザによってステータスが変更されています。')
            } else {
              Snackbar.error(err.message)
            }
            refsInboxMenu.stopSendRequestLoading()
          })
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
