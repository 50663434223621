<template>
  <v-card class="sweeep-dialog">
    <v-card-title v-text="$t('message.email')" />
    <email-summary ref="summary" :form="form" :bulk="bulk" />
    <email-template
      ref="template"
      :value="form.templateId"
      :items="templates"
      :edited="editedBody"
      :updatable="editable"
      @change="onChangeTemplate"
      @rebind="reBindTemplateId"
      @create="onCreateTemplate"
      @update="onUpdateTemplate"
      @delete="onDeleteTemplate"
    />
    <email-body ref="body" :value="form.body" @change="onChangeBody" />
    <email-actions
      ref="actions"
      :form="form"
      @send="onSend"
      @send-test="onSendTest"
      @cancel="onCancel"
    />
  </v-card>
</template>

<script>
import {
  checkCCDomain,
  checkBCCDomain,
  getEmailBodyTemplate,
} from '@/pages/inbox/@modules/helpers/email'
import EmailSummary from './EmailSummary/EmailSummary'
import EmailTemplate from './EmailTemplate/EmailTemplate'
import EmailBody from './EmailBody/EmailBody'
import EmailActions from './EmailActions/EmailActions'

export default {
  components: {
    EmailSummary,
    EmailTemplate,
    EmailBody,
    EmailActions,
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
    customers: {
      type: Array,
      required: true,
    },
    bulk: {
      type: Boolean,
      default: true,
    },
    templates: {
      type: Array,
      required: true,
    },
    userOrganizations: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      countChangedBody: 1,
    }
  },
  computed: {
    getTemplate() {
      return (id) => {
        const obj = this.templates.find((obj) => obj.id === id)
        return obj ? obj : { id: '', name: '', body: '', is_default: false }
      }
    },
    template() {
      return this.getTemplate(this.form.templateId)
    },
    editable() {
      return !this.template.is_default
    },
    editedBody() {
      return this.countChangedBody > 1
    },
    customer() {
      return this.customers[0]
    },
  },
  methods: {
    onChangeTemplate(templateId) {
      this.countChangedBody = 0

      const template = this.getTemplate(templateId)
      this.form.templateId = templateId
      this.form.body = template.body
    },
    onChangeBody(body) {
      // 発火のタイミイング
      // 1: 2回目でopenした際
      // 2: テンプレート変更
      // 3: ユーザによる入力
      this.form.body = body
      this.countChangedBody += 1
    },
    onCreateTemplate(name) {
      this.$emit('create-template', name)
    },
    onUpdateTemplate(name) {
      this.$emit('update-template', name)
      this.countChangedBody = 1
    },
    onDeleteTemplate() {
      this.$emit('delete-template')
    },
    onSend() {
      this.$emit('send')
    },
    onSendTest() {
      this.$emit('send-test')
    },
    onCancel() {
      this.stopLoading()
      this.$emit('cancel')
      this.countChangedBody = 0
    },
    stopLoading() {
      this.$refs.template.stopLoading()
      this.$refs.actions.stopLoading()
    },
    reBindTemplateId() {
      const _id = this.form.templateId
      this.form.templateId = 0
      setTimeout(() => {
        this.form.templateId = _id
      }, 10)
    },
    validate() {
      return (
        checkCCDomain(this.form, this.customer) && checkBCCDomain(this.form)
      )
    },
    setEmailFormBody(usePublicUrl = true) {
      const customer = this.customer
      const organizationName = this.$store.getters.getOrganizationName
      let textBody = getEmailBodyTemplate()
      let userName = this.userOrganizations.filter(
        (data) => data.id == customer.own_represent_user_org
      )[0].name
      textBody = this._replaceAll(textBody, '%取引先会社名%', customer.name)
      textBody = this._replaceAll(
        textBody,
        '%取引先担当者名%',
        customer.represent_name
      )
      textBody = this._replaceAll(textBody, '%会社名%', organizationName)
      textBody = this._replaceAll(textBody, '%自社担当者名%', userName)
      if (usePublicUrl && customer.upload_url) {
        textBody = this._replaceAll(
          textBody,
          '%アップロードURL%',
          customer.upload_url
        )
        this.form.hidden_upload_url = customer.upload_url
      } else {
        textBody = this._replaceAll(
          textBody,
          '%アップロードURL%',
          customer.temp_upload_url
        )
        this.form.hidden_upload_url = customer.temp_upload_url
      }
      this.form.body = textBody
    },
    _replaceAll(text, from, to) {
      text = text.split(from)
      return text.join(to)
    },
    _getBooleanValueFromString(v) {
      if (typeof v == 'boolean') {
        return v
      }
      v = v.toLowerCase()
      if (v == 'true') {
        return true
      }
      return false
    },
  },
}
</script>
