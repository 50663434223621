function formatDate(date) {
  var Y = date.getFullYear()
  var M = date.getMonth() + 1
  var D = date.getDate()
  var yyyy = ('000' + Y).slice(-4)
  var mm = ('0' + M).slice(-2)
  var dd = ('0' + D).slice(-2)
  return yyyy + '-' + mm + '-' + dd
}

export { formatDate }
