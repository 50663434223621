<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <base-dialog
      ref="dialog"
      size="small"
      :title="$t('message.edit_sort_num')"
      :cancel-text="$t('message.modal_cancel_btn')"
      :submit-text="$t('message.modal_save_btn')"
      @submit="submit"
    >
      <template v-slot:card-text>
        <v-text-field
          v-model="innerValue"
          :rules="inputSortNumRules"
          type="number"
          single-line
        />
      </template>
    </base-dialog>
  </v-form>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      valid: false,
      sortNum: 0,
      inputSortNumRules: [
        (v) => !!v || this.$t('message.form_input_required'),
        (v) =>
          (!!v && v > 0) || this.$t('message.form_input_required_above_zero'),
      ],
    }
  },
  computed: {
    innerValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  methods: {
    open() {
      this.$refs.dialog.open()
      this.resetValidation()
      this.dialog = true
    },
    close() {
      this.$refs.dialog.close()
      this.dialog = false
    },
    submit() {
      if (this.validate()) {
        this.$emit('submit')
      }
    },
    validate() {
      return this.$refs.form.validate()
    },
    resetValidation() {
      if (this.dialog) {
        this.$nextTick(function () {
          this.$refs.form.resetValidation()
        })
      }
    },
    reset() {
      if (this.dialog) {
        this.$nextTick(function () {
          this.$refs.form.reset()
        })
      }
    },
  },
}
</script>
