<template>
  <v-dialog v-model="dialog" max-width="550px">
    <v-card class="sweeep-dialog">
      <v-card-title v-text="$t('message.confirm')" />
      <v-card-text>
        CCまたはBCCに差出人と異なるドメインが入力されています。
        <br />
        本当に送信してもよろしいでしょうか？
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="cancel"> キャンセル </v-btn>
        <v-btn color="primary" @click="submit">
          <v-icon x-small class="mr-2"> feather-mail </v-icon>
          送信
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      data: {},
    }
  },
  methods: {
    open(data) {
      this.data = data
      this.dialog = true
    },
    close() {
      this.dialog = false
    },
    cancel() {
      this.$emit('cancel')
      this.close()
    },
    submit() {
      this.$emit('submit', this.data)
      this.close()
    },
  },
}
</script>
