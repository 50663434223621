<template>
  <v-dialog v-model="dialog" width="400">
    <v-card id="dialog-upload" class="h-100">
      <v-card-text class="pa-6">
        <dropzone
          id="myVueDropzone"
          ref="dropzone"
          :options="dropzoneOptions"
          class="h-100 grey lighten-3"
          @vdropzone-files-added="onAdd($event)"
        >
          <input type="hidden" name="token" value="xxx" />
        </dropzone>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Dropzone from 'vue2-dropzone'
import Vue from 'vue'
import { errorNotify } from '@/helpers/helpers'

export default {
  components: {
    Dropzone,
  },
  data() {
    return {
      dialog: false,
      form: {},
      dropzoneOptions: {
        url: this.$store.getters.apiInboxUploadFunction,
        thumbnailWidth: 500,
        thumbnailHeight: 800,
        thumbnailMethod: 'contain',
        method: 'post',
        uploadMultiple: false,
        autoProcessQueue: false,
        maxFilesize: 2,
        maxFiles: 1,
        headers: { Authorization: this.$store.getters.getAuthToken },
        dictDefaultMessage:
          '<i class="feather-download" style="font-size:20px;color:#000"></i><br><br>ファイルをドラッグ＆ドロップするか<br>クリックして請求書をアップロードください。',
      },
    }
  },
  methods: {
    open(form) {
      this.form = form
      if (this.$refs.dropzone) {
        this.$refs.dropzone.removeAllFiles()
      }
      this.dialog = true
    },
    close() {
      this.dialog = false
    },
    onAdd(files) {
      if (files.length >= 2) {
        errorNotify(this.$t('message.inbox_multiple_upload_message'))
        Vue.nextTick(() => {
          this.$refs.dropzone.removeAllFiles()
        })
        return
      }
      this.$refs.dropzone.processQueue()
      this.$emit('added', { ...this.form, file: files[0] })
    },
  },
}
</script>
