<template>
  <v-card-text class="py-0 my-0">
    <base-dialog
      ref="dialogTemplateName"
      :title="isNewTemplate ? 'テンプレート作成' : 'テンプレート更新'"
      message="テンプレート名を入力してください"
      :cancel-text="$t('message.modal_cancel_btn')"
      :submit-text="isNewTemplate ? '作成' : '更新'"
      persistent
      @submit="isNewTemplate ? emitCreateTemplate() : emitUpdateTemplate()"
    >
      <template #card-content>
        <v-form ref="form" lazy-validation>
          <v-text-field
            v-model="newName"
            class="mt-3"
            :placeholder="isNewTemplate ? 'テンプレート名' : ''"
            :rules="requiredRules"
            required
            dense
            outlined
            filled
          />
        </v-form>
      </template>
    </base-dialog>

    <base-dialog
      ref="dialogConfirmChange"
      title="確認"
      message="編集中のメール文を破棄して置き換えますがよろしいですか？"
      :cancel-text="$t('message.modal_cancel_btn')"
      submit-text="適用"
      persistent
      close-on-submit
      @cancel="$emit('rebind')"
      @submit="onConfirmedChangeTemplate"
    />

    <base-dialog
      ref="dialogDeleteTemplateConfirm"
      title="テンプレート削除"
      message="このテンプレートを削除しますか？"
      :cancel-text="$t('message.modal_cancel_btn')"
      :submit-text="$t('message.delete')"
      @submit="$emit('delete')"
    />

    <v-row no-gutters class="px-2">
      <v-autocomplete
        :value="value"
        label="メールテンプレートを選択"
        :items="items"
        item-value="id"
        item-text="name"
        @change="onChangeTemplate"
      />
      <v-btn
        v-tooltip="'カスタムテンプレートを更新'"
        small
        class="ml-2 mt-4"
        :loading="loadingUpdate"
        :disabled="!updatable"
        @click="onUpdateTemplate"
      >
        更新
      </v-btn>
      <v-btn
        v-tooltip="'このメールをカスタムテンプレートとして登録'"
        small
        class="ml-2 mt-4"
        @click="onCreateTemplate"
        v-text="'登録'"
      />
      <v-btn
        v-tooltip="'カスタムテンプレートを削除'"
        small
        class="ml-2 mt-4"
        :disabled="!updatable"
        @click="onDeleteTemplate"
        v-text="'削除'"
      />
    </v-row>
  </v-card-text>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 1,
    },
    items: {
      type: Array,
      required: true,
    },
    edited: {
      type: Boolean,
      required: true,
    },
    updatable: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      tempData: null,
      loadingUpdate: false,

      isNewTemplate: false,
      newName: '',
      requiredRules: [
        (v) => !!v || this.$t('message.form_input_required'),
        (v) =>
          (v && v.replace(/\s/g, '').length >= 1) ||
          this.$t('message.form_input_required'),
        (v) => !this.isDuplicate(v) || 'すでに登録済みの名称です',
      ],
    }
  },
  computed: {
    isDuplicate() {
      return (name) => {
        if (this.isNewTemplate) {
          return this.items.some((obj) => obj.name === name)
        }
      }
    },
  },
  methods: {
    onChangeTemplate(value) {
      if (this.edited) {
        this.tempData = value
        this.$refs.dialogConfirmChange.open()
      } else {
        this.$emit('change', value)
      }
    },
    onConfirmedChangeTemplate() {
      this.$emit('change', this.tempData)
    },
    onCreateTemplate() {
      this.isNewTemplate = true
      this.$refs.dialogTemplateName.open()
    },
    emitCreateTemplate() {
      if (!this.$refs.form.validate()) {
        return
      }
      this.$refs.dialogTemplateName.startLoading()
      this.$emit('create', this.newName)
    },
    onUpdateTemplate() {
      const index = this.items.findIndex((obj) => obj.id === this.value)
      this.newName = this.items[index].name
      this.isNewTemplate = false
      this.$refs.dialogTemplateName.open()
    },
    emitUpdateTemplate() {
      this.$refs.dialogTemplateName.startLoading()
      this.$emit('update', this.newName)
    },
    onDeleteTemplate() {
      this.$refs.dialogDeleteTemplateConfirm.open()
    },
    stopLoading() {
      this.loadingUpdate = false
      this.$refs.dialogTemplateName.stopLoading()
    },
  },
}
</script>
