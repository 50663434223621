export default [
  {
    id: 1,
    name: '全て',
    action: 'feather-inbox',
    status: -1,
  },
  {
    id: 4,
    name: '受領済',
    action: '',
    status: 3,
  },
  {
    id: 5,
    name: '承認中',
    action: '',
    status: 4,
  },
  {
    id: 6,
    name: '取込済',
    action: '',
    status: 5,
  },
  {
    id: 7,
    name: '否認',
    action: '',
    status: 6,
  },
  {
    id: 8,
    name: '削除',
    action: '',
    status: 7,
  },
]
