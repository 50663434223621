import { render, staticRenderFns } from "./OwnerCell.vue?vue&type=template&id=47b92b0d&functional=true&"
var script = {}


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports