<template>
  <div>
    <v-toolbar style="border-bottom: 1px solid #ccc" height="56">
      <v-btn-toggle
        :value="inboxMenu === 'collect' ? 0 : 1"
        dense
        mandatory
        color="blue"
        class="mr-4"
      >
        <v-btn class="px-4" @click="onClickTableTab">
          <s-icon class="tw-mr-2" icon="feather-grid" size="xl" />
          回収状況
        </v-btn>
        <v-btn class="px-4" @click="onClickDetailTab">
          <s-icon class="tw-mr-2" icon="feather-sidebar" size="xl" />
          詳細表示
        </v-btn>
      </v-btn-toggle>

      <div v-if="inboxMenu == 'collect'">
        <v-btn-toggle dense active-class="active-btn-opacity" class="ml-2">
          <v-btn @click="$emit('to-prev-month')">
            <s-icon icon="feather-chevron-left" />
          </v-btn>
          <v-btn class="px-4">
            {{ displayPeriod }}
          </v-btn>
          <v-btn @click="$emit('to-next-month')">
            <s-icon icon="feather-chevron-right" />
          </v-btn>
        </v-btn-toggle>
      </div>

      <div v-else style="display: flex">
        <v-btn-toggle
          :value="isSelectedReceiptDate ? 0 : 1"
          dense
          mandatory
          color="blue"
          class="mr-6"
        >
          <v-btn
            v-tooltip="'受領した月でフィルタ'"
            @click="isSelectedReceiptDate = true"
            v-text="'受領'"
          />
          <v-btn
            v-tooltip="'請求対象の月でフィルタ'"
            @click="isSelectedReceiptDate = false"
            v-text="'請求'"
          />
        </v-btn-toggle>
        <v-autocomplete
          v-model="dateFrom"
          :items="itemsYearMonthFrom"
          filled
          dense
          class="more-dense"
          style="margin-top: 5px; width: 160px"
          hide-details
          item-text="name"
          item-value="date"
        />
        <span class="mt-3 mx-2 fs-10">〜</span>
        <v-autocomplete
          v-model="dateTo"
          :items="itemsYearMonthTo"
          filled
          dense
          class="more-dense"
          style="margin-top: 5px; width: 160px"
          hide-details
          item-text="name"
          item-value="date"
        />
      </div>
    </v-toolbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      dateFrom: null,
      dateTo: null,
      isSelectedReceiptDate: true,
    }
  },
  computed: {
    ...mapGetters({
      inboxMenu: 'inboxMenu',
      tableParams: 'inboxCustomersTableParams',
      listParams: 'inboxItemsListParams',
    }),
    displayPeriod() {
      const startYear = this.tableParams.year
      const startMonth = this.tableParams.month
      let endYear = startYear
      let endMonth = startMonth + 3

      if (endMonth - 12 > 0) {
        endYear += 1
        endMonth += -12
      }
      return `${startYear}年${startMonth}月 - ${endYear}年${endMonth}月`
    },
    computedDate: function () {
      return function (date) {
        const startYear = date.getFullYear()
        const startMonth = date.getMonth()
        let itemsYearMonthFrom = []

        const lastDate = new Date(startYear, startMonth - 1, 1)
        itemsYearMonthFrom.push({
          date: lastDate,
          name:
            lastDate.getFullYear() +
            '年' +
            String(lastDate.getMonth() + 1) +
            '月',
        })

        itemsYearMonthFrom.push({
          date: date,
          name: date.getFullYear() + '年' + String(date.getMonth() + 1) + '月',
        })

        const nextDate = new Date(startYear, startMonth + 1, 1)
        itemsYearMonthFrom.push({
          date: nextDate,
          name:
            nextDate.getFullYear() +
            '年' +
            String(nextDate.getMonth() + 1) +
            '月',
        })

        return itemsYearMonthFrom
      }
    },
    itemsYearMonthFrom() {
      const dateFrom = this.listParams.dateFrom
      return this.computedDate(dateFrom)
    },
    itemsYearMonthTo() {
      const dateTo = this.listParams.dateTo
      return this.computedDate(dateTo)
    },
  },
  watch: {
    isSelectedReceiptDate: {
      handler() {
        if (this.inboxMenu == 'inbox') {
          this.$emit('change-date-type', {
            isSelectedReceiptDate: this.isSelectedReceiptDate,
          })
        }
      },
    },
    dateFrom: {
      handler() {
        if (this.inboxMenu == 'inbox') {
          this.$emit('change-date-from', { dateFrom: this.dateFrom })
        }
      },
    },
    dateTo: {
      handler() {
        if (this.inboxMenu == 'inbox') {
          this.$emit('change-date-to', { dateTo: this.dateTo })
        }
      },
    },
  },
  mounted() {
    this.setUpDate()
  },
  methods: {
    setUpDate() {
      const listParams = JSON.parse(
        localStorage.getItem('inboxItems.listParams')
      )
      if (!listParams) {
        this.isSelectedReceiptDate = true
      } else {
        this.isSelectedReceiptDate = listParams.isSelectedReceiptDate
      }
      if (!listParams || !listParams.dateFrom) {
        this.dateFrom = new Date()
      } else {
        this.dateFrom = new Date(listParams.dateFrom)
      }
      if (!listParams || !listParams.dateTo) {
        this.dateTo = new Date()
      } else {
        this.dateTo = new Date(listParams.dateTo)
      }
    },
    onClickTableTab() {
      this.$emit('select-menu', 'collect')
    },
    onClickDetailTab() {
      this.$emit('select-menu', 'inbox')
    },
  },
}
</script>
