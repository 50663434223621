function getMonthHeaders() {
  return [
    { text: '01月', display: true, value: '01月' },
    { text: '02月', display: true, value: '02月' },
    { text: '03月', display: true, value: '03月' },
    { text: '04月', display: true, value: '04月' },
    { text: '05月', display: true, value: '05月' },
    { text: '06月', display: true, value: '06月' },
    { text: '07月', display: true, value: '07月' },
    { text: '08月', display: true, value: '08月' },
    { text: '09月', display: true, value: '09月' },
    { text: '10月', display: true, value: '10月' },
    { text: '11月', display: true, value: '11月' },
    { text: '12月', display: true, value: '12月' },
  ]
}

export { getMonthHeaders }
