var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%"}},[_c('dialog-confirm-delete-memo',{ref:"dialogConfirmDeleteMemo",on:{"submit":function($event){return _vm.$emit('delete-memo', $event)}}}),_c('v-data-table',{staticClass:"px-4",staticStyle:{"width":"100%"},attrs:{"headers":_vm.headers,"items":_vm.filteredItems,"item-key":"customer.id","fixed-header":"","footer-props":_vm.footerProps,"items-per-page":_vm.itemsPerPage,"custom-sort":_vm.customTableSort,"height":"calc(100vh - 240px)"},on:{"pagination":function($event){return _vm.onPagination($event)},"update:items-per-page":_vm.onChangeItemsPerPage},scopedSlots:_vm._u([{key:"header.selected",fn:function(ref){return [_c('select-all-header-cell',{attrs:{"items":_vm.filteredItems,"all-selected":_vm.allSelected,"indeterminate":_vm.indeterminate},on:{"click:item":function($event){return _vm.onClickSelectAll($event.value, $event.message)}}})]}},{key:"item",fn:function(ref){
var item = ref.item;
var isSelected = ref.isSelected;
return [_c('tr',{class:isSelected ? 'v-data-table__selected' : ''},[_c('td',{staticStyle:{"width":"30px"}},[(!item.customer.is_uncategorized)?_c('v-simple-checkbox',{attrs:{"value":isSelected},on:{"input":function($event){return _vm.toggleOne(item)}}}):_vm._e()],1),_c('td',{directives:[{name:"show",rawName:"v-show",value:(!_vm.tableParams.isAll),expression:"!tableParams.isAll"},{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('message.edit_sort_num')),expression:"$t('message.edit_sort_num')"}],on:{"click":function($event){return _vm.$emit('edit-sort-num', item.customer)}}},[(!item.customer.is_uncategorized)?_c('span',[_vm._v("\n            "+_vm._s(item.customer.sort_num)+"\n          ")]):_vm._e()]),_c('td',{staticStyle:{"width":"20%"}},[_c('div',{staticClass:"has-link d-inline",on:{"click":function($event){return _vm.$emit('edit-customer', item.customer)}}},[_c('span',{domProps:{"textContent":_vm._s(item.customer.name)}}),(item.customer.department_name)?_c('span',{domProps:{"textContent":_vm._s(' / ' + item.customer.department_name)}}):_vm._e()]),_c('span',{on:{"click":function($event){_vm.$emit('copy-upload-email', {
                customer: item.customer,
                query: _vm.uploadEmailQuery(item.customer.id),
              })}}},[_c('s-icon',{staticClass:"tw-float-right tw-cursor-pointer",attrs:{"tooltip":("クリックで" + (_vm.$t(
                'message.inbox_upload_email'
              )) + "をコピー"),"icon":"feather-mail"}})],1),_c('input',{attrs:{"id":_vm.uploadEmailId(item.customer.id),"type":"hidden"},domProps:{"value":item.customer.upload_email}}),_c('span',{on:{"click":function($event){_vm.$emit('copy-upload-url', {
                customer: item.customer,
                query: _vm.uploadUrlQuery(item.customer.id),
              })}}},[_c('s-icon',{staticClass:"tw-float-right tw-mr-3 tw-cursor-pointer",attrs:{"tooltip":("クリックで" + (_vm.$t('message.inbox_upload_url')) + "をコピー"),"icon":"feather-link"}})],1),_c('input',{attrs:{"id":_vm.uploadUrlId(item.customer.id),"type":"hidden"},domProps:{"value":item.customer.upload_url}}),(item.own_represent_user_status == 2)?_c('s-icon',{staticClass:"tw-float-right tw-mr-3",attrs:{"tooltip":"自社担当者が削除されているため変更ください","color":"red-500","icon":"feather-alert-triangle"}}):_vm._e()],1),_c('owner-cell',{attrs:{"item":item}}),_vm._l((item.items),function(inboxItems,i){return [(_vm.notHasInboxItems(inboxItems))?_c('td',{key:i,style:(_vm.inboxCellStyle)},[_c('v-row',{style:(_vm.inboxSendEmailStyle)},[(item.requested[i])?_c('v-chip',{staticClass:"mr-1 px-1",attrs:{"x-small":"","label":"","outlined":"","color":'orange'},domProps:{"textContent":_vm._s('依頼済')}}):_vm._e()],1),_c('v-row',{style:(_vm.inboxMenuStyle)},[_c('inbox-action-menu',{attrs:{"submit-statuses":_vm.submitStatuses,"can-nocharge":true,"delete-message":'',"is-default-no-charge":_vm.defaultSubmitStatus(item.customer, i) ==
                  _vm.submitStatuses.NOT_NEED_TO_COLLECT.status},on:{"create-inbox":function($event){_vm.$emit('create-inbox-item', Object.assign({}, {customerId: item.customer.id,
                    status: _vm.submitStatuses.UNCOLLECTED.status},
                    _vm.getYearMonth(i)))},"upload-by-self":function($event){_vm.$emit('upload-by-self', Object.assign({}, {customer: item.customer},
                    _vm.getYearMonth(i)))},"nocharge":function($event){_vm.$emit('nocharge', Object.assign({}, {customer: item.customer},
                    _vm.getYearMonth(i)))}}})],1),(!item.customer.is_uncategorized && !item.requested[i])?_c('v-row',[_c('inbox-status-chip',{attrs:{"submit-statuses":_vm.submitStatuses,"status":_vm.defaultSubmitStatus(item.customer, i)}}),_c('inbox-memo',{on:{"open":function($event){return _vm.setMemoForm(item.customer, i)},"click-update":function($event){return _vm.$emit('create-memo', _vm.memoForm)}},model:{value:(_vm.memoForm.memo),callback:function ($$v) {_vm.$set(_vm.memoForm, "memo", $$v)},expression:"memoForm.memo"}})],1):_vm._e()],1):_c('td',{key:i,style:(_vm.inboxCellStyle)},[_c('v-row',{style:(_vm.inboxSendEmailStyle)},[(item.requested[i])?_c('v-chip',{staticClass:"mr-1 px-1",attrs:{"x-small":"","label":"","outlined":"","color":'orange'},domProps:{"textContent":_vm._s('依頼済')}}):_vm._e()],1),_c('v-row',{style:(_vm.inboxMenuStyle)},[_c('inbox-action-menu',{attrs:{"submit-statuses":_vm.submitStatuses,"can-nocharge":_vm.canNocharge(inboxItems),"delete-message":_vm.deleteMessage(_vm.getDeletableInboxItem(inboxItems)),"is-default-no-charge":false},on:{"delete-inbox":function($event){_vm.$emit('delete-inbox', _vm.getDeletableInboxItem(inboxItems))},"upload-by-self":function($event){_vm.$emit(
                    'upload-by-self',
                    _vm.getUploadBySelfParams(item.customer, inboxItems, i)
                  )},"nocharge":function($event){_vm.$emit(
                    'nocharge',
                    _vm.getNochargeParams(item.customer, inboxItems, i)
                  )}}})],1),_vm._l((inboxItems),function(inbox,index){return _c('div',{key:inbox.id},[(
                  !item.requested[i] ||
                  inbox.submit_status != _vm.submitStatuses.UNCOLLECTED.status
                )?_c('v-row',{attrs:{"align":"center"}},[_c('inbox-status-chip',{attrs:{"submit-statuses":_vm.submitStatuses,"status":inbox.submit_status}}),_c('inbox-memo',{on:{"click-delete":function($event){return _vm.$refs.dialogConfirmDeleteMemo.open(inbox)},"click-update":function($event){return _vm.$emit('update-memo', {
                      inboxItem: inbox,
                      inboxId: inbox.id,
                      memo: inbox.memo,
                    })}},model:{value:(inbox.memo),callback:function ($$v) {_vm.$set(inbox, "memo", $$v)},expression:"inbox.memo"}}),_c('span',{on:{"click":function($event){return _vm.$emit('draw-collected-invoice', inbox.id, inbox)}}},[(
                      inbox &&
                      _vm.statusesHasFile.includes(inbox.submit_status) &&
                      inbox.file_path
                    )?_c('s-icon',{staticClass:"tw-float-right tw-ml-1 tw-cursor-pointer",attrs:{"tooltip":"ファイルを確認","size":"lg","color":"blue-500","icon":"feather-file"}}):_vm._e()],1)],1):_vm._e()],1)})],2)]})],2)]}},{key:"no-results",fn:function(){return [_c('v-alert',{staticClass:"mt-3",attrs:{"value":true,"color":"yellow","icon":"warning"}},[_vm._v("\n        "+_vm._s(_vm.$t('message.noResult'))+"\n      ")])]},proxy:true}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }