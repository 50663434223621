export default {
  UNCOLLECTED: {
    status: 1,
    name: '未請求',
    color: '',
  },
  REQUESTED: {
    status: 2,
    name: '依頼済',
    color: 'yellow-500',
  },
  RECEIVED: {
    status: 3,
    name: '受領済',
    color: 'blue-500',
  },
  APPROVING: {
    status: 4,
    name: '承認中',
    color: 'green-500',
  },
  IMPORTED: {
    status: 5,
    name: '取込済',
    color: 'indigo-800',
  },
  DISAPPROVED: {
    status: 6,
    name: '否認',
    color: 'pink-600',
  },
  DELETED: {
    status: 7,
    name: '削除済',
    color: 'red-500',
  },
  NOT_NEED_TO_COLLECT: {
    status: 8,
    name: '回収不要',
    color: 'gray-400',
  },
}
