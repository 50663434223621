<template>
  <v-container fluid pa-0>
    <v-dialog
      v-model="drawerCollectedInvoice"
      width="1400"
      content-class="my-0"
    >
      <v-card>
        <v-row no-gutters>
          <v-col>
            <inbox-info
              ref="inboxInfo"
              :inbox-customers="customers"
              :submit-statuses="submitStatuses"
              :selected-inbox-item="selectedInboxItem"
              :update-form="uploadForm"
              :input-area-height="'height:calc(100vh - 213px);overflow:auto;'"
              :requested-approval-users="requestedApprovalUsers"
              :approval-type="approvalType"
              :max-approval-stage="maxApprovalStage"
              @update-inbox="$emit('update-inbox', $event)"
              @disapprove="$emit('disapprove', $event)"
              @delete-inbox="$emit('delete', $event)"
              @send-request="sendRequestApproval($event)"
            />
          </v-col>
          <v-col style="min-width: 700px">
            <inbox-image
              ref="image"
              :selected-inbox-item="selectedInboxItem"
              :submit-statuses="submitStatuses"
              :image-data="imageData"
              :requested-approval-users="requestedApprovalUsers"
              :approval-type="approvalType"
              :max-approval-stage="maxApprovalStage"
              @approve="$emit('approve', $event)"
              @cancel-approve="$emit('cancel-approve', $event)"
              @download-file="downloadFile"
            />
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <dialog-upload ref="dialogUpload" @added="uploadFile" />

    <dialog-customer-form
      ref="dialogCustomerForm"
      @write-to-clipboard="writeToClipboard"
      @create-customer="createCustomer"
      @update-customer="updateCustomer"
      @delete-customer="deleteCustomer"
    />

    <dialog-bulk-update-customer-form
      ref="dialogBulkUpdateCustomerForm"
      @update-customers="updateCustomers"
    />

    <input-delete-dialog
      ref="dialogDelete"
      title="取引先の削除"
      message="選択した取引先をすべて削除してよろしいですか?"
      :cancel-text="$t('message.modal_cancel_btn')"
      :delete-text="$t('message.delete')"
      @submit="
        deleteCustomers()
        $refs.dialogDelete.close()
      "
    />

    <dialog-send-email
      ref="dialogSendEmail"
      @send="onSendEmail"
      @send-test="sendEmailExam"
      @create-template="createEmailTemplate"
      @update-template="updateEmailTemplate"
      @delete-template="deleteEmailTemplate"
    />

    <dialog-confirm-send-email
      ref="dialogConfirmSendEmail"
      @submit="submitSendEmail"
      @cancel="cancelSendEmail"
    />

    <dialog-error-uncollect ref="dialogErrorUncollect" />

    <dialog-confirm-duplicated-customers
      ref="dialogConfirmDuplicatedCustomers"
      @submit="checkImportData(false)"
    />

    <dialog-import-csv-error ref="dialogImportCsvError" />

    <dialog-edit-sort-num
      ref="dialogEditSortNum"
      v-model="editSortNumForm.sortNum"
      @submit="saveSortNum()"
    />

    <base-dialog
      ref="dialogConfirmImport"
      :title="$t('message.confirm')"
      :message="'ファイルをインポートしますか？'"
      :cancel-text="$t('message.modal_cancel_btn')"
      :submit-text="'インポート'"
      @submit="checkImportData()"
    />

    <base-dialog
      ref="dialogUncollect"
      :title="$t('message.delete')"
      :message="$t('message.uncollect_message')"
      :cancel-text="$t('message.modal_cancel_btn')"
      :submit-text="$t('message.delete')"
      :close-on-submit="true"
      @submit="uncollect"
    />
    <base-dialog
      ref="dialogConfirmSendBulkEmails"
      :title="$t('message.confirm')"
      :message="$t('message.include_deleted_user_message')"
      :cancel-text="$t('message.modal_cancel_btn')"
      :submit-text="$t('message.ok')"
      :close-on-submit="true"
      @submit="openDialogBulkSendEmail(true)"
    />

    <v-row no-gutters>
      <input v-show="false" id="import_customers_csv" type="file" />
      <customer-table-toolbar
        ref="toolbar"
        @add-customer="onClickCreateCustomer"
        @import-customers="onClickImportCustomers"
        @get-import-sample="exportCustomersCsv"
        @bulk-edit="openDialogBulkUpdateCustomer()"
        @bulk-delete="openDialogDeleteCustomer"
        @send-emails="openDialogBulkSendEmail(false)"
        @change-filter="onChangeFilter"
      />

      <customer-table
        ref="table"
        :submit-statuses="submitStatuses"
        :table-items="tableItems"
        :table-params="tableParams"
        @create-memo="createMemo"
        @update-memo="updateMemo"
        @delete-memo="deleteMemo"
        @edit-sort-num="openDialogEditSortNum"
        @edit-customer="createDialogCustomerEdit"
        @copy-upload-email="checkCopyBeforeWriteToClipboard"
        @copy-upload-url="checkCopyBeforeWriteToClipboard"
        @draw-collected-invoice="drawCollectedInvoice"
        @bill-request="openDialogSendEmail"
        @create-inbox-item="createInboxItem"
        @upload-by-self="createDialogUpload"
        @nocharge="onNoCharge"
        @delete-inbox="deleteInbox"
      />
    </v-row>
    <v-snackbar
      v-model="snackbar"
      :timeout="2000"
      color="success"
      class="mt-4"
      top
    >
      <p class="ma-0"><i class="ti-thumb-up mr-2" />{{ copiedMessage }}</p>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import Vue from 'vue'
import Encoding from 'encoding-japanese'

// helpers
import Snackbar from '@/helpers/snackbar/index'
import { errorNotify } from '@/helpers/helpers'
import { formatDate } from '@/pages/inbox/@modules/helpers/date'
import { removeQuoteBlankAndNewLineAllRows } from '@/pages/inbox/@modules/helpers/csv'
import CSVReader from '@/helpers/csv'
import { convertBase64toBlob } from '@/pages/inbox/@modules/helpers/file'
import { convertStringToBoolean } from '@/pages/inbox/@modules/helpers/types'

import InputDeleteDialog from '@/components/Dialog/InputDeleteDialog.vue'
import inboxStatus from '@/pages/inbox/@modules/data/status.js'
import submitStatuses from '@/pages/inbox/@modules/data/submitStatuses.js'

import InboxInfo from '@/pages/inbox/@components/@shared/InboxInfo/InboxInfo.vue'
import InboxImage from '@/pages/inbox/@components/@shared/InboxImage/InboxImage.vue'

import CustomerTableToolbar from './CustomerTableToolbar/CustomerTableToolbar'
import CustomerTable from './CustomerTable/CustomerTable'
import DialogBulkUpdateCustomerForm from './DialogBulkUpdateCustomerForm/DialogBulkUpdateCustomerForm'
import DialogCustomerForm from './DialogCustomerForm/DialogCustomerForm'
import DialogUpload from './DialogUpload/DialogUpload'
import DialogSendEmail from './DialogSendEmail/DialogSendEmail'
import DialogImportCsvError from './DialogImportCsvError/DialogImportCsvError'
import DialogEditSortNum from './DialogEditSortNum/DialogEditSortNum'
import DialogErrorUncollect from './DialogErrorUncollect/DialogErrorUncollect'
import DialogConfirmSendEmail from './DialogConfirmSendEmail/DialogConfirmSendEmail'
import DialogConfirmDuplicatedCustomers from './DialogConfirmDuplicatedCustomers/DialogConfirmDuplicatedCustomers'

export default {
  components: {
    InputDeleteDialog,
    CustomerTableToolbar,
    CustomerTable,
    InboxInfo,
    InboxImage,
    DialogBulkUpdateCustomerForm,
    DialogCustomerForm,
    DialogSendEmail,
    DialogUpload,
    DialogImportCsvError,
    DialogEditSortNum,
    DialogErrorUncollect,
    DialogConfirmSendEmail,
    DialogConfirmDuplicatedCustomers,
  },
  data() {
    return {
      snackbar: false,
      drawerCollectedInvoice: false,
      loadingUploadImage: false,
      copiedMessage: '',
      selectedInboxItem: {},
      isFromCollectTable: false,
      uploadForm: {
        inboxId: '',
        file: null,
        image: '',
        issueDate: '',
        customerId: '',
        purpose: '',
        checkComment: '',
        transactionYear: 0,
        transactionMonth: 0,
      },
      editSortNumForm: {
        customerId: '',
        sortNum: '',
      },
      csvData: [],
      csvHeaders: [
        { col: 0, removeQuoteBlankAndNewLine: true },
        { col: 1, removeQuoteBlankAndNewLine: true },
        { col: 2, removeQuoteBlankAndNewLine: true },
        { col: 3, removeQuoteBlankAndNewLine: true },
        { col: 4, removeQuoteBlankAndNewLine: true },
      ],
      requestedApprovalUsers: {},
      approvalType: 1,
      maxApprovalStage: 8,
    }
  },
  computed: {
    ...mapGetters({
      userOrganizations: 'inboxUserOrganizations',
      customers: 'inboxCustomers',
      tableItems: 'inboxCustomersTableData',
      tableParams: 'inboxCustomersTableParams',
      isUnnecessaryImpress: 'inboxCustomersIsUnnecessaryImpress',
      imageData: 'imageData',
    }),
    displayYear() {
      return this.tableParams.year
    },
    displayMonth() {
      return this.tableParams.month
    },
    submitStatuses() {
      return submitStatuses
    },
  },
  watch: {},
  mounted() {
    this.$store.dispatch('initInboxCustomersStates')
    this.$emit('get-table-data')
  },
  methods: {
    startApproveLoading() {
      this.$refs.image.startApproveLoading()
    },
    stopApproveLoading() {
      this.$refs.image.stopApproveLoading()
    },
    onChangeFilter({ status, text, showUncategorized }) {
      this.$store.dispatch('updateInboxCustomersTableParams', {
        status,
        text,
        showUncategorized,
      })
    },
    setOwnDepartmentFilter(ownDepartmentId) {
      this.$store.dispatch('updateInboxCustomersTableParams', {
        ownDepartmentId,
      })
    },
    toNextMonth() {
      this.$store.dispatch('toNextMonth')
    },
    toPrevMonth() {
      this.$store.dispatch('toPrevMonth')
    },
    onClickImportCustomers() {
      CSVReader.read('import_customers_csv').then((csvData) => {
        const result = csvData.filter((row) => row.length > 1)
        removeQuoteBlankAndNewLineAllRows(result, this.csvHeaders)
        this.csvData = result
        const duplicatedData = this.checkDuplicatedDataInCsv()
        if (duplicatedData.length > 0) {
          this.$refs.dialogConfirmDuplicatedCustomers.open(duplicatedData)
        } else {
          this.$refs.dialogConfirmImport.open()
        }
      })
    },
    checkCopyBeforeWriteToClipboard({ customer, query }) {
      if (!customer.upload_url || !customer.upload_email) {
        this.createUploadUrlAndUploadEmail(customer).then((response) => {
          if (response.data.error) {
            Snackbar.error(response.data.error)
          } else {
            customer.upload_url = response.data.customer.upload_url
            customer.upload_email = response.data.customer.upload_email
            let element = document.querySelector(query)
            if (query.slice(8, 11) == 'url') {
              element.value = customer.upload_url
            } else if (query.slice(8, 13) == 'email') {
              element.value = customer.upload_email
            }
            this.writeToClipboard(query)
          }
        })
      } else {
        this.writeToClipboard(query)
      }
    },
    writeToClipboard(queryId) {
      let element = document.querySelector(queryId)
      element.setAttribute('type', 'text')
      element.select()
      try {
        var successful = document.execCommand('copy')
        this.copiedMessage = successful
          ? 'アドレスをコピーしました'
          : 'アドレスをコピーしてください'
        this.snackbar = true
      } catch (err) {
        this.copiedMessage = 'アドレスをコピーしてください'
      }
      element.setAttribute('type', 'hidden')
    },
    drawCollectedInvoice(inboxId, inbox) {
      try {
        if (inbox) {
          this.$store.dispatch('setSelectedInboxItemState', inbox)
        }
        this.$store.dispatch('getInboxItem', inboxId).then((response) => {
          if (!inbox) {
            this.$store.dispatch('setSelectedInboxItemState', response.inbox_item)
          }
          this.requestedApprovalUsers = response.requested_approval
          this.approvalType = response.approval_type
          this.maxApprovalStage = response.max_approval_stage
          const inboxItem = response.inbox_item
          this.selectedInboxItem = inboxItem
          this.uploadForm.customerId = inboxItem.customer.id
          this.uploadForm.issueDate = formatDate(
            new Date(inboxItem.transaction_date)
          )
          this.uploadForm.purpose = inboxItem.purpose
          this.uploadForm.checkComment = inboxItem.submit_comment
          this.uploadForm.transactionYear = inboxItem.transaction_year
          this.uploadForm.transactionMonth = inboxItem.transaction_month
          this.isFromCollectTable = true
          if (this.$refs.image) {
            this.$refs.image.startLoading()
          }
          this.$store.dispatch('getInboxImage', inboxId).then(() => {
            if (this.$refs.image) {
              this.$refs.image.stopLoading()
            }
          })
        })
        this.drawerCollectedInvoice = true
      } catch (e) {
        console.log(e)
      }
    },
    sendRequestApproval(params) {
      this.requestedApprovalUsers = params.nextApproveRequestUsers
      this.approvalType = params.approvalType
      this.maxApprovalStage = params.maxApprovalStage
      this.$emit('send-request', params)
    },
    onDeleteInbox(inboxItem) {
      this.$store.dispatch('setSelectedInboxItemState', inboxItem)
      this.$emit('delete', inboxItem)
    },
    updateSelectedInboxItem({ status, comment }) {
      if (status) {
        if (status == this.submitStatuses.DELETED.status) {
          this.selectedInboxItem.submit_status =
            this.submitStatuses.UNCOLLECTED.status
          this.$store.dispatch(
            'updateSubmitStatusOfSelectedInboxItem',
            this.submitStatuses.UNCOLLECTED.status
          )
        } else {
          this.selectedInboxItem.submit_status = status
          this.$store.dispatch('updateSubmitStatusOfSelectedInboxItem', status)
        }
      }
      if (comment) {
        this.selectedInboxItem.submit_comment = comment
      }
    },
    updateInboxApproval({
      approval_stage,
      approved_user_id,
      approved_user_name,
      approved_date,
    }) {
      const userIdField = 'approved_user' + approval_stage
      let approvedUser = {
        id: approved_user_id,
        date: approved_date,
        name: approved_user_name,
      }

      this.selectedInboxItem.approval[userIdField] = approvedUser
      if (
        this.selectedInboxItem.submit_status <
        this.submitStatuses.APPROVING.status
      ) {
        this.selectedInboxItem.submit_status =
          this.submitStatuses.APPROVING.status
        this.$store.dispatch(
          'updateSubmitStatusOfSelectedInboxItem',
          this.submitStatuses.APPROVING.status
        )
      }
    },
    canselInboxApproval(approval_stage) {
      const userIdField = 'approved_user' + approval_stage
      this.selectedInboxItem.approval[userIdField] = null
    },
    createDialogUpload({ id, submit_comment, customer, year, month }) {
      let form = {
        inboxId: id ? id : null,
        customerId: customer.id,
        date: formatDate(new Date(year, month, 0)),
        comment: submit_comment,
      }
      this.$refs.dialogUpload.open(form)

      Vue.nextTick(() => {
        this.$refs.dropzone.removeAllFiles()
      })
    },
    uploadFile({ file, inboxId, customerId, date, comment }) {
      try {
        var t = this
        t.loadingUploadImage = true
        var reader = new FileReader()
        var status = false

        const senderUserName = this.userOrganizations.filter(
          (data) => data.id == this.$store.getters.getUserOrganizationID
        )[0].name

        reader.onload = function (e) {
          status = true

          $.ajax({
            url: t.$store.getters.apiInboxUploadFunction,
            type: 'POST',
            dataType: 'json',
            data: {
              inbox_id: inboxId,
              title: 'default',
              image: e.target.result,
              upload_source: file.name,
              date: date,
              pay_date: null,
              pay_day: null,
              pay_month: null,
              user_organization_id: t.$store.getters.getUserOrganizationID,
              organization_id: t.$store.getters.getOrganizationID,
              debit: null,
              credit: null,
              multiple: 'true',
              attachment_page: 4,
              customer_id: customerId,
              comment_from_inbox: comment,
              sender_user_name: senderUserName,
            },
            beforeSend: function (xhr) {
              /* Authorization header */
              xhr.setRequestHeader(
                'Authorization',
                t.$store.getters.getAuthToken
              )
            },
            error: function (response) {
              errorNotify('Error on uploading ' + file.name)
              t.loadingUploadImage = false
            },
            success: function (response) {
              if (response.error) {
                errorNotify(response.error)
                t.loadingUploadImage = false
              } else {
                t.$emit('get-inbox-items')
                t.$emit('get-table-data')
                Snackbar.success(
                  'アップロード完了。<br>受信トレイに保存されました。'
                )
                t.$refs.dialogUpload.close()
                t.loadingUploadImage = false
              }
            },
          })
        }
        reader.onloadend = function (e) {
          if (!status) {
            setTimeout(function () {
              // t.totalItems += -1
              errorNotify('Cannot upload folder')
            }, 500)
          }
        }
        reader.readAsDataURL(file)
      } catch (error) {
        console.log(error)
      }
    },
    checkDuplicatedDataInCsv() {
      let duplicatedData = []

      this.csvData.forEach((row, i) => {
        if (i === 0) {
          return
        }
        let csvCustomerName = row[0]
        let csvCustomerDepertment = row[1]
        let duplicatedCstomers = this.customers.filter(
          (item) =>
            item.name == csvCustomerName &&
            item.department_name == csvCustomerDepertment
        )
        if (duplicatedCstomers.length > 0) {
          duplicatedData.push(i)
        }
      })
      return duplicatedData
    },
    checkImportData(is_update = false) {
      try {
        this.$refs.dialogConfirmImport.close()

        const [hasError, errors] = this.checkValidData()
        if (hasError) {
          this.$refs.dialogImportCsvError.open(errors)
          return
        }
        const url = this.$store.getters.apiCustomersCsvFunction
        const headers = { Authorization: this.$store.getters.getAuthToken }
        const data = this.csvData
        Snackbar.warning('インポートには少々お時間をいただく場合がございます')

        axios.post(url, { data, is_update }, { headers }).then((response) => {
          if (response.data.is_invalid) {
            this.$refs.dialogImportCsvError.open({
              invalidEmailError: response.data.invalid_emails,
              userOrganizationError:
                response.data.invalid_user_organization_ids,
            })
          } else if (response.data.status == 'success') {
            this.$emit('get-customers')
            this.$emit('get-table-data')
            // console.log('インポートが完了しました')
            Snackbar.success('インポートが完了しました')
          }
        })
      } catch (error) {
        console.log(error)
      }
    },
    checkValidData() {
      let hasError = false
      let userOrganizationError = []
      let customerNameError = []
      let invalidEmailError = []
      this.csvData.forEach((row, i) => {
        if (i === 0) {
          return
        }
        // check valid email
        const email = row[3]
        if (!this.isValidEmail(email)) {
          hasError = true
          invalidEmailError.push(i)
        }
        // check valid customer name
        const customerName = row[0]
        if (!customerName) {
          hasError = true
          customerNameError.push(i)
        }
        // check valid user_organization
        const userName = row[4]
        const user = this.userOrganizations.find(
          (user) => user.name == userName
        )
        if (!user) {
          hasError = true
          userOrganizationError.push(i)
        } else {
          // use id
          this.csvData[i][4] = user.id
        }
      })
      const errors = {
        userOrganizationError,
        customerNameError,
        invalidEmailError,
      }

      return [hasError, errors]
    },
    isValidEmail(email) {
      if (!email) {
        return true
      }
      return /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
        email
      )
    },
    exportCustomersCsv() {
      try {
        let unicodeList = this.createCustomersCsvData()

        const shiftJisCodeList = Encoding.convert(
          unicodeList,
          'sjis',
          'unicode'
        )
        const uInt8List = new Uint8Array(shiftJisCodeList)
        let blob = new Blob([uInt8List], { type: 'text/csv' })
        let a = document.createElement('a')
        a.href = window.URL.createObjectURL(blob)
        a.download = 'export_customers.csv'
        a.click()
      } catch (e) {
        console.log(e)
      }
    },
    createCustomersCsvData() {
      const csvFormat = (col) => {
        return `"${col}"`
      }

      let headers = [
        '会社名',
        '担当部署',
        '担当者名',
        'メールアドレス',
        '自社担当者',
      ]
      let csvDataSet = [headers.map(csvFormat).join(',')]

      for (let data of this.$refs.table.filteredItems) {
        if (data.customer.is_uncategorized) {
          continue
        }
        const userName = this.getUserNameFromId(
          data.customer.own_represent_user_org
        )
        if (!userName) {
          continue
        }
        let row = [
          data.customer.name,
          data.customer.department_name,
          data.customer.represent_name,
          data.customer.email,
          userName,
        ]
        csvDataSet.push(row.map(csvFormat).join(','))
      }
      const csvData = csvDataSet.join('\n')

      const unicodeList = []
      for (let i = 0; i < csvData.length; i += 1) {
        unicodeList.push(csvData.charCodeAt(i))
      }
      return unicodeList
    },
    getUserNameFromId(userOrgId) {
      const userOrg = this.userOrganizations.find(
        (data) => data.id == userOrgId
      )
      if (userOrg.status == 1) {
        return userOrg.name
      } else if (userOrg.status == 2) {
        return userOrg.name + '(削除済み)'
      }
      return null
    },
    openDialogUncollect(inbox) {
      if (inbox.collected_way && [1, 3].indexOf(inbox.collected_way) >= 0) {
        this.$refs.dialogErrorUncollect.open()
        return
      }
      if (!inbox.file_path) {
        this.uncollect(inbox)
        return
      }
      this.$refs.dialogUncollect.open(inbox)
    },
    onClickCreateCustomer() {
      const customerForm = {
        status: 1,
        name: '',
        departmentName: '',
        representName: '',
        email: '',
        ownRepresentUserOrg: String(this.$store.getters.getUserOrganizationID),
        ownDepartmentId: this.tableParams.ownDepartmentId,
        useIssueDate: true,
      }
      this.$refs.dialogCustomerForm.open(customerForm)
    },
    openDialogEditSortNum(customer) {
      this.editSortNumForm.customerId = customer.id
      this.editSortNumForm.sortNum = String(customer.sort_num)
      this.$refs.dialogEditSortNum.open()
    },
    saveSortNum() {
      this.editSortNumForm.sortNum = Number(this.editSortNumForm.sortNum)
      this.$emit('update-sort-num', this.editSortNumForm)
    },
    closeDialogEditSortNum() {
      this.$refs.dialogEditSortNum.close()
    },
    createDialogCustomerEdit(customer) {
      const form = {
        id: customer.id,
        name: customer.name,
        departmentName: customer.department_name,
        representName: customer.represent_name,
        email: customer.email,
        ownRepresentUserOrg: String(customer.own_represent_user_org),
        ownDepartmentId: customer.own_department,
        uploadUrl: customer.upload_url,
        uploadEmail: customer.upload_email,
        status: customer.status,
        hiddenCustomerId: customer.id,
        isUncategorized: customer.is_uncategorized,
        approvalStage: customer.approval_stage,
        approvalType: customer.approval_type,
        approvalUsers: {},
        startAt: customer.start_at,
        endAt: customer.end_at,
        isSendScheduleEmail: customer.is_send_schedule_email,
        beginningDay: customer.beginning_day,
        incrementDay: customer.increment_day,
        emailTemplateId: customer.email_template_id,
        useIssueDate: customer.use_issue_date,
      }
      if (customer.tran_months && customer.tran_months.length > 0) {
        form.tranMonths = customer.tran_months
      }

      this.$store
        .dispatch('getCustomerApprovalStage', { customerId: customer.id })
        .then((response) => {
          form.approvalUsers = response.default_approval_users

          if (!form.uploadUrl || !form.uploadEmail) {
            this.createUploadUrlAndUploadEmail(customer).then((response) => {
              if (response.data.error) {
                Snackbar.error(response.data.error)
              } else {
                form.uploadUrl = response.data.customer.upload_url
                form.uploadEmail = response.data.customer.upload_email
                customer.upload_url = response.data.customer.upload_url
                customer.upload_email = response.data.customer.upload_email
                this.$refs.dialogCustomerForm.open(form, true)
              }
            })
          } else {
            this.$refs.dialogCustomerForm.open(form, true)
          }
        })
        .catch((err) => {
          Snackbar.error(err.message)
        })
    },
    createCustomer(form) {
      try {
        this.$refs.dialogCustomerForm.startLoading()
        this.$store
          .dispatch('createCustomer', form)
          .then(() => {
            Snackbar.success('取引先が追加されました')

            this.$emit('get-customers')
            this.$emit('get-table-data')
            this.$refs.dialogCustomerForm.close()
            this.$refs.dialogCustomerForm.stopLoading()
          })
          .catch((err) => {
            Snackbar.error(err.message)
          })
      } catch (err) {
        console.log(err)
      }
    },
    updateCustomer(form) {
      try {
        this.$refs.dialogCustomerForm.startLoading()
        this.$store
          .dispatch('updateCustomer', form)
          .then(() => {
            Snackbar.success('データが修正されました')
            this.$emit('get-customers')
            this.$emit('get-table-data')
            this.$refs.dialogCustomerForm.close()
          })
          .catch((err) => {
            this.$refs.dialogCustomerForm.stopLoading()
            Snackbar.error(err.message)
          })
      } catch (err) {
        console.log(err)
      }
    },
    updateCustomers({ customerIds, form }) {
      try {
        const params = {
          customerIds: customerIds,
          status: form.status,
          ownRepresentUserOrg: form.ownRepresentUserOrg,
          ownDepartmentId: form.ownDepartmentId,
          approvalStage: form.approvalStage,
          approvalType: form.approvalType,
          isUpdateDefaultApproveUsers: form.isUpdateDefaultApproveUsers,
          selectedUsers: form.selectedUsers,
          isUpdateTranMonths: form.isUpdateTranMonths,
          startAt: form.startAt,
          endAt: form.endAt,
          tranMonths: form.tranMonths,
          isUpdateScheduleEmail: form.isUpdateScheduleEmail,
          isSendScheduleEmail: form.isSendScheduleEmail,
          beginningDay: form.beginningDay,
          incrementDay: form.incrementDay,
          emailTemplateId: form.emailTemplateId,
          useIssueDate: form.useIssueDate,
        }
        this.$refs.dialogBulkUpdateCustomerForm.startLoading()
        this.$store
          .dispatch('updateCustomers', params)
          .then(() => {
            Snackbar.success('データが修正されました')
            this.$emit('get-customers')
            this.$emit('get-table-data')
            this.$refs.dialogBulkUpdateCustomerForm.close()
          })
          .catch((err) => {
            this.$refs.dialogBulkUpdateCustomerForm.stopLoading()
            Snackbar.error(err.message)
          })
      } catch (err) {
        console.log(err)
      }
    },
    deleteCustomer(customerId) {
      try {
        this.$refs.dialogCustomerForm.startLoading()
        this.$store
          .dispatch('deleteCustomer', customerId)
          .then(() => {
            Snackbar.success('取引先が削除されました')
            this.$emit('get-customers')
            this.$emit('get-table-data')
            this.$refs.dialogCustomerForm.close()
          })
          .catch((err) => {
            this.$refs.dialogCustomerForm.stopLoading()
            Snackbar.error(err.message)
          })
      } catch (err) {
        console.log(err)
      }
    },
    deleteCustomers() {
      try {
        const selected = this.$refs.table.selected
        const customerIds = selected.map((obj) => obj.customer.id)
        const params = {
          customerIds: customerIds,
        }
        this.$store
          .dispatch('deleteCustomers', params)
          .then(() => {
            Snackbar.success('取引先が削除されました')
            this.$emit('get-customers')
            this.$emit('get-table-data')
          })
          .catch((err) => {
            Snackbar.error(err.message)
          })
      } catch (err) {
        console.log(err)
      }
    },
    createEmailTemplate(form) {
      this.$store
        .dispatch('createInboxEmailTemplate', form)
        .then((newTemplate) => {
          Snackbar.success('テンプレートを作成しました')
          this.$refs.dialogSendEmail.dialogTemplateName.close()
          this.$refs.dialogSendEmail.setTemplate(newTemplate.id)
        })
        .catch((err) => {
          Snackbar.err(err.message)
          this.$refs.dialogSendEmail.dialogTemplateName.stopLoading()
        })
    },
    updateEmailTemplate(form) {
      this.$store
        .dispatch('updateInboxEmailTemplate', form)
        .then((msg) => {
          Snackbar.success('テンプレートを更新しました')
          this.$refs.dialogSendEmail.dialogTemplateName.close()
        })
        .catch((err) => {
          Snackbar.err(err.message)
        })
    },
    deleteEmailTemplate(form) {
      this.$store
        .dispatch('deleteInboxEmailTemplate', form)
        .then((msg) => {
          Snackbar.success('テンプレートを削除しました')
          this.$refs.dialogSendEmail.dialogDeleteTemplateConfirm.close()
          this.$refs.dialogSendEmail.setTemplate(1)
          this.$refs.dialogSendEmail.stopLoading()
        })
        .catch((err) => {
          Snackbar.err(err.message)
        })
    },
    createUploadUrlAndUploadEmail(customer) {
      let url = this.$store.getters.apiCustomersFunction + '/' + customer.id
      let params = {
        is_update_upload_url: false,
        is_update_upload_email: false,
      }
      if (!customer.upload_url) {
        params.is_update_upload_url = true
      }
      if (!customer.upload_email) {
        params.is_update_upload_email = true
      }

      return new Promise((resolve) => {
        axios
          .patch(url, params, {
            headers: { Authorization: this.$store.getters.getAuthToken },
          })
          .then((response) => {
            resolve(response)
          })
      })
    },
    createMemo({ memo, customerId, year, month }) {
      this.createInboxItem({
        status: 1,
        customerId: customerId,
        year,
        month,
        memo,
      })
    },
    deleteMemo(inboxItem) {
      try {
        this.$store.dispatch('setSelectedInboxItemState', inboxItem)
        let params = {
          inboxId: inboxItem.id,
          memo: '',
        }
        this.$store.dispatch('updateMemo', params).then((response) => {
          if (response.data.error) {
            Snackbar.error(response.data.error)
          } else {
            this.$store.dispatch('updateMemoOfSelectedInboxItem', '')
            Snackbar.success('メモを削除しました')
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    updateMemo({ inboxItem, memo, inboxId }) {
      try {
        this.$store.dispatch('setSelectedInboxItemState', inboxItem)

        this.$store
          .dispatch('updateMemo', { inboxId, memo })
          .then((response) => {
            if (response.data.error) {
              Snackbar.error(response.data.error)
            } else {
              this.$store.dispatch('updateMemoOfSelectedInboxItem', memo)
              Snackbar.success('メモを更新しました')
            }
          })
      } catch (e) {
        console.log(e)
      }
    },
    onNoCharge(params) {
      if (params.inboxItem) {
        this.$store.dispatch('setSelectedInboxItemState', params.inboxItem)
      }
      this.changeInboxStatus({
        ...params,
        status: inboxStatus.NOT_NEED_TO_COLLECT,
      })
    },
    uncollect(inbox) {
      this.changeInboxStatus({
        id: inbox.id,
        customer: inbox.customer,
        status: inboxStatus.DELETED,
      })
    },
    deleteInbox(inbox) {
      this.$store.dispatch('setSelectedInboxItemState', inbox)
      this.changeInboxStatus({
        id: inbox.id,
        status: inboxStatus.DELETED,
      })
    },
    changeInboxStatus({ id, customer, year, month, status }) {
      if (!id) {
        let params = {
          customerId: customer.id,
          year: year,
          month: month,
          status: status,
        }
        this.createInboxItem(params)
      } else {
        let params = {
          inboxId: id,
          status: status,
        }
        if (status == inboxStatus.UNCOLLECTED) {
          params.comment = ''
        }
        this.$emit('update-submit-status', params)
      }
    },
    createInboxItem(params) {
      try {
        this.$store
          .dispatch('createInboxItem', params)
          .then(() => {
            Snackbar.success('成功')
            this.$store.dispatch('getInboxCustomersTableData')
            this.$refs.table.clearMemoForm()
          })
          .catch((err) => {
            Snackbar.error(err.message)
          })
      } catch (e) {
        console.log(e)
      }
    },
    openDialogBulkUpdateCustomer() {
      const selected = this.$refs.table.selected
      if (!selected.length) {
        Snackbar.error('データが選択されていません')
        return
      }
      const customers = selected.map((obj) => obj.customer)
      this.$refs.dialogBulkUpdateCustomerForm.open({ customers })
    },
    openDialogDeleteCustomer() {
      const selected = this.$refs.table.selected
      if (!selected.length) {
        Snackbar.error('データが選択されていません')
        return
      }
      if (!this.checkPrivilege('inbox:delete_customer')) {
        Snackbar.error('権限がありません')
        return
      }
      this.$refs.dialogDelete.open()
    },
    openDialogSendEmail({ id, customer, year, month }) {
      let form = {}
      if (id) {
        form.inboxId = id
      } else {
        form.inboxId = null
      }
      form.to = customer.email
      form.cc = customer.own_represent_user_org_email
      form.from = customer.own_represent_user_org_email
      form.hidden_user_org_id = customer.own_represent_user_org
      form.title = '請求書アップロードのご依頼'
      form.hidden_year = year
      form.hidden_month = month
      form.is_unnecessary_impress = this.isUnnecessaryImpress
      this.$refs.dialogSendEmail.open({ form, customer })
    },
    openDialogBulkSendEmail(isForced = false) {
      const selected = this.$refs.table.selected
      if (!selected.length) {
        Snackbar.error('データが選択されていません')
        return
      }
      if (selected.find((v) => !v.customer.email)) {
        Snackbar.error('メールアドレスが未入力の取引先が選択されています。')
        return
      }
      if (!isForced && this.isIncludedAlreadyDeletedUser()) {
        this.$refs.dialogConfirmSendBulkEmails.open()
        return
      }
      const form = {}
      let now = new Date()
      let customers = []
      for (let data of selected) {
        customers.push(data.customer)
      }
      form.hidden_year = now.getFullYear()
      form.hidden_month = now.getMonth() + 1
      form.title = '請求書アップロードのご依頼'
      form.to = ''
      form.from = customers[0].own_represent_user_org_email // for exam
      form.cc = ''
      form.is_unnecessary_impress = this.isUnnecessaryImpress
      this.$refs.dialogSendEmail.open({ form, customers })
    },
    isIncludedAlreadyDeletedUser() {
      for (let data of this.$refs.table.selected) {
        if (data.own_represent_user_status == 2) {
          return true
        }
      }
      return false
    },
    onSendEmail(params) {
      try {
        if (!this.$refs.dialogSendEmail.validate()) {
          this.$refs.dialogConfirmSendEmail.open(params)
        } else {
          this.submitSendEmail(params)
        }
      } catch (err) {
        console.log(err)
      }
    },
    submitSendEmail({ customerId, customerIds, form }) {
      if (customerId) {
        this.sendEmail(customerId, form)
      } else if (customerIds) {
        this.sendEmails(customerIds, form)
      }
    },
    cancelSendEmail() {
      this.$refs.dialogSendEmail.stopLoading()
    },
    sendEmail(customerId, form) {
      try {
        this.setIsUnnecessaryImpress(form.is_unnecessary_impress)
        const inboxId = form.inboxId

        this.$store
          .dispatch('sendBillRequest', { customerId, inboxId, form })
          .then(() => {
            Snackbar.success('請求依頼メールが送信されました')
            this.$refs.dialogSendEmail.close()
          })
          .catch((err) => {
            this.$refs.dialogSendEmail.stopLoading()
            Snackbar.error(err.message)
          })
      } catch (err) {
        console.log(err)
      }
    },
    sendEmails(customerIds, form) {
      try {
        this.setIsUnnecessaryImpress(form.is_unnecessary_impress)

        this.$store
          .dispatch('bulkSendBillRequests', { customerIds, form })
          .then(() => {
            Snackbar.success('請求依頼メールが送信されました')
            this.$refs.dialogSendEmail.close()
          })
          .catch((err) => {
            Snackbar.error(err.message)
            this.$refs.dialogSendEmail.stopLoading()
          })
      } catch (err) {
        console.log(err)
      }
    },
    sendEmailExam({ customerIds, form }) {
      try {
        this.setIsUnnecessaryImpress(form.is_unnecessary_impress)

        this.$store
          .dispatch('sendBillRequestExam', { customerIds, form })
          .then(() => {
            Snackbar.success('テストメールを送信しました')
            this.$refs.dialogSendEmail.stopLoading()
          })
          .catch((err) => {
            Snackbar.error(err.message)
            this.$refs.dialogSendEmail.stopLoading()
          })
      } catch (err) {
        console.log(err)
      }
    },
    getBooleanValueFromLocalStorage(name) {
      try {
        let value = localStorage.getItem(name)
        if (value == null) {
          return false
        }
        return convertStringToBoolean(value)
      } catch (e) {
        console.log(e)
        return false
      }
    },
    download(inbox) {
      try {
        this.$store.dispatch('setSelectedItemId', inbox.id)
        this.$store.dispatch('getInboxImage', inbox.id).then((response) => {
          if (response.data.error) {
            Snackbar.error(response.data.error)
          } else if (response.data.pdf) {
            let a = document.createElement('a')
            let blob = convertBase64toBlob(response.data.pdf, 'application/pdf')
            a.download = response.data.pdf_file_name
            a.href = window.URL.createObjectURL(blob)
            a.click()
          } else {
            Snackbar.error('PDFがありません')
          }
        })
      } catch (e) {
        errorNotify(e.message)
      }
    },
    setIsUnnecessaryImpress(value) {
      this.$store.dispatch('setInboxCustomersState', {
        field: 'isUnnecessaryImpress',
        value: value,
        setLocalStorage: true,
      })
    },
    downloadFile() {
      const inboxItemId = this.selectedInboxItem.id
      if (inboxItemId) {
        this.$store.dispatch('downloadInboxPdfFile', inboxItemId)
      }
    },
    stopSendRequestLoading() {
      this.$refs.inboxInfo.stopSendRequestLoading()
    },
  },
}
</script>
