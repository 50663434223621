<template>
  <v-card tile outlined>
    <v-toolbar flat color="grey lighten-5" class="border-bottom-1" height="50">
      受信した請求書
      <v-spacer />
    </v-toolbar>
    <vue-perfect-scrollbar :style="getScrollHeight()">
      <v-row
        v-if="loading"
        class="d-custom-flex align-items-center justify-center h-100"
      >
        <v-progress-circular indeterminate color="primary" />
      </v-row>
      <template>
        <v-list v-if="items && items.length > 0">
          <v-list-item-group color="blue">
            <v-list-item
              v-for="(item, key) in items"
              :key="key"
              class="px-0 border-bottom-1"
              ripple
              @click="$emit('on-view-inbox-item', item)"
            >
              <v-list-item-content class="px-4">
                <v-list-item-title
                  class="fw-bold fs-12"
                  v-text="item.customer.name"
                />
                <v-list-item-subtitle>
                  <div
                    class="mt-1 mb-2"
                    v-text="item.customer.represent_name"
                  />
                  <div>
                    <span
                      v-tooltip="'受信日時<br>' + getReceiptDate(item)"
                      class="mr-2"
                    >
                      <s-chip class="tw-px-1"> 受 </s-chip>
                      {{ getReceiptDate(item).slice(5, 10) }}
                    </span>
                    <span
                      v-tooltip="'対象月<br>' + getTransactionDate(item)"
                      class="mr-2"
                    >
                      <s-chip class="tw-px-1"> 請 </s-chip>
                      {{ getTransactionDate(item).slice(5, 7) }}月分
                    </span>
                    <s-chip
                      class="right"
                      :color="submitColorsByStatus[item.submit_status]"
                    >
                      {{ submitNamesByStatus[item.submit_status] }}
                    </s-chip>
                  </div>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <div v-else class="w-100">
          <v-alert
            :value="true"
            color="yellow"
            icon="warning"
            class="ma-3 fs-11"
          >
            {{ $t('message.noResult') }}
          </v-alert>
        </div>
      </template>
    </vue-perfect-scrollbar>
  </v-card>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    submitStatuses: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    submitNamesByStatus() {
      const index = {}
      const statuses = this.submitStatuses
      Object.keys(this.submitStatuses).forEach((key) => {
        const config = statuses[key]
        index[config.status] = config.name
      })
      return index
    },
    submitColorsByStatus() {
      const index = {}
      const statuses = this.submitStatuses
      Object.keys(this.submitStatuses).forEach((key) => {
        const config = statuses[key]
        index[config.status] = config.color
      })
      return index
    },
  },
  methods: {
    getScrollHeight() {
      return 'height:calc(100vh - 210px)'
    },
    getTransactionDate(item) {
      try {
        let yyyy = item.transaction_year
        let M = item.transaction_month
        let mm = ('0' + M).slice(-2)
        return yyyy + '-' + mm
      } catch (err) {
        console.log(err)
        return ''
      }
    },
    getReceiptDate(item) {
      try {
        let date = item.receipt_date
        if (!date) {
          date = item.date_updated
        }
        let receiptDate = new Date(date)
        let Y = receiptDate.getFullYear()
        let M = receiptDate.getMonth() + 1
        let D = receiptDate.getDate()
        let T = receiptDate.toLocaleTimeString()
        let yyyy = ('000' + Y).slice(-4)
        let mm = ('0' + M).slice(-2)
        let dd = ('0' + D).slice(-2)
        let time = ('0' + T).slice(-8, -3)
        return yyyy + '-' + mm + '-' + dd + ' ' + time
      } catch (err) {
        console.log(err)
        return ''
      }
    },
  },
}
</script>
