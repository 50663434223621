<template>
  <div>
    <dialog-request-approval
      ref="dialogRequestApproval"
      @send-request="$emit('send-request', $event)"
    />

    <v-card
      id="dialog-upload"
      tile
      outlined
      class="h-100"
      style="overflow: hidden"
    >
      <div v-if="!selectedInboxItem" class="pa-5">
        データが選択されていません
      </div>
      <div v-else>
        <v-toolbar flat height="50" style="border-bottom: 1px solid #ccc">
          請求書情報
        </v-toolbar>
        <v-card flat class="pa-5" :style="inputAreaHeight">
          <!-- 取引元の情報（共通項目） -->
          <template>
            <span
              v-tooltip="'クリックして詳細表示'"
              class="fs-10 pointer"
              @click="customerDetail = !customerDetail"
            >
              取引先
              <s-icon
                :icon="
                  customerDetail ? 'feather-chevron-up' : 'feather-chevron-down'
                "
              />
            </span>
            <v-text-field
              v-model="selectedInboxItem.customer.name"
              dense
              filled
              hide-details
              class="more-dense my-1"
              readonly
            />

            <div v-show="customerDetail">
              <v-text-field
                v-if="selectedInboxItem.customer.department"
                v-model="selectedInboxItem.customer.department"
                dense
                filled
                hide-details
                class="more-dense my-1"
                readonly
              />
              <v-text-field
                v-if="selectedInboxItem.customer.represent_name"
                v-model="selectedInboxItem.customer.represent_name"
                dense
                filled
                hide-details
                class="more-dense my-1"
                readonly
              />
              <v-text-field
                v-if="selectedInboxItem.customer.email"
                v-model="selectedInboxItem.customer.email"
                dense
                filled
                hide-details
                class="more-dense my-1"
                readonly
              />
              <v-text-field
                v-if="selectedInboxItem.customer.phonenumber"
                :value="
                  phonenumberWithhyphen(selectedInboxItem.customer.phonenumber)
                "
                dense
                filled
                hide-details
                class="more-dense my-1"
                readonly
              />
            </div>
            <div class="mt-5">
              <span class="fs-10"> 送信方法 </span>
              <v-menu
                open-on-hover
                top
                nudge-top="25"
                transition="slide-y-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" class="fs-10 ml-1" v-on="on">
                    <s-icon icon="feather-info" size="sm" />
                  </span>
                </template>

                <v-card class="sweeep-popover" width="450">
                  <v-card-title> 請求書の送信方法について </v-card-title>
                  <v-card-text>
                    <div class="py-1">
                      <v-chip
                        x-small
                        label
                        class="pa-1 mr-1"
                        v-text="'画　面'"
                      />
                      取引先がアップロード専用画面から送信
                    </div>
                    <div class="py-1">
                      <v-chip
                        x-small
                        label
                        class="pa-1 mr-1"
                        v-text="'メール'"
                      />
                      取引先が専用メールアドレスから送信
                    </div>
                    <div class="py-1">
                      <v-chip
                        x-small
                        label
                        class="pa-1 mr-1"
                        v-text="'当　社'"
                      />
                      自社の担当者がアップロード
                    </div>
                  </v-card-text>
                </v-card>
              </v-menu>
            </div>

            <v-radio-group
              :value="selectedInboxItem.collected_way"
              mandatory
              readonly
              row
              class="mt-2"
            >
              <v-radio label="画面" :value="1" />
              <v-radio label="メール" :value="3" />
              <v-radio label="自社" :value="2" />
            </v-radio-group>
            <div
              v-if="
                selectedInboxItem.sender_user_name ||
                selectedInboxItem.sender_mail_address ||
                selectedInboxItem.sender_phone_number
              "
              class="fs-10 mt-3"
            >
              送信者
            </div>
            <v-text-field
              v-if="selectedInboxItem.sender_user_name"
              v-model="selectedInboxItem.sender_user_name"
              placeholder="実際の送信者の名前"
              dense
              filled
              hide-details
              class="more-dense my-1"
              readonly
            />
            <v-text-field
              v-if="selectedInboxItem.sender_mail_address"
              v-model="selectedInboxItem.sender_mail_address"
              placeholder="実際の送信者のメールアドレス"
              dense
              filled
              hide-details
              class="more-dense my-1"
              readonly
            />
            <v-text-field
              v-if="selectedInboxItem.sender_phone_number"
              :value="
                phonenumberWithhyphen(selectedInboxItem.sender_phone_number)
              "
              placeholder="実際の送信者の電話番号"
              dense
              filled
              hide-details
              class="more-dense my-1"
              readonly
            />
          </template>

          <v-card flat>
            <vue-perfect-scrollbar>
              <!-- 受信情報 -->
              <div>
                <div v-if="selectedInboxItem.collected_way != 2">
                  <div>
                    <div class="fs-10 mt-3">件名</div>
                    <v-text-field
                      v-model="selectedInboxItem.title"
                      dense
                      filled
                      hide-details
                      class="more-dense my-1"
                      readonly
                    />
                  </div>
                  <div>
                    <div class="fs-10 mt-3">本文</div>
                    <v-textarea
                      v-model="selectedInboxItem.comment"
                      dense
                      filled
                      hide-details
                      class="more-dense my-1 fs-12 fw-bold"
                      readonly
                    />
                  </div>
                </div>
              </div>

              <!-- 回覧タブ -->
              <template>
                <div>
                  <div class="fs-10 mt-3">取引先</div>
                  <v-autocomplete
                    v-model="updateForm.customerId"
                    :items="computedInboxCustomers"
                    label="取引先"
                    filled
                    outlined
                    dense
                    hide-details
                    class="mt-3"
                    item-text="name"
                    item-value="id"
                    :readonly="cannotUpdate"
                  />
                </div>
              </template>
              <template>
                <div>
                  <div class="fs-10 mt-3">取引年月</div>
                  <v-autocomplete
                    v-model="updateForm.transactionYear"
                    :items="computedTransactionYear"
                    label="年"
                    filled
                    dense
                    outlined
                    hide-details
                    class="mt-3"
                    item-text="name"
                    item-value="id"
                    :readonly="cannotUpdate"
                  />
                  <v-autocomplete
                    v-model="updateForm.transactionMonth"
                    :items="computedTransactionMonth"
                    label="月"
                    filled
                    dense
                    outlined
                    hide-details
                    class="mt-3"
                    item-text="name"
                    item-value="id"
                    :readonly="cannotUpdate"
                  />
                </div>
              </template>
              <template>
                <div>
                  <div class="fs-10 mt-3">利用目的</div>
                  <v-text-field
                    v-model="updateForm.purpose"
                    :disabled="cannotUpdate"
                    dense
                    filled
                    hide-details
                    outlined
                    class="more-dense my-1"
                  />
                  <div class="fs-10 mt-3">回覧先へのコメント</div>
                  <v-textarea
                    v-show="inputMode"
                    v-model="updateForm.checkComment"
                    autofocus
                    dense
                    outlined
                    hide-details
                    class="more-dense my-1 fs-12 fw-bold"
                    @blur="inputMode = !inputMode"
                  />
                  <div
                    v-show="!inputMode"
                    class="pa-2 my-1 fs-12 fw-bold"
                    style="
                      border: #a5a7b2 1px solid;
                      border-radius: 5px;
                      min-height: 100px;
                    "
                    @click="!cannotUpdate ? (inputMode = !inputMode) : ''"
                    v-html="checkCommentWithLink"
                  />
                </div>
              </template>
            </vue-perfect-scrollbar>
          </v-card>
        </v-card>
        <v-card-text class="border-top-1 pa-1">
          <v-toolbar flat height="50">
            <v-btn
              v-if="enableViewInvoiceBtn"
              v-tooltip="'請求書メニューで表示'"
              color="primary"
              small
              dark
              :loading="loadingViewInvoice"
              class="ml-3"
              @click="onViewInvoice"
            >
              <s-icon icon="feather-layers" size="xl" />
            </v-btn>
            <v-spacer />
            <v-btn
              color="red"
              dark
              class="mr-3"
              :disabled="cannotUpdate"
              @click="$emit('delete-inbox', createSubmitParams())"
            >
              削除
            </v-btn>
            <v-btn
              class="mr-5"
              :disabled="cannotUpdate"
              @click="$emit('disapprove', createSubmitParams())"
            >
              否認
            </v-btn>
            <v-spacer />
            <v-btn
              class="mr-5"
              color="primary"
              :disabled="cannotUpdate"
              @click="$emit('update-inbox', createSubmitParams())"
            >
              更新
            </v-btn>
            <v-btn
              color="primary"
              :disabled="cannotUpdate"
              @click="
                openRequetNextApprovalDialog({
                  inboxItemId: selectedInboxItem.id,
                  approvalType: approvalType,
                  maxApprovalStage: maxApprovalStage,
                  requestedApprovalUsers: requestedApprovalUsers,
                })
              "
            >
              承認を依頼
            </v-btn>
          </v-toolbar>
        </v-card-text>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { AsYouType } from 'libphonenumber-js'
import { escapeText, surroundUrlByTag } from '@/helpers/helpers'

import DialogRequestApproval from '@/pages/inbox/@components/@shared/InboxInfo/DialogRequestApproval/DialogRequestApproval'

export default {
  components: {
    DialogRequestApproval,
  },
  props: {
    inboxCustomers: {
      type: Array,
      required: true,
    },
    submitStatuses: {
      type: Object,
      required: true,
    },
    selectedInboxItem: {
      type: Object,
      default: null,
    },
    updateForm: {
      type: Object,
      required: true,
    },
    inputAreaHeight: {
      type: String,
      default: null,
    },
    requestedApprovalUsers: {
      type: Object,
      required: true,
    },
    approvalType: {
      type: Number,
      required: true,
    },
    maxApprovalStage: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loadingViewInvoice: false,
      customerDetail: false,
      inputMode: false,
    }
  },
  computed: {
    ...mapGetters(['invoicePrivilege']),
    finalApproved() {
      const approval = this.selectedInboxItem.approval
      const approved = !!(approval && approval.approved_user8)
      return approved
    },
    cannotUpdate() {
      return (
        this.finalApproved ||
        this.selectedInboxItem.submit_status >=
          this.submitStatuses.DISAPPROVED.status
      )
    },
    computedInboxCustomers: function () {
      let computedCustomers = []
      for (let customer of this.inboxCustomers) {
        if (customer.is_uncategorized) {
          continue
        }
        let customerData = {}
        customerData.id = customer.id
        customerData.name =
          customer.name + ' （担当部門：' + customer.own_department_name + '）'
        computedCustomers.push(customerData)
      }
      return computedCustomers
    },
    computedTransactionYear: function () {
      const registeredYear = this.selectedInboxItem.transaction_year
      const computedTransactionYear = [
        {
          id: registeredYear - 1,
          name: String(registeredYear - 1) + '年',
        },
        {
          id: registeredYear,
          name: String(registeredYear) + '年',
        },
        {
          id: registeredYear + 1,
          name: String(registeredYear + 1) + '年',
        },
      ]
      return computedTransactionYear
    },
    computedTransactionMonth: function () {
      let computedTransactionMonth = []
      for (let i = 1; i < 13; i++) {
        let data = {}
        data.id = i
        data.name = String(i) + '月'
        computedTransactionMonth.push(data)
      }
      return computedTransactionMonth
    },
    enableViewInvoiceBtn() {
      return Boolean(
        this.finalApproved &&
          this.checkPrivilege('invoice:read') &&
          this.selectedInboxItem.invoice_local_id
      )
    },
    checkCommentWithLink() {
      let text = escapeText(this.updateForm.checkComment)
      text = surroundUrlByTag(text)
      return text
    },
    phonenumberWithhyphen: function () {
      return function (number) {
        return new AsYouType('JP').input(number)
      }
    },
  },
  methods: {
    onViewInvoice() {
      this.loadingViewInvoice = true
      this.$router.push(
        '/invoice/' +
          this.selectedInboxItem.invoice_local_id +
          '?modedetail=true'
      )
    },
    openRequetNextApprovalDialog(params) {
      this.$refs.dialogRequestApproval.open(
        params.inboxItemId,
        Number(params.approvalType),
        Number(params.maxApprovalStage),
        params.requestedApprovalUsers
      )
    },
    stopSendRequestLoading() {
      this.$refs.dialogRequestApproval.stopLoading()
    },
    createSubmitParams() {
      return {
        inboxId: this.selectedInboxItem.id,
        purpose: this.updateForm.purpose,
        comment: this.updateForm.checkComment,
        customerId: this.updateForm.customerId,
        transactionYear: this.updateForm.transactionYear,
        transactionMonth: this.updateForm.transactionMonth,
      }
    },
  },
}
</script>
