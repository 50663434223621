function trimText(text) {
  return text.replace(/^\s+/g, '').replace(/\s+$/g, '')
}

function removeQuoteBlankAndNewLine(text) {
  let result = text.replace(/"/g, '')
  result = result.replace(/'/g, '')
  result = result.replace(/\n/g, '')
  result = result.replace(/\r/g, '')
  result = trimText(result)
  return result
}

function strToCode(str) {
  let array = []
  for (let i = 0; i < str.length; i++) {
    array.push(str.charCodeAt(i))
  }
  return array
}

function removeQuoteBlankAndNewLineAllRows(csvData, csvHeaders) {
  csvData.forEach((row, i) => {
    if (i == 0) {
      return
    }
    row.forEach((col, j) => {
      const header = csvHeaders.find((x) => x.col == j)
      if (header.removeQuoteBlankAndNewLine) {
        row[j] = removeQuoteBlankAndNewLine(row[j])
      }
    })
  })
}

export { removeQuoteBlankAndNewLineAllRows, strToCode }
