<template>
  <v-dialog
    v-model="dialog"
    max-width="1000px"
    max-height="900px"
    class="d-block"
    persistent
  >
    <email-form
      ref="form"
      :form="form"
      :customers="customers"
      :bulk="bulk"
      :templates="templates"
      :user-organizations="userOrganizations"
      @create-template="onCreateTemplate"
      @update-template="onUpdateTemplate"
      @delete-template="onDeleteTemplate"
      @send="onSend"
      @send-test="onSendTest"
      @cancel="close"
    />
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import EmailForm from './EmailForm/EmailForm'

export default {
  components: {
    EmailForm,
  },
  data() {
    return {
      form: {
        body: '',
        inboxId: '',
        templateId: 0,
        to: '',
        cc: '',
        bcc: '',
        from: '',
        title: '',
        hidden_year: 1,
        hidden_month: 1,
        hidden_upload_url: '',
        hidden_user_org_id: '',
        is_unnecessary_impress: false,
        use_public_url: true,
      },
      customers: [],
      bulk: false,
      dialog: false,
    }
  },
  computed: {
    ...mapGetters({
      userOrganizations: 'inboxUserOrganizations',
      templates: 'inboxEmailTemplates',
      getTemplate: 'getInboxEmailTemplate',
    }),
    dialogTemplateName() {
      if (this.$refs.form) {
        return this.$refs.form.$refs.template.$refs.dialogTemplateName
      } else {
        return null
      }
    },
    dialogDeleteTemplateConfirm() {
      if (this.$refs.form) {
        return this.$refs.form.$refs.template.$refs.dialogDeleteTemplateConfirm
      } else {
        return null
      }
    },
  },
  methods: {
    open({ form, customer, customers }) {
      try {
        const template = this.getTemplate(1)
        form.templateId = 1
        form.body = template.body

        this.form = form

        if (customers) {
          this.bulk = true
          this.customers = customers
        } else {
          this.bulk = false
          this.customers = [customer]
        }
        this.dialog = true
      } catch (err) {
        console.log(err)
      }
    },
    close() {
      this.dialog = false
      this.stopLoading()
    },
    validate() {
      return this.$refs.form.validate()
    },
    stopLoading() {
      this.$refs.form.stopLoading()
    },
    setTemplate(templateId) {
      const template = this.getTemplate(templateId)
      this.form.templateId = template.id
      this.form.body = template.body
    },
    clear() {
      this.form = {
        inboxId: '',
        templateId: 1,
        to: '',
        from: '',
        title: '',
        body: '',
        hidden_year: 1,
        hidden_month: 1,
        hidden_upload_url: '',
        hidden_user_org_id: '',
        is_unnecessary_impress: false,
        use_public_url: true,
      }
    },
    onCreateTemplate(name) {
      this.$emit('create-template', {
        name: name,
        body: this.form.body,
      })
    },
    onUpdateTemplate(name) {
      this.$emit('update-template', {
        id: this.form.templateId,
        body: this.form.body,
        name: name,
      })
    },
    onDeleteTemplate() {
      this.$emit('delete-template', {
        id: this.form.templateId,
      })
    },
    onSend() {
      const form = this.form
      if (!this.bulk) {
        const customerId = this.customers[0].id
        this.$emit('send', { customerId, form })
      } else {
        const customerIds = this.customers.map((obj) => obj.id)
        this.$emit('send', { customerIds, form })
      }
    },
    onSendTest() {
      const form = this.form
      const customerIds = this.customers.map((obj) => obj.id)
      this.$emit('send-test', { customerIds, form })
    },
  },
}
</script>
