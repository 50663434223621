export function filterInboxList(
  items,
  { status, dateFrom, dateTo, ownDepartmentId, isSelectedReceiptDate }
) {
  let filteredItems = []

  dateTo = new Date(dateTo.getFullYear(), dateTo.getMonth() + 1, 0, 23, 59, 59)

  // status filter
  if (status === -1) {
    filteredItems = items.filter(
      (item) =>
        [3, 4, 5, 6, 7].indexOf(item.submit_status) >= 0 && item.file_path
    )
  } else if (status === 4) {
    filteredItems = items.filter(
      (item) => item.submit_status == status && item.file_path
    )
  } else {
    filteredItems = items.filter((item) => item.submit_status == status)
  }

  // date filter
  if (isSelectedReceiptDate) {
    filteredItems = filteredItems.filter(
      (item) =>
        item.receipt_date &&
        new Date(item.receipt_date) >= dateFrom &&
        new Date(item.receipt_date) <= dateTo
    )
  } else {
    filteredItems = filteredItems.filter(
      (item) =>
        item.transaction_date &&
        new Date(item.transaction_date) >= dateFrom &&
        new Date(item.transaction_date) <= dateTo
    )
  }

  // departmentId filter
  if (ownDepartmentId) {
    const strOwnDepartmentId = String(ownDepartmentId)
    filteredItems = filteredItems.filter(
      (item) => item.customer.own_department == strOwnDepartmentId
    )
  }
  return filteredItems
}
