<template>
  <v-card tile outlined class="h-100 w-100">
    <v-tabs v-model="menuIndex">
      <v-tab
        v-for="emailBox in emailBoxes"
        :key="emailBox.id"
        active-class="blue lighten-5"
      >
        <div class="tw-flex tw-items-center">
          <s-icon :icon="emailBox.action" size="xl" color="current" />
          <span class="tw-text-base tw-ml-2">{{ emailBox.name }}</span>
          <v-badge
            v-if="getInboxCount(emailBox)"
            overlap
            dot
            bottom
            color="red"
          />
        </div>
      </v-tab>
    </v-tabs>
  </v-card>
</template>

<script>
import submitStatuses from '@/pages/inbox/@modules/data/submitStatuses.js'
import emailBoxes from '@/pages/inbox/@modules/data/emailBoxes.js'

export default {
  props: {
    numAllItems: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    submitStatuses() {
      return submitStatuses
    },
    emailBoxes() {
      return emailBoxes
    },
    menuIndex: {
      get() {
        const status = this.$store.getters.inboxItemsListParams.status
        return emailBoxes.findIndex((obj) => obj.status === status)
      },
      set(index) {
        this.onChange(index)
      },
    },
  },
  methods: {
    getInboxCount(emailBox) {
      if (emailBox.id == 1) {
        return this.numAllItems
      } else {
        return ''
      }
    },
    onChange(index) {
      const status = emailBoxes[index].status
      this.status = status
      this.$emit('change-status', { status })
    },
  },
}
</script>
