<template>
  <v-card-text class="pb-0 mb-0">
    <v-row no-gutters>
      <v-col cols="12" sm="6" class="px-2">
        <v-text-field
          v-if="bulk"
          label="宛先"
          value="選択中の取引先"
          hide-details
          readonly
        />
        <v-text-field v-else v-model="form.to" label="宛先" readonly />
        <v-btn
          x-small
          class="mt-2 right"
          depressed
          @click="displayCC = !displayCC"
        >
          CC追加
        </v-btn>
      </v-col>
      <v-col cols="12" sm="6" class="px-2">
        <v-text-field
          v-if="bulk"
          label="差出人"
          value="選択中の取引先の自社担当者"
          hide-details
          readonly
        />
        <v-text-field v-else v-model="form.from" label="差出人" readonly />
      </v-col>
    </v-row>

    <v-row v-show="displayCC" no-gutters>
      <v-col cols="12" sm="6" class="px-2">
        <v-text-field
          v-model="form.cc"
          label="CC（複数指定はカンマ区切り）"
          hint="複数取引先へ一括送信の場合、全てのメールのCCに適用されます(各取引先の自社担当者は自動的にCCに登録されます)"
          validate-on-blur
        />
      </v-col>
      <v-col cols="12" sm="6" class="px-2">
        <v-text-field
          v-model="form.bcc"
          label="BCC（複数指定はカンマ区切り）"
          hint="複数取引先へ一括送信の場合、全てのメールのBCCに適用されます"
          validate-on-blur
        />
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="12" sm="6" class="px-2">
        <v-text-field v-model="form.title" label="メール件名" />
      </v-col>
      <template v-if="bulk">
        <v-col cols="6" sm="3" class="px-2">
          <v-autocomplete
            v-model="form.hidden_year"
            :items="yearList"
            label="取引年"
            placeholder=" "
            item-text="name"
            item-value="value"
          />
        </v-col>
        <v-col cols="6" sm="3" class="px-2">
          <v-autocomplete
            v-model="form.hidden_month"
            :items="monthList"
            label="取引月"
            placeholder=" "
            item-text="name"
            item-value="value"
          />
        </v-col>
      </template>

      <template v-else>
        <v-col cols="6" sm="3" class="px-2">
          <v-text-field
            v-model="form.hidden_year"
            label="取引年"
            placeholder=" "
            class="fs-10"
            item-text="name"
            item-value="value"
            suffix="月"
            readonly
          />
        </v-col>
        <v-col cols="6" sm="3" class="px-2">
          <v-text-field
            v-model="form.hidden_month"
            label="取引月"
            placeholder=" "
            class="fs-10"
            item-text="name"
            item-value="value"
            suffix="月"
            readonly
          />
        </v-col>
      </template>
    </v-row>

    <v-row no-gutters>
      <v-col class="px-2 mb-3">
        <v-menu offset-y top nudge-top="40" z-index="211" max-width="400">
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" class="mb-1" v-on="on">
              アップロード用URL
              <v-icon x-small class="pl-2"> feather-info </v-icon>
            </div>
          </template>

          <v-card class="sweeep-popover" width="400">
            <v-card-title> アップロードURLについて </v-card-title>

            <v-card-text>
              <div>取引先がアップロード可能なURLは2種類あります。</div>
              <v-chip x-small label class="mt-2"> 取引先別のURL </v-chip>
              <div>
                取引先の登録時に発行されるURLをメールで案内します。このURLは何度でも利用可能です。
              </div>
              <v-chip x-small label class="mt-2"> ワンタイムURL </v-chip>
              <div>
                メール送信時に自動でアップロード専用URLが発行されます。このURLは一度のみ利用可能なため、都度メールで案内する必要があります。
              </div>
            </v-card-text>
          </v-card>
        </v-menu>

        <v-btn-toggle
          v-model="form.use_public_url"
          mandatory
          dense
          color="blue"
        >
          <v-btn
            v-tooltip="'取引先ごとに発行されたURLをメールに埋め込みます'"
            small
            outlined
            :value="true"
          >
            取引先別のURLを案内
          </v-btn>
          <v-btn
            v-tooltip="'一度のみ使用可能なURLをメールに埋め込みます'"
            small
            outlined
            :value="false"
          >
            ワンタイムURLを案内
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
import {
  checkCCDomain,
  checkBCCDomain,
} from '@/pages/inbox/@modules/helpers/email'

export default {
  props: {
    form: {
      type: Object,
      required: true,
    },
    bulk: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      displayCC: false,
      monthList: [],
      yearList: [],
      checkCCDomainRules: [
        (v) =>
          checkCCDomain(v) || '(注)差出人と異なるドメインが入力されています',
      ],
      checkBCCDomainRules: [
        (v) =>
          checkBCCDomain(v) || '(注)差出人と異なるドメインが入力されています',
      ],
    }
  },
  mounted() {
    this.createYearMonthList()
  },
  methods: {
    createYearMonthList() {
      this.monthList = []
      for (let m = 1; m <= 12; m++) {
        let item = {}
        item.value = m
        item.name = m + '月'
        this.monthList.push(item)
      }

      this.yearList = []
      let now = new Date()
      let thisYear = now.getFullYear()
      for (let year = thisYear - 2; year <= thisYear + 2; year++) {
        let item = {}
        item.value = year
        item.name = year + '年'
        this.yearList.push(item)
      }
    },
  },
}
</script>
