function convertStringToBoolean(v) {
  if (typeof v == 'boolean') {
    return v
  }
  v = v.toLowerCase()
  if (v == 'true') {
    return true
  }
  return false
}

export { convertStringToBoolean }
