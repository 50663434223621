<template>
  <div style="width: 100%">
    <dialog-confirm-delete-memo
      ref="dialogConfirmDeleteMemo"
      @submit="$emit('delete-memo', $event)"
    />
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="filteredItems"
      item-key="customer.id"
      fixed-header
      :footer-props="footerProps"
      :items-per-page="itemsPerPage"
      :custom-sort="customTableSort"
      height="calc(100vh - 240px)"
      class="px-4"
      style="width: 100%"
      @pagination="onPagination($event)"
      @update:items-per-page="onChangeItemsPerPage"
    >
      <template #header.selected="{}">
        <select-all-header-cell
          :items="filteredItems"
          :all-selected="allSelected"
          :indeterminate="indeterminate"
          @click:item="onClickSelectAll($event.value, $event.message)"
        />
      </template>

      <template #item="{ item, isSelected }">
        <tr :class="isSelected ? 'v-data-table__selected' : ''">
          <td style="width: 30px">
            <v-simple-checkbox
              v-if="!item.customer.is_uncategorized"
              :value="isSelected"
              @input="toggleOne(item)"
            />
          </td>
          <td
            v-show="!tableParams.isAll"
            v-tooltip="$t('message.edit_sort_num')"
            @click="$emit('edit-sort-num', item.customer)"
          >
            <span v-if="!item.customer.is_uncategorized">
              {{ item.customer.sort_num }}
            </span>
          </td>
          <td style="width: 20%">
            <div
              class="has-link d-inline"
              @click="$emit('edit-customer', item.customer)"
            >
              <span v-text="item.customer.name" />
              <span
                v-if="item.customer.department_name"
                v-text="' / ' + item.customer.department_name"
              />
            </div>

            <span
              @click="
                $emit('copy-upload-email', {
                  customer: item.customer,
                  query: uploadEmailQuery(item.customer.id),
                })
              "
            >
              <s-icon
                :tooltip="`クリックで${$t(
                  'message.inbox_upload_email'
                )}をコピー`"
                class="tw-float-right tw-cursor-pointer"
                icon="feather-mail"
              />
            </span>
            <input
              :id="uploadEmailId(item.customer.id)"
              type="hidden"
              :value="item.customer.upload_email"
            />
            <span
              @click="
                $emit('copy-upload-url', {
                  customer: item.customer,
                  query: uploadUrlQuery(item.customer.id),
                })
              "
            >
              <s-icon
                :tooltip="`クリックで${$t('message.inbox_upload_url')}をコピー`"
                class="tw-float-right tw-mr-3 tw-cursor-pointer"
                icon="feather-link"
              />
            </span>
            <input
              :id="uploadUrlId(item.customer.id)"
              type="hidden"
              :value="item.customer.upload_url"
            />
            <s-icon
              v-if="item.own_represent_user_status == 2"
              tooltip="自社担当者が削除されているため変更ください"
              class="tw-float-right tw-mr-3"
              color="red-500"
              icon="feather-alert-triangle"
            />
          </td>

          <owner-cell :item="item" />

          <template v-for="(inboxItems, i) in item.items">
            <td
              v-if="notHasInboxItems(inboxItems)"
              :key="i"
              :style="inboxCellStyle"
            >
              <v-row :style="inboxSendEmailStyle">
                <v-chip
                  v-if="item.requested[i]"
                  x-small
                  label
                  outlined
                  :color="'orange'"
                  class="mr-1 px-1"
                  v-text="'依頼済'"
                />
              </v-row>
              <v-row :style="inboxMenuStyle">
                <inbox-action-menu
                  :submit-statuses="submitStatuses"
                  :can-nocharge="true"
                  :delete-message="''"
                  :is-default-no-charge="
                    defaultSubmitStatus(item.customer, i) ==
                    submitStatuses.NOT_NEED_TO_COLLECT.status
                  "
                  @create-inbox="
                    $emit('create-inbox-item', {
                      customerId: item.customer.id,
                      status: submitStatuses.UNCOLLECTED.status,
                      ...getYearMonth(i),
                    })
                  "
                  @upload-by-self="
                    $emit('upload-by-self', {
                      customer: item.customer,
                      ...getYearMonth(i),
                    })
                  "
                  @nocharge="
                    $emit('nocharge', {
                      customer: item.customer,
                      ...getYearMonth(i),
                    })
                  "
                />
              </v-row>
              <v-row
                v-if="!item.customer.is_uncategorized && !item.requested[i]"
              >
                <inbox-status-chip
                  :submit-statuses="submitStatuses"
                  :status="defaultSubmitStatus(item.customer, i)"
                />
                <inbox-memo
                  v-model="memoForm.memo"
                  @open="setMemoForm(item.customer, i)"
                  @click-update="$emit('create-memo', memoForm)"
                />
              </v-row>
            </td>
            <td v-else :key="i" :style="inboxCellStyle">
              <v-row :style="inboxSendEmailStyle">
                <v-chip
                  v-if="item.requested[i]"
                  x-small
                  label
                  outlined
                  :color="'orange'"
                  class="mr-1 px-1"
                  v-text="'依頼済'"
                />
              </v-row>
              <v-row :style="inboxMenuStyle">
                <inbox-action-menu
                  :submit-statuses="submitStatuses"
                  :can-nocharge="canNocharge(inboxItems)"
                  :delete-message="
                    deleteMessage(getDeletableInboxItem(inboxItems))
                  "
                  :is-default-no-charge="false"
                  @delete-inbox="
                    $emit('delete-inbox', getDeletableInboxItem(inboxItems))
                  "
                  @upload-by-self="
                    $emit(
                      'upload-by-self',
                      getUploadBySelfParams(item.customer, inboxItems, i)
                    )
                  "
                  @nocharge="
                    $emit(
                      'nocharge',
                      getNochargeParams(item.customer, inboxItems, i)
                    )
                  "
                />
              </v-row>
              <div v-for="(inbox, index) in inboxItems" :key="inbox.id">
                <v-row
                  v-if="
                    !item.requested[i] ||
                    inbox.submit_status != submitStatuses.UNCOLLECTED.status
                  "
                  align="center"
                >
                  <inbox-status-chip
                    :submit-statuses="submitStatuses"
                    :status="inbox.submit_status"
                  />

                  <inbox-memo
                    v-model="inbox.memo"
                    @click-delete="$refs.dialogConfirmDeleteMemo.open(inbox)"
                    @click-update="
                      $emit('update-memo', {
                        inboxItem: inbox,
                        inboxId: inbox.id,
                        memo: inbox.memo,
                      })
                    "
                  />
                  <span
                    @click="$emit('draw-collected-invoice', inbox.id, inbox)"
                  >
                    <s-icon
                      v-if="
                        inbox &&
                        statusesHasFile.includes(inbox.submit_status) &&
                        inbox.file_path
                      "
                      tooltip="ファイルを確認"
                      size="lg"
                      color="blue-500"
                      class="tw-float-right tw-ml-1 tw-cursor-pointer"
                      icon="feather-file"
                    />
                  </span>
                </v-row>
              </div>
            </td>
          </template>
        </tr>
      </template>

      <template #no-results>
        <v-alert :value="true" color="yellow" icon="warning" class="mt-3">
          {{ $t('message.noResult') }}
        </v-alert>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Snackbar from '@/helpers/snackbar/index'

// components
import SelectAllHeaderCell from 'Components/Table/SelectAllHeaderCell'
import DialogConfirmDeleteMemo from './DialogConfirmDeleteMemo/DialogConfirmDeleteMemo'
import OwnerCell from './OwnerCell/OwnerCell'
import InboxActionMenu from './InboxActionMenu/InboxActionMenu'
import InboxStatusChip from './InboxStatusChip/InboxStatusChip'
import InboxMemo from './InboxMemo/InboxMemo'

// data
import { getMonthHeaders } from '@/pages/inbox/@modules/data/monthHeaders'
import { filterCustomerTableWithoutDate } from '@/pages/inbox/@modules/helpers/table'
import { convertText } from 'Helpers/table/sort'

export default {
  components: {
    SelectAllHeaderCell,
    DialogConfirmDeleteMemo,
    OwnerCell,
    InboxMemo,
    InboxActionMenu,
    InboxStatusChip,
  },
  props: {
    tableItems: {
      type: Array,
      required: true,
    },
    tableParams: {
      type: Object,
      required: true,
    },
    submitStatuses: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      itemsPerPage: 20,
      selected: [],
      pageData: {
        start: 0,
        pageStart: 0,
        pageStop: 0,
      },
      memoForm: {
        customerId: '',
        memo: '',
        year: '',
        month: '',
      },
    }
  },
  computed: {
    displayYear() {
      return this.tableParams.year
    },
    displayMonth() {
      return this.tableParams.month
    },
    getYearMonth() {
      return (monthIndex) => {
        let month = this.displayMonth + monthIndex
        let year = this.displayYear
        if (month > 12) {
          year = this.displayYear + 1
          month = month - 12
        }
        return {
          year,
          month,
        }
      }
    },
    filteredItems() {
      return filterCustomerTableWithoutDate(this.tableItems, this.tableParams)
    },
    allSelected: function () {
      return this.tableItems.length == this.selected.length
    },
    indeterminate: function () {
      return !this.allSelected && this.selected.length > 0
    },
    uploadUrlId() {
      return (customerId) => 'upload-url-' + customerId
    },
    uploadUrlQuery() {
      return (customerId) => '#' + this.uploadUrlId(customerId)
    },
    uploadEmailId() {
      return (customerId) => 'upload-email-' + customerId
    },
    uploadEmailQuery() {
      return (customerId) => '#' + this.uploadEmailId(customerId)
    },
    footerProps() {
      return {
        'items-per-page-text': '表示件数',
        'items-per-page-options': [20, 50, 100],
      }
    },
    monthHeaders() {
      return getMonthHeaders()
    },
    headers() {
      const month = this.displayMonth
      const monthHeaders = [...this.monthHeaders, ...this.monthHeaders]
      const monthIndex = month - 1
      const monthSpan = 4

      const cpMonthHeaders = monthHeaders.slice(
        monthIndex,
        monthIndex + monthSpan
      )
      return this.defaultHeaders.concat(cpMonthHeaders)
    },
    defaultHeaders() {
      if (this.tableParams.isAll) {
        return [
          { text: '', diplay: true, value: 'selected', sortable: false },
          { text: '取引先', display: true, value: 'customer' },
          { text: '自社担当者', display: true, value: 'ownRepresentUser' },
        ]
      }
      return [
        { text: '', diplay: true, value: 'selected', sortable: false },
        { text: '表示順', display: true, value: 'sortNum' },
        { text: '取引先', display: true, value: 'customer' },
        { text: '自社担当者', display: true, value: 'ownRepresentUser' },
      ]
    },
    statusesHasFile() {
      return [
        this.submitStatuses.RECEIVED.status,
        this.submitStatuses.APPROVING.status,
        this.submitStatuses.IMPORTED.status,
        this.submitStatuses.DISAPPROVED.status,
      ]
    },
    notHasInboxItems: function () {
      return function (inboxItems) {
        if (!inboxItems || inboxItems.length == 0) {
          return true
        }
        return false
      }
    },
    canNocharge: function () {
      return function (inboxItem) {
        const statusesAbleNocharge = [
          this.submitStatuses.UNCOLLECTED.status,
          this.submitStatuses.REQUESTED.status,
        ]
        const isIncludedAbleNochargeStatus = (item) =>
          statusesAbleNocharge.includes(item.submit_status)
        return inboxItem.every(isIncludedAbleNochargeStatus)
      }
    },
    deleteMessage: function () {
      return function (inboxItem) {
        if (!inboxItem) {
          return ''
        }

        if (inboxItem.submit_status == this.submitStatuses.REQUESTED.status) {
          return '請求依頼を取り消し'
        }
        if (
          inboxItem.submit_status ==
          this.submitStatuses.NOT_NEED_TO_COLLECT.status
        ) {
          return '回収不要を取り消し'
        }
        return ''
      }
    },
    inboxSendEmailStyle: function () {
      return 'position: absolute; right: 10px; bottom: 2px'
    },
    inboxMenuStyle: function () {
      return 'position: absolute; right: 20px; top: 3px'
    },
    inboxCellStyle() {
      return `
        position: relative;
        min-width: 155px;
        padding-top: 6px !important;
        padding-bottom: 6px !important;
        padding-left: 24px !important;
      `
    },
  },
  watch: {
    filteredItems: {
      handler() {
        this.checkSelected()
      },
      deep: true,
    },
  },
  created() {
    this.setItemsPerPage()
  },
  methods: {
    customTableSort: function (items, index, isDesc) {
      if (index[0] == 'customer') {
        items.sort((a, b) => {
          const customerNameAndDepartmentA =
            a.customer.name + a.customer.department
          const customerNameAndDepartmentB =
            b.customer.name + b.customer.department
          const convertCustomerNameAndDepartmentA = convertText(
            customerNameAndDepartmentA
          )
          const convertCustomerNameAndDepartmentB = convertText(
            customerNameAndDepartmentB
          )
          if (!isDesc[0]) {
            return convertCustomerNameAndDepartmentA >
              convertCustomerNameAndDepartmentB
              ? 1
              : -1
          } else {
            return convertCustomerNameAndDepartmentA <
              convertCustomerNameAndDepartmentB
              ? 1
              : -1
          }
        })
      } else if (index[0] == 'ownRepresentUser') {
        items.sort((a, b) => {
          const ownDepartmentAndUserNameA =
            a.own_department_name + a.own_represent_user_name
          const ownDepartmentAndUserNameB =
            b.own_department_name + b.own_represent_user_name
          const convertOwnDepartmentAndUserNameA = convertText(
            ownDepartmentAndUserNameA
          )
          const convertOwnDepartmentAndUserNameB = convertText(
            ownDepartmentAndUserNameB
          )
          if (!isDesc[0]) {
            return convertOwnDepartmentAndUserNameA <
              convertOwnDepartmentAndUserNameB
              ? 1
              : -1
          } else {
            return convertOwnDepartmentAndUserNameA >
              convertOwnDepartmentAndUserNameB
              ? 1
              : -1
          }
        })
      } else if (index[0] == 'sortNum') {
        items.sort((a, b) => {
          if (!isDesc[0]) {
            return a.customer.sort_num > b.customer.sort_num ? 1 : -1
          } else {
            return a.customer.sort_num < b.customer.sort_num ? 1 : -1
          }
        })
      } else if (index.length > 0) {
        items.sort((a, b) => {
          //月の列ソート時
          const month = Number(index[0].replace(/[^0-9]/g, ''))
          let colIndex //ソートする列のインデックス
          if (month < this.displayMonth) {
            colIndex = 12 + month - this.displayMonth
          } else {
            colIndex = month - this.displayMonth
          }
          const itemA = a.items[colIndex]
            ? a.items[colIndex]
            : [{ submit_status: this.submitStatuses.UNCOLLECTED.status }]
          const itemB = b.items[colIndex]
            ? b.items[colIndex]
            : [{ submit_status: this.submitStatuses.UNCOLLECTED.status }]
          if (!isDesc[0]) {
            const minA = Math.min.apply(
              null,
              itemA.map(function (o) {
                return o.submit_status
              })
            )
            const minB = Math.min.apply(
              null,
              itemB.map(function (o) {
                return o.submit_status
              })
            )
            return minA - minB
          } else {
            const maxA = Math.max.apply(
              null,
              itemA.map(function (o) {
                return o.submit_status
              })
            )
            const maxB = Math.max.apply(
              null,
              itemB.map(function (o) {
                return o.submit_status
              })
            )
            return maxB - maxA
          }
        })
      }
      return items
    },
    onClickSelectAll(selectType, message) {
      const pageData = this.pageData
      //selectType  1:全選択 2:現在ページ選択 3:全解除 4:現在ページ解除
      switch (selectType) {
        case 1: {
          this.selected = []
          for (let item of this.filteredItems) {
            if (!item.customer.is_uncategorized) {
              this.selected.push(item)
            }
          }
          break
        }
        case 2: {
          for (let i = pageData.pageStart; i < pageData.pageStop; i++) {
            const item = this.filteredItems[i]
            if (
              !this.selected.includes(item) &&
              !item.customer.is_uncategorized
            ) {
              this.selected.push(item)
            }
          }
          break
        }
        case 3: {
          this.selected.splice(-this.selected.length)
          break
        }
        case 4: {
          for (let i = pageData.pageStart; i < pageData.pageStop; i++) {
            const item = this.filteredItems[i]
            if (this.selected.includes(item)) {
              this.selected = this.selected.filter(
                (v) => v.customer.id != item.customer.id
              )
            }
          }
          break
        }
      }
      Snackbar.success(message)
    },
    onPagination(event) {
      this.pageData.page = event.page
      this.pageData.pageStart = event.pageStart
      this.pageData.pageStop = event.pageStop
    },
    setItemsPerPage() {
      const key = 'itemsPerPageOnInboxTable'
      const value = JSON.parse(localStorage.getItem(key))
      if (Number.isInteger(value)) {
        this.itemsPerPage = value
      }
    },
    onChangeItemsPerPage(itemsPerPage) {
      const key = 'itemsPerPageOnInboxTable'
      const value = JSON.stringify(itemsPerPage)
      localStorage.setItem(key, value)
    },
    toggleOne(item) {
      if (this.selected.includes(item)) {
        this.selected = this.selected.filter(
          (v) => v.customer.id != item.customer.id
        )
      } else {
        this.selected.push(item)
      }
    },
    checkSelected() {
      // filter範囲外のselectedを削除する
      try {
        for (let item of this.selected) {
          if (!this.filteredItems.includes(item)) {
            this.selected = this.selected.filter(
              (v) => v.customer.id != item.customer.id
            )
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    setMemoForm(customer, monthIndex) {
      this.memoForm = {
        customerId: customer.id,
        memo: '',
        ...this.getYearMonth(monthIndex),
      }
    },
    clearMemoForm() {
      this.memoForm = {
        customerId: '',
        memo: '',
        year: '',
        month: '',
      }
    },
    createActiveBtnNumber(year, month, startYear) {
      if (!year || year == 0 || !month || month == 0) {
        let d = new Date()
        let thisYear = d.getFullYear()
        let thisMonth = d.getMonth() + 1
        // デフォルトで現在の3ヶ月前を採用
        if (thisMonth < 4) {
          year = thisYear - 1
          month = 9 + thisMonth
        } else {
          year = thisYear
          month = thisMonth - 3
        }
      }
      let diffYear = year - startYear
      return diffYear * 12 + month
    },
    getDeletableInboxItem(inboxItems) {
      const deletableStatuses = [
        this.submitStatuses.REQUESTED.status,
        this.submitStatuses.NOT_NEED_TO_COLLECT.status,
      ]
      const isIncludedDeletableStatus = (item) =>
        deletableStatuses.includes(item.submit_status)
      return inboxItems.find(isIncludedDeletableStatus)
    },
    getUploadBySelfParams(customer, inboxItems, i) {
      const updatableStatuses = [
        this.submitStatuses.UNCOLLECTED.status,
        this.submitStatuses.NOT_NEED_TO_COLLECT.status,
      ]
      const isIncludedUpdatableStatus = (item) =>
        updatableStatuses.includes(item.submit_status)
      const updatableInboxItem = inboxItems.find(isIncludedUpdatableStatus)
      if (updatableInboxItem) {
        return {
          id: updatableInboxItem.id,
          customer: customer,
          ...this.getYearMonth(i),
        }
      }
      return { customer: customer, ...this.getYearMonth(i) }
    },
    getNochargeParams(customer, inboxItems, i) {
      const updatableStatuses = [
        this.submitStatuses.UNCOLLECTED.status,
        this.submitStatuses.REQUESTED.status,
        this.submitStatuses.NOT_NEED_TO_COLLECT.status,
      ]
      const isIncludedUpdatableStatus = (item) =>
        updatableStatuses.includes(item.submit_status)
      const updatableInboxItem = inboxItems.find(isIncludedUpdatableStatus)

      if (updatableInboxItem) {
        return {
          id: updatableInboxItem.id,
          inboxItem: updatableInboxItem,
          customer: customer,
          ...this.getYearMonth(i),
        }
      }
      return { customer: customer, ...this.getYearMonth(i) }
    },
    defaultSubmitStatus(customer, i) {
      try {
        if (customer.tran_months.length < 1) {
          return this.submitStatuses.UNCOLLECTED.status
        }

        let startAt = null
        let endAt = null
        if (customer.start_at) {
          startAt = new Date(customer.start_at)
          startAt = new Date(startAt.getFullYear(), startAt.getMonth(), 1)
        }
        if (customer.end_at) {
          endAt = new Date(customer.end_at)
          endAt = new Date(endAt.getFullYear(), endAt.getMonth() + 1, 0)
        }

        const tranYearMonth = this.getYearMonth(i)
        const tranDate = new Date(
          tranYearMonth.year,
          tranYearMonth.month - 1,
          1
        )
        let isDuringPeriod = false
        if (!startAt && !endAt) {
          isDuringPeriod = true
        } else if (!startAt && tranDate <= endAt) {
          isDuringPeriod = true
        } else if (!endAt && startAt <= tranDate) {
          isDuringPeriod = true
        } else if (startAt <= tranDate && tranDate <= endAt) {
          isDuringPeriod = true
        }

        if (isDuringPeriod && customer.tran_months[tranYearMonth.month - 1]) {
          return this.submitStatuses.UNCOLLECTED.status
        }
        return this.submitStatuses.NOT_NEED_TO_COLLECT.status
      } catch (e) {
        console.log(e)
        return this.submitStatuses.UNCOLLECTED.status
      }
    },
  },
}
</script>
