<template>
  <v-container fluid pa-0>
    <inbox-detail-menu
      :num-all-items="numAllItems"
      @change-status="$emit('change-menu-status', $event)"
    />
    <v-row no-gutters style="flex-wrap: nowrap">
      <v-col
        cols="2"
        class="flex-grow-0 flex-shrink-0"
        style="min-width: 200px"
      >
        <inbox-list
          :items="filteredItems"
          :submit-statuses="submitStatuses"
          :loading="inboxItemsLoading"
          @on-view-inbox-item="onViewInboxItem"
        />
      </v-col>

      <v-col cols="7" class="flex-grow-1 flex-shrink-1">
        <inbox-info
          ref="inboxInfo"
          :inbox-customers="inboxCustomers"
          :submit-statuses="submitStatuses"
          :selected-inbox-item="selectedInboxItem"
          :update-form="updateForm"
          :input-area-height="'height:calc(100vh - 268px);overflow:auto;'"
          :requested-approval-users="requestedApprovalUsers"
          :approval-type="approvalType"
          :max-approval-stage="maxApprovalStage"
          @update-inbox="$emit('update-inbox', $event)"
          @disapprove="$emit('disapprove', $event)"
          @delete-inbox="$emit('delete', $event)"
          @send-request="sendRequestApproval($event)"
        />
      </v-col>

      <v-col
        v-show="selectedInboxItem.id"
        cols="3"
        class="flex-grow-0 flex-shrink-0"
        style="min-width: 650px; max-width: 650px"
      >
        <inbox-image
          ref="image"
          :selected-inbox-item="selectedInboxItem"
          :submit-statuses="submitStatuses"
          :image-data="imageData"
          :requested-approval-users="requestedApprovalUsers"
          :approval-type="approvalType"
          :max-approval-stage="maxApprovalStage"
          @approve="$emit('approve', $event)"
          @cancel-approve="$emit('cancel-approve', $event)"
          @download-file="downloadFile"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import Snackbar from '@/helpers/snackbar/index'

// helpers
import submitStatuses from '@/pages/inbox/@modules/data/submitStatuses.js'
import emailBoxes from '@/pages/inbox/@modules/data/emailBoxes.js'
import { filterInboxList } from '@/pages/inbox/@modules/helpers/filter'

// components
import InboxDetailMenu from '@/pages/inbox/@components/InboxDetail/InboxDetailMenu/InboxDetailMenu'
import InboxList from '@/pages/inbox/@components/InboxDetail/InboxList/InboxList'
import InboxInfo from '@/pages/inbox/@components/@shared/InboxInfo/InboxInfo'
import InboxImage from '@/pages/inbox/@components/@shared/InboxImage/InboxImage'

export default {
  components: {
    InboxDetailMenu,
    InboxList,
    InboxInfo,
    InboxImage,
  },
  data() {
    return {
      notifications: false,
      selectedInboxItem: {},
      updateForm: {
        issueDate: '',
        purpose: '',
        checkComment: '',
        customerId: '',
        transactionYear: 0,
        transactionMonth: 0,
      },
      requestedApprovalUsers: {},
      approvalType: 1,
      maxApprovalStage: 8,
    }
  },
  computed: {
    ...mapGetters([
      'inboxItems',
      'inboxItemsListParams',
      'inboxCustomers',
      'imageData',
      'inboxItemsLoading',
    ]),
    emailBoxes() {
      return emailBoxes
    },
    submitStatuses() {
      return submitStatuses
    },
    filteredItems() {
      return filterInboxList(this.inboxItems, this.inboxItemsListParams)
    },
    numAllItems() {
      const params = {
        status: -1,
        isSelectedReceiptDate: this.inboxItemsListParams.isSelectedReceiptDate,
        dateFrom: this.inboxItemsListParams.dateFrom,
        dateTo: this.inboxItemsListParams.dateTo,
        ownDepartmentId: this.inboxItemsListParams.ownDepartmentId,
      }
      return filterInboxList(this.inboxItems, params).length
    },
  },
  mounted() {
    this.onViewInboxItemFromUrl()
  },
  methods: {
    startApproveLoading() {
      this.$refs.image.startApproveLoading()
    },
    stopApproveLoading() {
      this.$refs.image.stopApproveLoading()
    },
    onViewInboxItemFromUrl() {
      let userOrganizationId = this.$route.query.userorganizationid
      if (userOrganizationId != this.$store.getters.getUserOrganizationID) {
        return
      }
      let inboxId = this.$route.params.id
      if (!inboxId) {
        return
      }
      if (!this.inboxItems) {
        return
      }
      let inboxItem = this.inboxItems.filter((item) => item.id == inboxId)[0]
      this.onViewInboxItem(inboxItem)
      // this.$router.push("/inbox")
      // this.$router.replace("/inbox/"+inboxId+"?submitstatus="+this.$route.query.submitstatus)
      this.$router.replace('/inbox')
    },
    onViewInboxItem(inboxItem) {
      if (inboxItem.id != this.selectedInboxItem.id) {
        const payload = {
          inboxItemId: inboxItem.id,
        }
        this.$store
          .dispatch('getNextApprovalRequests', payload)
          .then((response) => {
            this.requestedApprovalUsers = response.next_approval_users
            this.approvalType = response.approval_type
            this.maxApprovalStage = response.approval_stage

            this.selectedInboxItem = inboxItem
            this.$store.dispatch('setSelectedItemId', inboxItem.id)
            this.updateForm.customerId = inboxItem.customer.id
            this.updateForm.issueDate = this.formatDate(
              new Date(inboxItem.transaction_date)
            )
            this.updateForm.purpose = inboxItem.purpose
            this.updateForm.checkComment = inboxItem.submit_comment
            this.updateForm.transactionYear = inboxItem.transaction_year
            this.updateForm.transactionMonth = inboxItem.transaction_month
          })
          .catch((err) => {
            Snackbar.error(err.message)
          })

        this.$refs.image.startLoading()
        this.$store.dispatch('getInboxImage', inboxItem.id).then(() => {
          this.$refs.image.stopLoading()
        })
      }
    },
    downloadFile() {
      const inboxItemId = this.selectedInboxItem.id
      if (inboxItemId) {
        this.$store.dispatch('downloadInboxPdfFile', inboxItemId)
      }
    },
    formatDate(date) {
      let Y = date.getFullYear()
      let M = date.getMonth() + 1
      let D = date.getDate()
      let yyyy = ('000' + Y).slice(-4)
      let mm = ('0' + M).slice(-2)
      let dd = ('0' + D).slice(-2)
      return yyyy + '-' + mm + '-' + dd
    },
    updateSelectedInboxItem({ status, comment }) {
      if (status) {
        this.selectedInboxItem.submit_status = status
      }
      if (comment) {
        this.selectedInboxItem.submit_comment = comment
      }
    },
    updateInboxApproval({
      approval_stage,
      approved_user_id,
      approved_user_name,
      approved_date,
    }) {
      const userIdField = 'approved_user' + approval_stage
      let approvedUser = {
        id: approved_user_id,
        date: approved_date,
        name: approved_user_name,
      }

      this.selectedInboxItem.approval[userIdField] = approvedUser
      if (
        this.selectedInboxItem.submit_status <
        this.submitStatuses.APPROVING.status
      ) {
        this.selectedInboxItem.submit_status =
          this.submitStatuses.APPROVING.status
      }
    },
    canselInboxApproval(approval_stage) {
      const userIdField = 'approved_user' + approval_stage
      this.selectedInboxItem.approval[userIdField] = null
    },
    sendRequestApproval(params) {
      this.requestedApprovalUsers = params.nextApproveRequestUsers
      this.approvalType = params.approvalType
      this.maxApprovalStage = params.maxApprovalStage
      this.$emit('send-request', params)
    },
    stopSendRequestLoading() {
      this.$refs.inboxInfo.stopSendRequestLoading()
    },
  },
}
</script>
