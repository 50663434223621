<template>
  <v-menu
    offset-y
    top
    nudge-top="10"
    z-index="99"
    transition="slide-y-transition"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on" @click="$emit('open')">
        <s-icon
          tooltip="メモを更新"
          size="lg"
          :color="!value ? 'gray-400' : 'blue-500'"
          class="tw-float-right tw-ml-2"
          icon="feather-message-circle"
        />
      </span>
    </template>

    <v-card class="sweeep-popover" width="400">
      <v-card-text class="px-3">
        <v-textarea
          :value="value"
          ref="text"
          small
          filled
          label="メモ"
          class="fs-12"
          outlined
          hide-details
          @change="$emit('change', $event)"
        />
      </v-card-text>
      <v-card-actions class="px-3 pt-0">
        <v-spacer />
        <v-btn v-if="value" small depressed @click="$emit('click-delete')">
          {{ $t('message.delete') }}
        </v-btn>
        <v-btn
          small
          depressed
          color="grey lighten-2"
          @click="$emit('click-update')"
        >
          {{ $t('message.submit') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: String,
      required: true,
    },
  },
}
</script>
