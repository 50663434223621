function getEmailDomain(address) {
  let splitedAddress = address.split('@')
  let domain = splitedAddress[splitedAddress.length - 1]
  return domain
}

function removeBlankAndSplit(text, pattern) {
  let s = text.replace(' ', '')
  return s.split(pattern)
}

function removeTag(text) {
  return text.replace(/<p>|<\/p>|\n|<br>|\s/g, '')
}

function checkCCDomain(form, customer) {
  try {
    if (!form.cc) {
      return true
    }

    let emailFromDomain = ''
    if (form.from) {
      emailFromDomain = getEmailDomain(form.from)
    } else {
      emailFromDomain = getEmailDomain(customer.own_represent_user_org_email)
    }

    let splitedAddress = removeBlankAndSplit(form.cc, ',')
    for (let address of splitedAddress) {
      if (getEmailDomain(address) != emailFromDomain) {
        return false
      }
    }
    return true
  } catch (e) {
    console.log(e)
  }
}

function checkBCCDomain(form) {
  try {
    if (!form.bcc) {
      return true
    }

    let splitedAddress = removeBlankAndSplit(form.bcc, ',')
    for (let address of splitedAddress) {
      if (getEmailDomain(address) != getEmailDomain(form.from)) {
        return false
      }
    }
    return true
  } catch (e) {
    console.log(e)
  }
}

function getEmailBodyTemplate() {
  let mailBodyTemplate =
    '%取引先会社名%<br>' +
    '%取引先担当者名% 様<br><br>' +
    'いつも大変お世話になっております。<br>' +
    '%会社名%の%自社担当者名%です。<br><br>' +
    '今月のご請求書について以下のリンクよりお送りください。<br>' +
    'ご請求がない場合はご返信いただけましたら幸いです。<br><br>' +
    '＜請求書アップロード用リンク＞<br>' +
    '%アップロードURL%<br><br>' +
    '今後ともよろしくお願い致します。<br>' +
    '＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝<br>' +
    '%会社名%<br>' +
    '%自社担当者名%<br>' +
    '＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝'
  return mailBodyTemplate
}

export { checkCCDomain, checkBCCDomain, getEmailBodyTemplate, removeTag }
