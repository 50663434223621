<template>
  <v-dialog v-model="dialog" max-width="550px">
    <v-card class="sweeep-dialog">
      <v-card-title v-text="$t('message.confirm')" />
      <v-card-text>
        まだ承認されていないものがあります。
        <br />
        本当に最終承認してもよろしいでしょうか？
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="cancel"> キャンセル </v-btn>
        <v-btn color="primary" @click="submit"> 承認 </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
    }
  },
  methods: {
    open() {
      this.dialog = true
    },
    close() {
      this.dialog = false
    },
    cancel() {
      this.close()
    },
    submit() {
      this.$emit('submit')
      this.close()
    },
  },
}
</script>
