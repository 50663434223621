<template>
  <v-dialog v-model="dialog" scrollable max-width="450px">
    <v-card class="sweeep-dialog">
      <v-card-title>承認依頼</v-card-title>
      <v-card-text>
        チェックしたユーザに承認依頼メールを送信することができます。<br />
        承認権限を持たないユーザには送信できません。<br />
        例）承認1をクリック → 承認1の権限保有ユーザのみ送信可能

        <v-radio-group v-model="selectedApprovalType" row>
          <v-radio color="blue" label="承認順序は問わない" :value="1" />
          <v-radio color="blue" label="前段階の承認が必要" :value="2" />
        </v-radio-group>
        <v-autocomplete
          v-model="selectedApprovalStage"
          label="承認回数"
          class="fs-10 py-0"
          style="width: 150px"
          filled
          outlined
          dense
          hide-details
          :items="approvalStageItems"
        />
        <div v-for="stage in selectedApprovalStage" :key="stage">
          <v-autocomplete
            v-model="
              selected[
                'approve' + String(stage == selectedApprovalStage ? 8 : stage)
              ]
            "
            chips
            small-chips
            multiple
            clearable
            :label="stageTitle(stage)"
            filled
            dense
            outlined
            hide-details
            class="mt-3"
            :items="approveUserOrganizations(stage)"
            item-text="name"
            item-value="id"
          />
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn @click.native="close"> キャンセル </v-btn>
        <v-btn color="primary" :loading="loading" @click="submit">
          承認依頼
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {},
  props: {},
  data() {
    return {
      dialog: false,
      loading: false,
      inboxItemId: null,
      selectedApprovalType: 1,
      selectedApprovalStage: 8,
      approvalStageItems: [1, 2, 3, 4, 5, 6, 7, 8],

      headers: [
        { text: '', diplay: true, value: 'selected', sortable: false },
        { text: '名前', diplay: true, value: 'name' },
      ],
      selected: {
        approve1: [],
        approve2: [],
        approve3: [],
        approve4: [],
        approve5: [],
        approve6: [],
        approve7: [],
        approve8: [],
      },
    }
  },
  computed: {
    ...mapGetters({
      userOrganizations: 'inboxUserOrganizations',
    }),
    footerProps() {
      return {
        'items-per-page-text': '表示件数',
        'items-per-page-options': [20, 50, 100],
      }
    },
    approveUserOrganizations() {
      return (stage) =>
        this.userOrganizations.filter(
          (user) => user.status == 1 && this.hasPrivilege(stage, user)
        )
    },
  },
  methods: {
    open(inboxItemId, approvalType, approvalStage, requestedUsers) {
      this.inboxItemId = inboxItemId
      this.selectedApprovalType = approvalType
      this.selectedApprovalStage = approvalStage
      this.setRequestedUsers(JSON.parse(JSON.stringify(requestedUsers)))
      this.dialog = true
    },
    close() {
      this.dialog = false
      this.$emit('cancel')
    },
    submit() {
      this.loading = true
      this.$emit('send-request', {
        inboxItemId: this.inboxItemId,
        approvalType: this.selectedApprovalType,
        maxApprovalStage: this.selectedApprovalStage,
        nextApproveRequestUsers: this.selected,
      })
    },
    stopLoading() {
      this.loading = false
    },
    toggleOne(stage, item) {
      try {
        if (stage == this.selectedApprovalStage) {
          stage = 8
        }
        const stageKey = 'approve' + String(stage)
        if (this.selected[stageKey].find((user) => user.id == item.id)) {
          this.selected[stageKey] = this.selected[stageKey].filter(
            (v) => v.id != item.id
          )
        } else {
          this.selected[stageKey].push(item)
        }
      } catch (e) {
        console.log(e)
      }
    },
    setRequestedUsers(requestedUsers) {
      this.clearSelected()
      for (let [approvalKey, users] of Object.entries(requestedUsers)) {
        this.selected[approvalKey] = []
        for (let user of users) {
          this.selected[approvalKey].push(String(user.id))
        }
      }
    },
    clearSelected() {
      this.selected = {
        approve1: [],
        approve2: [],
        approve3: [],
        approve4: [],
        approve5: [],
        approve6: [],
        approve7: [],
        approve8: [],
      }
    },
    stageTitle(stage) {
      if (stage == this.selectedApprovalStage) {
        return '最終承認'
      }
      return '第' + String(stage) + '承認'
    },
    hasPrivilege(stage, user) {
      if (stage == this.selectedApprovalStage) {
        stage = 8
      }
      const approvalKey = 'approve' + String(stage)
      return user.privileges.inbox[approvalKey]
    },
  },
}
</script>
