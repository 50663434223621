<template>
  <v-card-actions class="px-8">
    <v-switch
      v-model="form.is_unnecessary_impress"
      v-tooltip="
        'メール送信時に「押印不要」の旨のコメントを追加します。<br>※ 詳細はテスト送信機能で確認できます。'
      "
      color="primary"
      class="mt-0"
      hide-details
      label="押印不要を通知"
      style="max-width: 180px"
    />
    <v-spacer />
    <v-btn @click.native="cancel"> キャンセル </v-btn>
    <v-btn color="primary" class="px-4" :loading="loading" @click="send">
      <v-icon x-small class="mr-2"> feather-mail </v-icon>
      送信
    </v-btn>
    <v-btn
      v-tooltip="'自分にテストメールを送信'"
      :loading="loadingTest"
      text
      @click="sendTest"
    >
      テスト送信
    </v-btn>
  </v-card-actions>
</template>

<script>
export default {
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      loadingTest: false,
    }
  },
  methods: {
    send() {
      this.loading = true
      this.$emit('send')
    },
    sendTest() {
      this.loadingTest = true
      this.$emit('send-test')
    },
    cancel() {
      this.$emit('cancel')
    },
    startLoading(mode = '') {
      if (!(mode === 'test')) {
        this.loading = true
      } else {
        this.loadingTest = true
      }
    },
    stopLoading() {
      this.loading = false
      this.loadingTest = false
    },
  },
}
</script>
