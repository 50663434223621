<template>
  <v-dialog v-model="dialog" max-width="300">
    <v-card class="sweeep-dialog">
      <v-card-title v-text="'メモ削除'" />
      <v-card-text> メモを削除しますか？ </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="dialog = false">
          {{ $t('message.modal_cancel_btn') }}
        </v-btn>
        <v-btn color="primary" @click="submit">
          {{ $t('message.delete') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      inboxItem: {},
    }
  },
  methods: {
    open(inboxItem) {
      this.inboxItem = inboxItem
      this.dialog = true
    },
    submit() {
      this.dialog = false
      this.$emit('submit', this.inboxItem)
    },
  },
}
</script>
