<template>
  <v-dialog v-model="dialog" max-width="1000">
    <v-card class="sweeep-dialog">
      <v-card-title v-text="'取引先の編集'" />
      <v-card-text class="py-0 grey lighten-3">
        <v-form ref="form">
          <v-row>
            <v-col style="height: calc(90vh - 115px); overflow: scroll">
              <v-card class="pa-5">
                <div class="fs-12 fw-bold">取引先情報</div>
                <v-radio-group v-model="form.status" row>
                  <v-radio color="blue" label="表示" :value="1" />
                  <v-radio color="blue" label="非表示" :value="2" />
                </v-radio-group>
              </v-card>
              <v-card class="pa-5 mt-3">
                <div class="fs-12 fw-bold">請求書受領予定</div>
                <!-- <v-row no-gutters class="pt-1">
                  <v-radio-group
                    v-model="form.useIssueDate"
                    row
                  >
                    <v-radio
                      color="blue"
                      label="OCR結果を反映"
                      :value="false"
                    />
                    <v-radio
                      color="blue"
                      label="受領日付から計算"
                      :value="true"
                    />
                  </v-radio-group>
                </v-row> -->
                <v-checkbox
                  v-model="form.isUpdateTranMonths"
                  hide-details
                  label="請求書受領月を編集"
                  class="mb-3"
                />
                <v-row no-gutters>
                  <date-pick-input
                    v-model="form.startAt"
                    clearable
                    :editable="form.isUpdateTranMonths"
                  />
                  <span class="mt-2 ml-8 mr-5">〜</span>
                  <date-pick-input
                    v-model="form.endAt"
                    clearable
                    :editable="form.isUpdateTranMonths"
                  />
                </v-row>
                <v-row no-gutters class="pa-4">
                  <v-col
                    v-for="monthcol in [1, 4, 7, 10]"
                    :key="`monthcol${monthcol}`"
                  >
                    <v-checkbox
                      v-for="month in [monthcol, monthcol + 1, monthcol + 2]"
                      :key="`month${month}`"
                      v-model="form.tranMonths[month - 1]"
                      :label="`${month}月`"
                      class="mt-0"
                      hide-details
                      :disabled="!form.isUpdateTranMonths"
                    />
                  </v-col>
                </v-row>
              </v-card>
              <v-card class="pa-5 mt-3">
                <div class="fs-12 fw-bold">自動メール</div>
                <v-checkbox
                  v-model="form.isUpdateScheduleEmail"
                  hide-details
                  label="自動メール送信設定を編集"
                  class="mb-3"
                />
                <v-row no-gutters>
                  <v-col cols="6">
                    <v-switch
                      v-model="form.isSendScheduleEmail"
                      :disabled="!form.isUpdateScheduleEmail"
                      color="primary"
                      class="mt-0"
                      hide-details
                      label="自動でメール送信"
                    />
                  </v-col>
                  <v-col cols="3">
                    <v-autocomplete
                      v-model="form.beginningDay"
                      :items="beginningDayItems"
                      :disabled="
                        !form.isUpdateScheduleEmail || !form.isSendScheduleEmail
                      "
                      :rules="numberScheduleRequiredRulesNoZero"
                      :required="scheduleRequired"
                      label="起算日"
                      item-text="name"
                      item-value="value"
                      class="pr-2"
                    />
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model="form.incrementDay"
                      :disabled="
                        !form.isUpdateScheduleEmail || !form.isSendScheduleEmail
                      "
                      :rules="numberScheduleRequiredRules"
                      :required="scheduleRequired"
                      label="起算日からの日数"
                      type="number"
                      min="-31"
                      max="31"
                      class="fs-10"
                      style="width: 100px"
                    />
                  </v-col>
                </v-row>
                <v-autocomplete
                  v-model="form.emailTemplateId"
                  :items="emailTemplates"
                  :disabled="
                    !form.isUpdateScheduleEmail || !form.isSendScheduleEmail
                  "
                  :rules="numberScheduleRequiredRules"
                  :required="scheduleRequired"
                  label="メールテンプレート"
                  item-text="name"
                  item-value="id"
                />
              </v-card>
            </v-col>
            <v-col style="height: calc(90vh - 115px); overflow: scroll">
              <v-card class="pa-5">
                <div class="fs-12 fw-bold">自社情報</div>
                <v-autocomplete
                  v-model="form.ownRepresentUserOrg"
                  label="自社担当者"
                  filled
                  dense
                  outlined
                  hide-details
                  class="mt-3"
                  :items="validUserOrganizations"
                  item-text="name"
                  item-value="id"
                />
                <v-autocomplete
                  v-model="form.ownDepartmentId"
                  label="自社担当部門"
                  filled
                  dense
                  outlined
                  hide-details
                  class="mt-3"
                  :items="departmentList"
                  item-text="name_code"
                  item-value="id"
                />
              </v-card>
              <v-card class="pa-5 mt-3">
                <div class="fs-12 fw-bold">デフォルト承認者</div>
                <v-checkbox
                  v-model="form.isUpdateDefaultApproveUsers"
                  class="pb-3"
                  hide-details
                  label="デフォルト承認者を編集"
                />
                <v-radio-group
                  v-model="form.approvalType"
                  :disabled="!form.isUpdateDefaultApproveUsers"
                  row
                >
                  <v-radio color="blue" label="承認順序は問わない" :value="1" />
                  <v-radio color="blue" label="前段階の承認が必要" :value="2" />
                </v-radio-group>
                <v-autocomplete
                  v-model="form.approvalStage"
                  label="承認回数"
                  class="fs-10 py-0"
                  style="width: 150px"
                  filled
                  outlined
                  dense
                  hide-details
                  :items="approvalStageItems"
                  :disabled="!form.isUpdateDefaultApproveUsers"
                  clearable
                />

                <div
                  v-for="stage in computedApprovalStageItems"
                  :key="stage"
                  :disabled="!form.isUpdateDefaultApproveUsers"
                >
                  <v-autocomplete
                    v-model="form.selectedUsers['approve' + String(stage)]"
                    chips
                    small-chips
                    multiple
                    clearable
                    :label="stageTitle(stage)"
                    filled
                    dense
                    outlined
                    hide-details
                    class="mt-3"
                    :readonly="readonly"
                    :items="approveUserOrganizations(stage)"
                    item-text="name"
                    item-value="id"
                    :disabled="
                      !form.isUpdateDefaultApproveUsers || !form.approvalStage
                    "
                  />
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions class="border-top-1">
        <v-spacer />
        <v-btn @click.native="close">
          {{ $t('message.modal_cancel_btn') }}
        </v-btn>
        <v-btn color="primary" :loading="loading" @click="onClickUpdate">
          {{ $t('message.submit') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

import DatePickInput from '@/components/Input/DatePickInput.vue'

export default {
  components: {
    DatePickInput,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      headers: [
        { text: '', diplay: true, value: 'selectedUsers', sortable: false },
        { text: '名前', diplay: true, value: 'name' },
      ],
      approvalStageItems: [1, 2, 3, 4, 5, 6, 7, 8],
      form: {},
      customers: [],
    }
  },
  computed: {
    ...mapGetters({
      userOrganizations: 'inboxUserOrganizations',
      emailTemplates: 'inboxEmailTemplates',
    }),
    computedApprovalStageItems: function () {
      if (!this.form.approvalStage) {
        return this.approvalStageItems
      }
      const approvalStageItems = [...new Array(this.form.approvalStage)].map(
        (_, i) => i + 1
      )
      approvalStageItems.splice(-1, 1, 8)
      return approvalStageItems
    },
    departmentList: function () {
      const departments = [
        ...this.$store.getters.inboxDepartments,
        {
          id: null,
          name: '',
          code: '',
          name_code: '',
          is_all: false,
        },
      ]
      return departments
    },
    validUserOrganizations: function () {
      return this.$store.getters.inboxUserOrganizations.filter(
        (userOrg) => userOrg.status == 1
      )
    },
    requiredRules() {
      return [
        (v) => !!v || this.$t('message.form_input_required'),
        (v) =>
          (v && v.replace(/\s/g, '').length >= 1) ||
          this.$t('message.form_input_required'),
      ]
    },
    approveUserOrganizations() {
      return (stage) =>
        this.userOrganizations.filter(
          (user) => user.status == 1 && this.hasPrivilege(stage, user)
        )
    },
    footerProps() {
      return {
        'items-per-page-text': '表示件数',
        'items-per-page-options': [20, 50, 100],
      }
    },
    beginningDayItems() {
      const dayItems = []
      for (let i = 31; i >= 1; i--) {
        if (i == 31) {
          dayItems.push({ name: '月末', value: i })
        } else {
          dayItems.push({ name: i + '日', value: i })
        }
      }
      return dayItems
    },
    scheduleRequired() {
      if (this.form.isSendScheduleEmail) {
        return true
      }
      return false
    },
    numberScheduleRequiredRules() {
      if (!this.form.isSendScheduleEmail) {
        return []
      }
      return [(v) => v != null || this.$t('message.form_input_required')]
    },
    numberScheduleRequiredRulesNoZero() {
      if (!this.form.isSendScheduleEmail) {
        return []
      }
      const noZeroRule = [
        (v) => v != 0 || this.$t('message.form_input_required'),
      ]
      const rules = noZeroRule.concat(this.numberScheduleRequiredRules)
      return rules
    },
  },
  watch: {
    'form.isUpdateScheduleEmail': {
      handler() {
        this.form.isSendScheduleEmail = false
        this.form.beginningDay = null
        this.form.incrementDay = null
        this.form.emailTemplateId = null
      },
    },
  },
  created() {
    this.$set(this, 'form', this.defaultForm())
  },
  methods: {
    open({ customers }) {
      try {
        this.customers = customers
        this.form = this.defaultForm()
        this.dialog = true

        if (this.$refs.form) {
          this.$refs.form.resetValidation()
        }
      } catch (e) {
        console.log(e)
      }
    },
    defaultForm() {
      const form = {
        status: 1,
        ownRepresentUserOrg: '',
        ownDepartmentId: null,
        approvalStage: null,
        approvalType: null,
        isUpdateDefaultApproveUsers: false,
        selectedUsers: {
          approve1: [],
          approve2: [],
          approve3: [],
          approve4: [],
          approve5: [],
          approve6: [],
          approve7: [],
          approve8: [],
        },
        isUpdateTranMonths: false,
        startAt: '',
        endAt: '',
        tranMonths: [
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
        ],
        isUpdateScheduleEmail: false,
        isSendScheduleEmail: false,
        beginningDay: null,
        incrementDay: null,
        emailTemplateId: null,
        useIssueDate: null,
      }
      return form
    },
    close() {
      this.dialog = false
      this.loading = false
    },
    startLoading() {
      this.loading = true
    },
    stopLoading() {
      this.loading = false
    },
    onClickUpdate() {
      const form = this.form
      const customerIds = this.customers.map((obj) => obj.id)
      if (this.$refs.form.validate()) {
        this.$emit('update-customers', { customerIds, form })
        this.close()
      }
    },
    stageTitle(stage) {
      if (stage == 8) {
        return '最終承認'
      }
      return '第' + String(stage) + '承認'
    },
    toggleOne(stage, item) {
      try {
        const stageKey = 'approve' + String(stage)
        if (
          this.form.selectedUsers[stageKey].find((user) => user.id == item.id)
        ) {
          this.form.selectedUsers[stageKey] = this.form.selectedUsers[
            stageKey
          ].filter((v) => v.id != item.id)
        } else {
          this.form.selectedUsers[stageKey].push(item)
        }
      } catch (e) {
        console.log(e)
      }
    },
    hasPrivilege(stage, user) {
      const approvalKey = 'approve' + String(stage)
      return user.privileges.inbox[approvalKey]
    },
    getScrollHeight() {
      return 'max-height: calc(100vh - 230px)'
    },
    cardTextClass(stage) {
      if (stage == 8) {
        return 'pa-8'
      }
      return 'pa-8 mb-5'
    },
  },
}
</script>
