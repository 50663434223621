<template>
  <v-dialog v-model="dialog" max-width="650">
    <v-card class="sweeep-dialog">
      <v-card-title>確認</v-card-title>
      <v-card-text>
        既に登録されている取引先があります。<br />
        <!-- データを上書き登録しますか？<br> -->
        CSV行数 {{ duplicatedData.join(', ') }}
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click.native="dialog = false">
          {{ $t('message.modal_cancel_btn') }}
        </v-btn>
        <v-btn color="primary" @click="submit">
          {{ $t('message.import_csv_create_message') }}
        </v-btn>
        <!-- <v-btn
          color="primary"
          @click="checkImportData(true)"
        >
          {{ $t('message.import_csv_update_message') }}
        </v-btn> -->
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      duplicatedData: [],
    }
  },
  methods: {
    open(duplicatedData) {
      this.duplicatedData = duplicatedData
      this.dialog = true
    },
    submit() {
      this.dialog = false
      this.$emit('submit')
    },
  },
}
</script>
