<template>
  <v-container>
    <dialog-confirm-approve
      ref="dialogConfirmApprove"
      @submit="onClickApproveBtn(8, true)"
    />
    <dialog-error-approve ref="dialogErrorApprove" />

    <v-card
      id="dialog-upload"
      tile
      outlined
      class="h-100"
      style="overflow: hidden"
    >
      <v-row no-gutters>
        <v-col v-for="n in maxApprovalStage" :key="n">
          <v-card
            tile
            outlined
            class="text-center fs-10 pa-1"
            style="background-color: #f7f7f7"
          >
            {{ computedStageText(n) }}
          </v-card>
          <v-card tile outlined class="text-center pt-2" height="75">
            <v-btn
              v-if="!(!isApproved(n) && disabled)"
              fab
              small
              outlined
              :loading="finalApproveLoading(n)"
              :disabled="finalApproveLoading(n)"
              :color="computedColor(n)"
              :style="computedStyle(n)"
              @click="onClickApproveBtn(n)"
            >
              <s-icon
                v-if="!isApproved(n)"
                icon="feather-check"
                color="gray-300"
              />
              <div v-else class="fs-10 fw-bold">
                {{ computedApprovedUserName(n) }}
              </div>
            </v-btn>
            <div
              v-if="!(!isApproved(n) && disabled)"
              class="fs-10 pt-1"
              :class="!isApproved(n) ? 'grey--text' : 'red--text fw-bold'"
            >
              {{ computedApprovedDate(n) }}
            </div>
          </v-card>

          <v-card tile outlined>
            <v-spacer />
            <template
              v-for="requestedUser in requestedApprovalUsers[
                'approve' + String(n == maxApprovalStage ? 8 : n)
              ]"
            >
              <v-avatar
                :key="n + '-' + requestedUser.id"
                v-tooltip="
                  `${computedStageText(n)}依頼<br>${requestedUser.name}`
                "
                size="26"
                color="grey lighten-2"
                class="fs-10 ma-1"
                v-text="requestedUser.name.slice(0, 2)"
              />
            </template>
            <v-spacer />
          </v-card>
        </v-col>
      </v-row>
      <v-row no-gutters style="height: calc(100vh - 238px)">
        <div
          v-if="loadingImage"
          class="d-flex justify-center align-center w-100 h-100"
        >
          <v-progress-circular indeterminate color="primary" />
        </div>

        <div v-show="imageData.pdfUrl" :class="$style.emailImagePdfView">
          <pdf-view
            id="invoicepdf"
            :uri="imageData.pdfUrl"
            height="100%"
            width="100%"
            @download-file="$emit('download-file')"
          />
        </div>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import PdfView from 'Components/Pdf/PdfView'

import DialogConfirmApprove from '@/pages/inbox/@components/@shared/InboxImage/DialogConfirmApprove/DialogConfirmApprove'
import DialogErrorApprove from '@/pages/inbox/@components/@shared/InboxImage/DialogErrorApprove/DialogErrorApprove'

export default {
  components: {
    DialogConfirmApprove,
    DialogErrorApprove,
    PdfView,
  },
  props: {
    selectedInboxItem: {
      type: Object,
      default: null,
    },
    submitStatuses: {
      type: Object,
      required: true,
    },
    imageData: {
      type: Object,
      default: null,
    },
    requestedApprovalUsers: {
      type: Object,
      required: true,
    },
    approvalType: {
      type: Number,
      required: true,
    },
    maxApprovalStage: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      approved: false,
      loadingImage: true,
      loadingApprove: false,
    }
  },
  computed: {
    computedStageText: function () {
      return function (stage) {
        if (stage < this.maxApprovalStage) {
          return '第' + stage + '承認'
        }
        return '最終承認'
      }
    },
    computedStage: function () {
      return function (stage) {
        if (stage < this.maxApprovalStage) {
          return stage
        }
        return 8
      }
    },
    computedColor: function () {
      return function (stage) {
        const approval = this.selectedInboxItem.approval
        const approved_key = 'approved_user' + this.computedStage(stage)
        if (approval && approval[approved_key]) {
          return 'red'
        }
        return 'grey lighten-2'
      }
    },
    computedStyle: function () {
      return function (stage) {
        const approval = this.selectedInboxItem.approval
        const approved_key = 'approved_user' + this.computedStage(stage)
        if (this.disabled) {
          return 'border-width:2px; cursor: text;'
        }
        if (approval && approval[approved_key]) {
          return 'border-width:2px;'
        }
        return ''
      }
    },
    computedApprovedUserName: function () {
      return function (stage) {
        const approval = this.selectedInboxItem.approval
        const approved_key = 'approved_user' + this.computedStage(stage)
        if (approval && approval[approved_key]) {
          return approval[approved_key].name.slice(0, 3)
        }
        return ''
      }
    },
    computedApprovedDate: function () {
      return function (stage) {
        const approval = this.selectedInboxItem.approval
        const approved_key = 'approved_user' + this.computedStage(stage)
        if (approval && approval[approved_key]) {
          return approval[approved_key].date
        }
        return '未承認'
      }
    },
    isApproved: function () {
      return function (stage) {
        const approval = this.selectedInboxItem.approval
        const approved_key = 'approved_user' + this.computedStage(stage)
        if (approval && approval[approved_key]) {
          return true
        }
        return false
      }
    },
    isAllApprovedExcludeFinal: function () {
      for (let stage = 1; stage < this.maxApprovalStage; stage++) {
        if (!this.isApproved(stage)) {
          return false
        }
      }
      return true
    },
    finalApproved() {
      const approval = this.selectedInboxItem.approval
      return approval && approval.approved_user8
    },
    disapproved() {
      return (
        this.selectedInboxItem.submit_status ==
        this.submitStatuses.DISAPPROVED.status
      )
    },
    deleted() {
      return (
        this.selectedInboxItem.submit_status ==
        this.submitStatuses.DELETED.status
      )
    },
    disabled() {
      return this.finalApproved || this.disapproved || this.deleted
    },
    finalApproveLoading: function () {
      return function (stage) {
        return stage == this.maxApprovalStage && this.loadingApprove
      }
    },
  },
  methods: {
    startLoading() {
      this.loadingImage = true
    },
    stopLoading() {
      this.loadingImage = false
    },
    startApproveLoading() {
      this.loadingApprove = true
    },
    stopApproveLoading() {
      this.loadingApprove = false
    },
    checkAction(stage) {
      const approval = this.selectedInboxItem.approval
      const approved_key = 'approved_user' + stage
      if (approval && approval[approved_key]) {
        return 'cancel-approve'
      }
      return 'approve'
    },
    onClickApproveBtn(stage, isForced = false) {
      let updateStage = stage
      if (this.disabled) {
        return
      }
      if (stage == this.maxApprovalStage) {
        updateStage = 8
      }
      const action = this.checkAction(updateStage)

      if (action == 'approve') {
        let isValid = isForced || false
        if (!isForced) {
          isValid = this.checkApprovalType(stage)
        }
        if (!isValid) {
          return
        }
      }

      this.$emit(action, {
        inboxId: this.selectedInboxItem.id,
        transactionDate: this.selectedInboxItem.transaction_date,
        stage: updateStage,
      })
    },
    checkApprovalType(stage) {
      if (
        this.approvalType == 1 &&
        stage == this.maxApprovalStage &&
        !this.isAllApprovedExcludeFinal
      ) {
        this.$refs.dialogConfirmApprove.open()
        return false
      } else if (
        this.approvalType == 2 &&
        stage != 1 &&
        !this.isApproved(stage - 1)
      ) {
        this.$refs.dialogErrorApprove.open()
        return false
      }

      return true
    },
  },
}
</script>

<style module>
.emailImagePdfView {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}
</style>
